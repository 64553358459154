import * as React from "react";

import { FieldType } from "../../../store/types";
import { StyledInput } from "./TextInput.style";

export type Props = {
  // The `formTestId` prop is needed to construct the full test id
  // however it should be provided from the implementing form
  // and therefore does not need to be included in the `Field` type
  // and instead defined here.
  formTestId: string;
} & FieldType;

/**
 * Text Input to be used anywhere a user is required to enter a value.
 */
export const TextInput: React.FC<Props> = ({
  formTestId,
  name,
  displayName = name,
  placeholder,
  onChange,
  type = "text",
  required = false,
  autoComplete = true,
  focusOnMount = false,
  ...rest
}) => {
  // Prepare reference for input component to be used
  // if the input should be focused when it is mounted to the DOM
  // Note: The `any` type must be used because there is only partial overlap
  // between `HTMLInputElement` and `HTMLTextAreaElement`
  const reference = React.useRef<any | null>(null);

  // ID to be used for E2E testing
  // Note: Follows BEM naming convention
  const id = `${formTestId}__${name}-input`;

  // When this component mounts, if `focusOnMount` is set to `true`
  // call the `focus` method on the element
  React.useEffect(() => {
    if (focusOnMount) {
      if (reference.current) {
        reference.current.focus();
      }
    }
  }, [focusOnMount]);

  // If the input type is set to `textbox` render a `textarea` component
  // instead of an `input` component
  if (type === "textbox") {
    return (
      <StyledInput
        as="textarea"
        data-testid={id}
        id={id}
        name={name}
        placeholder={placeholder || `Enter your ${displayName}`}
        required={required}
        autoComplete={autoComplete ? "on" : "off"}
        ref={reference}
        onChange={onChange}
        {...rest}
      />
    );
  }

  return (
    <StyledInput
      data-testid={id}
      id={id}
      name={name}
      placeholder={placeholder || `Enter your ${displayName}`}
      type={type}
      required={required}
      autoComplete={autoComplete ? "on" : "off"}
      ref={reference}
      onChange={onChange}
      {...rest}
    />
  );
};
