import { createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import { GRAPHQL_API_URL } from "../../constants/apiUrl";
import { getAccessToken } from "../../store/accessToken";

/**
 * Configure the link to the API to include the Access Token
 * granted to the user to enable privilaged access to secure data.
 */
const authLink = setContext((_, { headers }) => {
  // Get the Access token stored in memory
  const token = getAccessToken();

  return {
    headers: {
      // Include all existing headers
      ...headers,
      // Set Authorization header to include Access Token
      authorization: `Bearer ${token}`,
    },
  };
});

/**
 * Create an HTTP Link to communicate with the GraphQL endpoint.
 */
const httpLink = createHttpLink({
  uri: GRAPHQL_API_URL,
  // Include credentials to allow cookies to be sent
  credentials: "include",
});

/**
 * Combine the HTTP Link with the Auth Link configuration.
 */
export const link = authLink.concat(httpLink);
