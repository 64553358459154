import styled from "styled-components";

import { buttonColors, spacing } from "../../../utils";

/**
 * Wraps the entire Checkbox component.
 */
export const CheckboxWrapper = styled.div`
  display: inline-flex;
  vertical-align: middle;
`;

/**
 * A label to indicate the purpose or value of the checkbox.
 */
export const Label = styled.label`
  padding-left: ${spacing[2]};
  cursor: pointer;
`;

/**
 * The Icon to be displayed within the box when checked.
 */
export const Icon = styled.svg`
  fill: none;
  stroke: ${buttonColors.secondary.hover.text};
  stroke-width: 3px;
`;

/**
 * Internal HTML checkbox that should be completely invisable.
 */
export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

/**
 * Displayed checkbox the user will see and interact with.
 */
export const StyledCheckbox = styled.div<{ checked: boolean }>`
  cursor: pointer;
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${(props) =>
    props.checked
      ? buttonColors.secondary.border
      : buttonColors.secondary.background};
  box-shadow: 0 0 0 2px ${buttonColors.secondary.border};
  border-radius: 3px;
  transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px
      ${(props) =>
        props.checked
          ? buttonColors.secondary.border
          : buttonColors.secondary.hover.border};
  }

  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;
