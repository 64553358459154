import { useHistory } from "react-router-dom";

import { Routes } from "../constants/routes";
import { clearAccessToken } from "../store/accessToken";
import { useLogoutMutation } from "../generated/graphql";

/**
 * Returns a function that handles all logout processes
 * including clearing the session, clearing the Access Token,
 * and returning the user to the login screen.
 */
export function useLogoutCleanup() {
  // Logout function will clear user session data from the server
  const [logout] = useLogoutMutation();

  // Access history to redirect the user to the home screen
  const history = useHistory();

  // Combine all effects into a single function to ensure consistant logouts
  const handleLogoutCleanup = async () => {
    // Log the user out from the server
    await logout();

    // Remove the Access Token to revoke privilaged access
    clearAccessToken();

    // Return the user to the Login screen
    history.push(Routes.Login);
  };

  return handleLogoutCleanup;
}
