import { StatusType } from "../../../../store/types";

/**
 * Updates a status variable based on a number of jobs to increase in severity
 * the more jobs are waiting for a queue to perform.
 */
export function getStatusByNumberOfJobs(jobs: number): StatusType {
  switch (true) {
    case jobs <= 5:
      return "success";

    case jobs <= 10:
      return "warning";

    default:
      return "error";
  }
}
