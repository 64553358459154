import { MultiSubmitFormType } from "../../store/types";
import { useMultiStepFormBuilderElements } from "./utils/useMultiStepFormBuilderElements";
import { useMultiSubmitFormBuilder } from "./useMultiSubmitFormBuilder";

/**
 * Accepts a form configuration object and returns components generated
 * using that configuration to create the Multistep Form UI.
 */
export function useMultiStepMultiSubmitFormBuilder<T>(
  formConfig: MultiSubmitFormType<T>,
) {
  // The default MultiSubmit Form Builder elements will all be used except for the `FormGroups` component
  const { FormGroups, ...multiSubmitFormElements } = useMultiSubmitFormBuilder(
    formConfig,
  );

  // Create components needed for a Multistep form
  const multiStepFormElements = useMultiStepFormBuilderElements(formConfig);

  return {
    ...multiSubmitFormElements,
    ...multiStepFormElements,
  };
}
