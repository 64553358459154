import * as React from "react";

import { Card, DefaultPageLayout, Link, Text } from "../../components";
import { Routes } from "../../constants/routes";
import { useDocumentTitle } from "../../hooks";

/**
 * The 404 page is rendered when the user requests a URL that the Router does not know how to handle,
 * or if the requested resource does not exist.
 */
export const NotFound: React.FC = () => {
  useDocumentTitle("404: Not Found");

  return (
    <DefaultPageLayout>
      <Card title="404: Not Found">
        <Text>Whoops! Looks like that page doesn&apos;t exist.</Text>
        <Text>
          Click <Link to={Routes.Home}>here</Link> to return to the home page.
        </Text>
      </Card>
    </DefaultPageLayout>
  );
};
