import styled from "styled-components";

import {
  Breakpoint,
  ZIndex,
  buttonColors,
  spacing,
  transition,
} from "../../../utils";

// Style props are exported so they may be included in the component's API
export type HamburgerStyleProps = {
  toggled: boolean;
};

// The height of each bar in the Hamburger
const BAR_HEIGHT = "2px";

/**
 * The three bar icon of the button, which transforms to an "X" shape when the button's state is toggled.
 */
export const HamburgerIcon = styled.span<HamburgerStyleProps>`
  display: block;
  position: relative;
  height: ${BAR_HEIGHT};
  width: ${spacing[6]};
  background: ${buttonColors.primary.text};

  ${transition({ property: "transform" })};
  transform: rotate(${(props) => (props.toggled ? "45deg" : "0deg")});

  /* Using CSS Pseudo elements, create the top and bottom bars of the Hamburger */
  &::before,
  &::after {
    content: "";
    position: absolute;
    display: block;
    height: ${BAR_HEIGHT};
    width: ${spacing[6]};
    background: ${buttonColors.primary.text};
  }
  &::before {
    transform: translateY(-5px);
    opacity: ${(props) => (props.toggled ? 0 : 1)};
  }
  &::after {
    ${transition({ property: "transform" })};
    transform: translateY(5px);
    transform: rotate(${(props) => (props.toggled ? "-90deg" : "")});
  }
`;

/**
 * Transparent wrapping button component.
 */
export const HamburgerWrapper = styled.button`
  position: relative;
  cursor: pointer;
  background: transparent;
  border: none;
  padding: ${spacing[4]};
  z-index: ${ZIndex.NavMenuToggleButton};

  /* On Medium and larger screens, hide the button
     (Note: This value should match the value on which the
     Nav Menu itself transforms into the desktop nav menu) */
  @media screen and (min-width: ${Breakpoint.Medium}) {
    display: none;
  }
`;
