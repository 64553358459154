/**
 * This file is automatically generated by GraphQL Codegen https://graphql-code-generator.com
 * Do not make changes directly to this file.
 */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

/** A User of the app, employed by Barn Pros. */
export type User = {
  __typename?: 'User';
  /** A unique identifier among all User entities. */
  id: Scalars['ID'];
  /** A User's name. */
  name: Scalars['String'];
  /** The current state of the User. */
  status: UserStatus;
  /** The level of access granted to the user, this value will be checked against before certain privileged actions are performed (ex: Creating a Supervisor Override punch). */
  role: UserRole;
  /** The department the User is associated with. */
  department: UserDepartment;
};

/** A list of possible states a user can be in. */
export enum UserStatus {
  NotActive = 'NotActive',
  Remote = 'Remote',
  OnSite = 'OnSite',
  InOffice = 'InOffice'
}

/** User role hierarchy. */
export enum UserRole {
  Admin = 'Admin',
  Basic = 'Basic',
  Supervisor = 'Supervisor'
}

/** A list of possible departments a user can be categorized by. */
export enum UserDepartment {
  Accounting = 'Accounting',
  Construction = 'Construction',
  Design = 'Design',
  Development = 'Development',
  Engineering = 'Engineering',
  Excavation = 'Excavation',
  Marketing = 'Marketing',
  Purchasing = 'Purchasing',
  Sales = 'Sales',
  Software = 'Software',
  Unkown = 'Unkown',
  Warehouse = 'Warehouse'
}

/** The status for each segment of a Client's project. */
export type ClientStatuses = {
  __typename?: 'ClientStatuses';
  /** The Construction status of a Client's Project */
  constructionStatus: ClientStatusState;
  /** The Engineering status of a Client's Project */
  engineeringStatus: ClientStatusState;
  /** The Excavation status of a Client's Project */
  excavationStatus: ClientStatusState;
  /** The "Finish Out" status of a Client's Project */
  finishOutStatus: ClientStatusState;
  /** The Job Costing status of a Client's Project */
  jobCostingStatus: ClientStatusState;
  /** The Permitting status of a Client's Project */
  permittingStatus: ClientStatusState;
  /** The Warehouse status of a Client's Project */
  warehouseStatus: ClientStatusState;
};

/** A list of possible states for each Client status. */
export enum ClientStatusState {
  NotApplicable = 'NotApplicable',
  InProgress = 'InProgress',
  Future = 'Future',
  Complete = 'Complete'
}

/** A Client who has a project under construction, or an internal entity under which Tasks are categorized. */
export type Client = {
  __typename?: 'Client';
  /** A unique identifier among all Client entities. */
  id: Scalars['ID'];
  /** A Client's name. */
  name: Scalars['String'];
  /** The status for each segment of a Client's project. */
  statuses: ClientStatuses;
};

/** A task which Users must perform to complete a project for a client or an ongoing task for the company. */
export type Task = {
  __typename?: 'Task';
  /** A unique identifier among all Task entities. */
  id: Scalars['ID'];
  /** A Client's name. */
  name: Scalars['String'];
  /** The department responsible for performing the task. */
  department: TaskDepartment;
  /** Indicates if the Task is an actual task of a stylized header used to deliniate between departments. */
  isHeader: Scalars['Boolean'];
};

/** A list of possible departments a task can be categorized by. */
export enum TaskDepartment {
  Construction = 'Construction',
  Design = 'Design',
  Excavation = 'Excavation',
  Office = 'Office',
  Software = 'Software',
  Warehouse = 'Warehouse'
}

/** A record of a punch created by a user. */
export type PunchRecord = {
  __typename?: 'PunchRecord';
  /** The ID of the user associated with the punch data. */
  userId: Scalars['String'];
  /** The client ID of the punch. */
  clientId: Scalars['String'];
  /** The task ID of the punch. */
  taskId: Scalars['String'];
};

/** Payload to be returned from the Current User query. */
export type CurrentUserPayload = {
  __typename?: 'CurrentUserPayload';
  /** The User object for the current user. */
  user: User;
  /** A record of the current user's most recently submitted punch. */
  punchRecord?: Maybe<PunchRecord>;
};

/** Standardized DTO for the Current User resolver to return. */
export type CurrentUserDto = {
  __typename?: 'CurrentUserDto';
  /** A contextual message to be returned with potential response data. */
  message: Scalars['String'];
  /** Object containing a record of the user's most recently submitted punch, and the User object for the current user. */
  payload: CurrentUserPayload;
};

/** Payload to be returned from the Login mutation. */
export type LoginPayload = {
  __typename?: 'LoginPayload';
  /** The Access token created for the user. */
  accessToken: Scalars['String'];
  /** The User object for the user logging in. */
  user: User;
  /** A record of the user's most recently submitted punch. */
  punchRecord?: Maybe<PunchRecord>;
};

/** Standardized DTO for the Login resolver to return. */
export type LoginDto = {
  __typename?: 'LoginDto';
  /** A contextual message to be returned with potential response data. */
  message: Scalars['String'];
  /** Object containing an Access token, a record of the user's most recently submitted punch, and the User object for the user logging in. */
  payload: LoginPayload;
};

/** Standardized DTO for the Logout resolver to return. */
export type LogoutDto = {
  __typename?: 'LogoutDto';
  /** A contextual message to be returned with potential response data. */
  message: Scalars['String'];
};

/** Standardized DTO for User resolvers to return. */
export type UserListApiResponseDto = {
  __typename?: 'UserListApiResponseDto';
  /** A contextual message to be returned with potential response data. */
  message: Scalars['String'];
  /** A list of Users requested by a user. */
  payload?: Maybe<Array<User>>;
};

/** Standardized DTO for Client resolvers to return. */
export type ClientListApiResponseDto = {
  __typename?: 'ClientListApiResponseDto';
  /** A contextual message to be returned with potential response data. */
  message: Scalars['String'];
  /** A list of Clients requested by a user. */
  payload?: Maybe<Array<Client>>;
};

/** Standardized DTO for the Punch creation resolvers to return. */
export type CreatePunchDto = {
  __typename?: 'CreatePunchDto';
  /** A contextual message to be returned with potential response data. */
  message: Scalars['String'];
};

/** Standardized DTO for Task resolvers to return. */
export type TaskListApiResponseDto = {
  __typename?: 'TaskListApiResponseDto';
  /** A contextual message to be returned with potential response data. */
  message: Scalars['String'];
  /** A list of Tasks requested by a user. */
  payload?: Maybe<Array<Task>>;
};

/** Dto consumed by the MatchmakerCreateQueue. */
export type MatchmakerCreateDto = {
  __typename?: 'MatchmakerCreateDto';
  /** Punch data passed from a User. */
  data: PunchDataUnion;
  /** The type of punch the User has created. */
  type: PunchType;
  /** The time the punch was created (in a formatted string). */
  time: Scalars['String'];
};

/** Union of all default Punch types. */
export type PunchDataUnion = InPunch | NewTaskPunch | OutPunch;

/** A punch created when a user clocks in. */
export type InPunch = {
  __typename?: 'InPunch';
  userId: Scalars['ID'];
  clientId: Scalars['ID'];
  taskId: Scalars['ID'];
  locationId: LocationId;
};

/** A list of location codes a punch can include. */
export enum LocationId {
  Unknown = 'Unknown',
  OfficeWarehouse = 'Office_Warehouse',
  OnSite = 'OnSite',
  Remote = 'Remote'
}

/** A punch created when a user changes their task. */
export type NewTaskPunch = {
  __typename?: 'NewTaskPunch';
  /** The ID of the User this punch is for. */
  userId: Scalars['ID'];
  /** The ID of the Client the User is working on/ for. */
  clientId: Scalars['ID'];
  /** The ID of the Task the User is switching to. */
  newTaskId: Scalars['ID'];
  /** The ID of the location in which the User is working. */
  locationId: LocationId;
  /** An optional ID of the Task the User is switching from, if that field needs to be ovewritten. */
  previousTaskOverrideId?: Maybe<Scalars['ID']>;
  /** An optional comment associated with the previous task the User is switching from. */
  previousTaskComment?: Maybe<Scalars['String']>;
};

/** A punch created when a user clocks out. */
export type OutPunch = {
  __typename?: 'OutPunch';
  /** The ID associated with the User this punch is for. */
  userId: Scalars['ID'];
  /** An optional comment associated with the shift the User is ending. */
  comment?: Maybe<Scalars['String']>;
};

/** A list of types of punches users can create. */
export enum PunchType {
  In = 'In',
  Out = 'Out',
  NewTask = 'NewTask'
}

/** Queue responsible for creating Matchmaker records in Smartsheet. */
export type MatchmakerCreateQueue = {
  __typename?: 'MatchmakerCreateQueue';
  /** The "friendly" display name associated with the queue. */
  name: QueueName;
  /** The current state of the queue, defaults to `Idle`. */
  state: QueueState;
  /** The number of rows present in the associated Smartsheet table, defaults to 0. */
  rowsInSmartsheetTable: Scalars['Int'];
  /** The number of jobs present in the queue, defaults to 0. */
  numberOfJobsInQueue: Scalars['Int'];
  /** The time (in milliseconds) the previous job took to complete. */
  previousJobDuration: Scalars['Int'];
  /** The number of consecutive jobs that have failed. */
  retryCounter: Scalars['Int'];
  /** The time (in milliseconds) the average job took to complete today, (resets at midnight). */
  averageDayJobDuration: Scalars['Int'];
  /** The time (in milliseconds) the average job took to complete this week, (resets at midnight on Sunday). */
  averageWeekJobDuration: Scalars['Int'];
  /** The time each job ran today took to complete, (resets at midnight). */
  jobsCompletedToday: Array<Scalars['Int']>;
  /** The time each job ran this week took to complete, (resets at midnight on Sunday). */
  jobsCompletedThisWeek: Array<Scalars['Int']>;
  /** The current job being processed by the queue. */
  currentJob?: Maybe<MatchmakerCreateDto>;
};

/** A list of known (friendly) names for queues. */
export enum QueueName {
  MatchmakerCreate = 'MatchmakerCreate',
  OvertimeCalculator = 'OvertimeCalculator',
  MatchmakerUpdate = 'MatchmakerUpdate'
}

/** A list of possilbe states a Queue can be in. */
export enum QueueState {
  Idle = 'Idle',
  Queued = 'Queued',
  Waiting = 'Waiting',
  ProcessingJob = 'ProcessingJob',
  Failing = 'Failing',
  Finishing = 'Finishing'
}

/** Dto consumed by the MatchmakerUpdateQueue. */
export type MatchmakerUpdateDto = {
  __typename?: 'MatchmakerUpdateDto';
  /** The row id generated by the MatchmakerCreate step. */
  matchmakerRowId: Scalars['String'];
  /** The cell id generated by the OvertimeCalculator step. */
  overtimeCalculatorSmartsheetId: Scalars['String'];
};

/** Queue responsible for updating Matchmaker records in Smartsheet after being processed by the Overtime Calculator. */
export type MatchmakerUpdateQueue = {
  __typename?: 'MatchmakerUpdateQueue';
  /** The "friendly" display name associated with the queue. */
  name: QueueName;
  /** The current state of the queue, defaults to `Idle`. */
  state: QueueState;
  /** The number of rows present in the associated Smartsheet table, defaults to 0. */
  rowsInSmartsheetTable: Scalars['Int'];
  /** The number of jobs present in the queue, defaults to 0. */
  numberOfJobsInQueue: Scalars['Int'];
  /** The time (in milliseconds) the previous job took to complete. */
  previousJobDuration: Scalars['Int'];
  /** The number of consecutive jobs that have failed. */
  retryCounter: Scalars['Int'];
  /** The time (in milliseconds) the average job took to complete today, (resets at midnight). */
  averageDayJobDuration: Scalars['Int'];
  /** The time (in milliseconds) the average job took to complete this week, (resets at midnight on Sunday). */
  averageWeekJobDuration: Scalars['Int'];
  /** The time each job ran today took to complete, (resets at midnight). */
  jobsCompletedToday: Array<Scalars['Int']>;
  /** The time each job ran this week took to complete, (resets at midnight on Sunday). */
  jobsCompletedThisWeek: Array<Scalars['Int']>;
  /** The current job being processed by the queue. */
  currentJob?: Maybe<MatchmakerUpdateDto>;
};

/** Dto consumed by the OvertimeCalculatorQueue. */
export type OvertimeCalculatorDto = {
  __typename?: 'OvertimeCalculatorDto';
  /** The row id generated by the MatchmakerCreate step. */
  matchmakerRowId: Scalars['String'];
  /** The cell id generated by the MatchmakerCreate step. */
  matchmakerSmartsheetId: Scalars['String'];
  /** The name of the user associated with the created punch. */
  userName: Scalars['String'];
};

export type OvertimeCalculatorQueue = {
  __typename?: 'OvertimeCalculatorQueue';
  /** The "friendly" display name associated with the queue. */
  name: QueueName;
  /** The current state of the queue, defaults to `Idle`. */
  state: QueueState;
  /** The number of rows present in the associated Smartsheet table, defaults to 0. */
  rowsInSmartsheetTable: Scalars['Int'];
  /** The number of jobs present in the queue, defaults to 0. */
  numberOfJobsInQueue: Scalars['Int'];
  /** The time (in milliseconds) the previous job took to complete. */
  previousJobDuration: Scalars['Int'];
  /** The number of consecutive jobs that have failed. */
  retryCounter: Scalars['Int'];
  /** The time (in milliseconds) the average job took to complete today, (resets at midnight). */
  averageDayJobDuration: Scalars['Int'];
  /** The time (in milliseconds) the average job took to complete this week, (resets at midnight on Sunday). */
  averageWeekJobDuration: Scalars['Int'];
  /** The time each job ran today took to complete, (resets at midnight). */
  jobsCompletedToday: Array<Scalars['Int']>;
  /** The time each job ran this week took to complete, (resets at midnight on Sunday). */
  jobsCompletedThisWeek: Array<Scalars['Int']>;
  currentJob?: Maybe<OvertimeCalculatorDto>;
};

/** The entity responsible for managing the queue system. */
export type QueueManager = {
  __typename?: 'QueueManager';
  /** The current state of the Queue Manager, defaults to `Idle`. */
  state: QueueManagerState;
  /** The name of the currently active queue. */
  currentQueueName: QueueName;
  /** The amount of time (in milliseconds) the Queue System has been operating. */
  uptime: Scalars['Int'];
};

/** A list of possible states the queue manager can be in. */
export enum QueueManagerState {
  Idle = 'Idle',
  Starting = 'Starting',
  Working = 'Working',
  Stopping = 'Stopping'
}

/** Payload to be returned from the QueueInformation mutation. */
export type QueueInformationPayload = {
  __typename?: 'QueueInformationPayload';
  /** The QueueManager entity responsible for orchestrating the Punch Queue system. */
  queueManager: QueueManager;
  /** The MatchmakerCreateQueue entity. */
  matchmakerCreateQueue: MatchmakerCreateQueue;
  /** The OvertimeCalculatorQueue entity. */
  overtimeCalculatorQueue: OvertimeCalculatorQueue;
  /** The MatchmakerUpdateQueue entity. */
  matchmakerUpdateQueue: MatchmakerUpdateQueue;
};

/** Standardized DTO for the QueueInformation resolver to return. */
export type QueueInformationDto = {
  __typename?: 'QueueInformationDto';
  /** A contextual message to be returned with potential response data. */
  message: Scalars['String'];
  /** Object containing health and status information about the Queue Manager and each queue. */
  payload: QueueInformationPayload;
};

/** Standardized DTO for the Support ticket creation resolvers to return. */
export type CreateSupportIssueDto = {
  __typename?: 'CreateSupportIssueDto';
  /** A contextual message to be returned with potential response data. */
  message: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  /** Gets user and punch record information for the current user. */
  currentUser: CurrentUserDto;
  /** Finds all User entities and returns them to the user. */
  findAllUsers: UserListApiResponseDto;
  findAllClients: ClientListApiResponseDto;
  /** Finds all Task entities and returns them to the user. */
  findAllTasks: TaskListApiResponseDto;
  /** Gets information about each queue as well as the Queue Manager. */
  getQueueInformation: QueueInformationDto;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Logs a user in using their pin, will return a refresh token if the user has opted in to the use of cookies. */
  login: LoginDto;
  /** Logs a user out by clearing their refresh token. */
  logout: LogoutDto;
  /** Allows an Admin user to update the locally stored cache of User entities. */
  updateUsersCache: UserListApiResponseDto;
  /** Allows an Admin user to update the locally stored cache of Client entities. */
  updateClientsCache: ClientListApiResponseDto;
  /** Allows an Admin user to update the locally stored cache of Task entities. */
  updateTasksCache: TaskListApiResponseDto;
  /** Creates and inserts an `In` Punch into the Queue Manager system. */
  createInPunch: CreatePunchDto;
  /** Creates and inserts an `In` Punch Override into the Queue Manager system. */
  createInPunchOverride: CreatePunchDto;
  /** Creates and inserts a `New Task` Punch into the Queue Manager system. */
  createNewTaskPunch: CreatePunchDto;
  /** Creates and inserts a `New Task` Punch Override into the Queue Manager system. */
  createNewTaskPunchOverride: CreatePunchDto;
  /** Creates and inserts an `Out` Punch into the Queue Manager system. */
  createOutPunch: CreatePunchDto;
  /** Creates and inserts an `Out` Punch Override into the Queue Manager system. */
  createOutPunchOverride: CreatePunchDto;
  /** Submits a Bug Report. */
  createBugReport: CreateSupportIssueDto;
  /** Submits a Feature Request. */
  createFeatureRequest: CreateSupportIssueDto;
  /** Submits a General Feedback Report. */
  createGeneralFeedbackReport: CreateSupportIssueDto;
};


export type MutationLoginArgs = {
  data: LoginInput;
};


export type MutationCreateInPunchArgs = {
  data: CreateInPunchInput;
};


export type MutationCreateInPunchOverrideArgs = {
  data: CreateInPunchOverrideInput;
};


export type MutationCreateNewTaskPunchArgs = {
  data: CreateNewTaskPunchInput;
};


export type MutationCreateNewTaskPunchOverrideArgs = {
  data: CreateNewTaskPunchOverrideInput;
};


export type MutationCreateOutPunchArgs = {
  data: CreateOutPunchInput;
};


export type MutationCreateOutPunchOverrideArgs = {
  data: CreateOutPunchOverrideInput;
};


export type MutationCreateBugReportArgs = {
  data: CreateBugReportInput;
};


export type MutationCreateFeatureRequestArgs = {
  data: CreateFeatureRequestInput;
};


export type MutationCreateGeneralFeedbackReportArgs = {
  data: CreateGeneralFeedbackReportInput;
};

/** Input used to log users in. */
export type LoginInput = {
  /** The user's four digit pin. */
  pin: Scalars['String'];
  /** An opt in flag indicating that the user wants to remain logged in using a cookie. */
  doesUserAcceptCookies?: Maybe<Scalars['Boolean']>;
};

/** Input used to create `In` punches. */
export type CreateInPunchInput = {
  /** The ID of the User this punch is for. */
  userId: Scalars['ID'];
  /** The ID of the Client the User is working on/ for. */
  clientId: Scalars['ID'];
  /** The ID of the Task the User is performing. */
  taskId: Scalars['ID'];
  /** The ID of the location in which the User is working. */
  locationId: LocationId;
};

/** Extends the default `CreateInPunchInput` and adds fields required to create a Supervisor override of this type. */
export type CreateInPunchOverrideInput = {
  /** The ID of the User this punch is for. */
  userId: Scalars['ID'];
  /** The ID of the Client the User is working on/ for. */
  clientId: Scalars['ID'];
  /** The ID of the Task the User is performing. */
  taskId: Scalars['ID'];
  /** The ID of the location in which the User is working. */
  locationId: LocationId;
  /** The id of the Supervisor tier User creating the Override punch. */
  supervisorId: Scalars['String'];
  /** The time the override punch is being created with. */
  time: Scalars['DateTime'];
};


/** Input used to create `NewTask` punches. */
export type CreateNewTaskPunchInput = {
  /** The ID associated with the User this punch is for. */
  userId: Scalars['ID'];
  /** The ID associated with the Client the User is working on/ for. */
  clientId: Scalars['ID'];
  /** The ID associated with the Task the User is switching to. */
  newTaskId: Scalars['ID'];
  /** The ID associated with the location in which the User is working. */
  locationId: LocationId;
  /** An optional ID associated with the Task the User is switching from, if that field needs to be ovewritten. */
  previousTaskOverrideId?: Maybe<Scalars['ID']>;
  /** An optional comment associated with the previous task the User is switching from. */
  previousTaskComment?: Maybe<Scalars['String']>;
};

/** Extends the default `CreateNewTaskPunchInput` and adds fields required to create a Supervisor override of this type. */
export type CreateNewTaskPunchOverrideInput = {
  /** The ID associated with the User this punch is for. */
  userId: Scalars['ID'];
  /** The ID associated with the Client the User is working on/ for. */
  clientId: Scalars['ID'];
  /** The ID associated with the Task the User is switching to. */
  newTaskId: Scalars['ID'];
  /** The ID associated with the location in which the User is working. */
  locationId: LocationId;
  /** An optional ID associated with the Task the User is switching from, if that field needs to be ovewritten. */
  previousTaskOverrideId?: Maybe<Scalars['ID']>;
  /** An optional comment associated with the previous task the User is switching from. */
  previousTaskComment?: Maybe<Scalars['String']>;
  /** The id of the Supervisor tier User creating the Override punch. */
  supervisorId: Scalars['String'];
  /** The time the override punch is being created with. */
  time: Scalars['DateTime'];
};

/** Input used to create `Out` punches. */
export type CreateOutPunchInput = {
  /** The ID associated with the User this punch is for. */
  userId: Scalars['ID'];
  /** An optional comment associated with the shift the User is closing. */
  comment?: Maybe<Scalars['String']>;
};

/** Extends the default `CreateOutPunchInput` and adds fields required to create a Supervisor override of this type. */
export type CreateOutPunchOverrideInput = {
  /** The ID associated with the User this punch is for. */
  userId: Scalars['ID'];
  /** An optional comment associated with the shift the User is closing. */
  comment?: Maybe<Scalars['String']>;
  /** The id of the Supervisor tier User creating the Override punch. */
  supervisorId: Scalars['String'];
  /** The time the override punch is being created with. */
  time: Scalars['DateTime'];
};

/** Input used to provide information needed to generate a bug report. */
export type CreateBugReportInput = {
  /** The behavior the user was expecting. */
  expectedBehavior: Scalars['String'];
  /** The behavior the user is experiencing. */
  currentBehavior: Scalars['String'];
  /** What the user was doing when they encountered the issue. */
  issueContext?: Maybe<Scalars['String']>;
  /** The user's system information, such as Operating System, Browser, Etc. */
  userEnvironment?: Maybe<Scalars['String']>;
};

/** Input used to provide information needed to generate a feature request. */
export type CreateFeatureRequestInput = {
  /** A description of the feature the user is requesting. */
  description: Scalars['String'];
};

/** Input used to provide information needed to create a general feeback report. */
export type CreateGeneralFeedbackReportInput = {
  /** A message sent by the user. */
  message: Scalars['String'];
};

export type ClientFragment = (
  { __typename?: 'Client' }
  & Pick<Client, 'id' | 'name'>
);

export type MatchmakerCreateQueueFragment = (
  { __typename?: 'MatchmakerCreateQueue' }
  & Pick<MatchmakerCreateQueue, 'name' | 'state' | 'previousJobDuration' | 'rowsInSmartsheetTable' | 'numberOfJobsInQueue' | 'averageDayJobDuration' | 'averageWeekJobDuration' | 'jobsCompletedToday' | 'jobsCompletedThisWeek' | 'retryCounter'>
);

export type OvertimeCalculatorQueueFragment = (
  { __typename?: 'OvertimeCalculatorQueue' }
  & Pick<OvertimeCalculatorQueue, 'name' | 'state' | 'previousJobDuration' | 'rowsInSmartsheetTable' | 'numberOfJobsInQueue' | 'averageDayJobDuration' | 'averageWeekJobDuration' | 'jobsCompletedToday' | 'jobsCompletedThisWeek' | 'retryCounter'>
);

export type MatchmakerUpdateQueueFragment = (
  { __typename?: 'MatchmakerUpdateQueue' }
  & Pick<MatchmakerUpdateQueue, 'name' | 'state' | 'previousJobDuration' | 'rowsInSmartsheetTable' | 'numberOfJobsInQueue' | 'averageDayJobDuration' | 'averageWeekJobDuration' | 'jobsCompletedToday' | 'jobsCompletedThisWeek' | 'retryCounter'>
);

export type TaskFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'id' | 'name' | 'isHeader' | 'department'>
);

export type UserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'name' | 'role' | 'department' | 'status'>
);

export type CreateBugReportMutationVariables = Exact<{
  data: CreateBugReportInput;
}>;


export type CreateBugReportMutation = (
  { __typename?: 'Mutation' }
  & { createBugReport: (
    { __typename?: 'CreateSupportIssueDto' }
    & Pick<CreateSupportIssueDto, 'message'>
  ) }
);

export type CreateFeatureRequestMutationVariables = Exact<{
  data: CreateFeatureRequestInput;
}>;


export type CreateFeatureRequestMutation = (
  { __typename?: 'Mutation' }
  & { createFeatureRequest: (
    { __typename?: 'CreateSupportIssueDto' }
    & Pick<CreateSupportIssueDto, 'message'>
  ) }
);

export type CreateGeneralFeedbackReportMutationVariables = Exact<{
  data: CreateGeneralFeedbackReportInput;
}>;


export type CreateGeneralFeedbackReportMutation = (
  { __typename?: 'Mutation' }
  & { createGeneralFeedbackReport: (
    { __typename?: 'CreateSupportIssueDto' }
    & Pick<CreateSupportIssueDto, 'message'>
  ) }
);

export type CreateInPunchMutationVariables = Exact<{
  data: CreateInPunchInput;
}>;


export type CreateInPunchMutation = (
  { __typename?: 'Mutation' }
  & { createInPunch: (
    { __typename?: 'CreatePunchDto' }
    & Pick<CreatePunchDto, 'message'>
  ) }
);

export type CreateInPunchOverrideMutationVariables = Exact<{
  data: CreateInPunchOverrideInput;
}>;


export type CreateInPunchOverrideMutation = (
  { __typename?: 'Mutation' }
  & { createInPunchOverride: (
    { __typename?: 'CreatePunchDto' }
    & Pick<CreatePunchDto, 'message'>
  ) }
);

export type CreateNewTaskPunchMutationVariables = Exact<{
  data: CreateNewTaskPunchInput;
}>;


export type CreateNewTaskPunchMutation = (
  { __typename?: 'Mutation' }
  & { createNewTaskPunch: (
    { __typename?: 'CreatePunchDto' }
    & Pick<CreatePunchDto, 'message'>
  ) }
);

export type CreateNewTaskPunchOverrideMutationVariables = Exact<{
  data: CreateNewTaskPunchOverrideInput;
}>;


export type CreateNewTaskPunchOverrideMutation = (
  { __typename?: 'Mutation' }
  & { createNewTaskPunchOverride: (
    { __typename?: 'CreatePunchDto' }
    & Pick<CreatePunchDto, 'message'>
  ) }
);

export type CreateOutPunchMutationVariables = Exact<{
  data: CreateOutPunchInput;
}>;


export type CreateOutPunchMutation = (
  { __typename?: 'Mutation' }
  & { createOutPunch: (
    { __typename?: 'CreatePunchDto' }
    & Pick<CreatePunchDto, 'message'>
  ) }
);

export type CreateOutPunchOverrideMutationVariables = Exact<{
  data: CreateOutPunchOverrideInput;
}>;


export type CreateOutPunchOverrideMutation = (
  { __typename?: 'Mutation' }
  & { createOutPunchOverride: (
    { __typename?: 'CreatePunchDto' }
    & Pick<CreatePunchDto, 'message'>
  ) }
);

export type LoginMutationVariables = Exact<{
  data: LoginInput;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginDto' }
    & Pick<LoginDto, 'message'>
    & { payload: (
      { __typename?: 'LoginPayload' }
      & Pick<LoginPayload, 'accessToken'>
      & { user: (
        { __typename?: 'User' }
        & UserFragment
      ) }
    ) }
  ) }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & { logout: (
    { __typename?: 'LogoutDto' }
    & Pick<LogoutDto, 'message'>
  ) }
);

export type RefreshSmartsheetCachesMutationVariables = Exact<{ [key: string]: never; }>;


export type RefreshSmartsheetCachesMutation = (
  { __typename?: 'Mutation' }
  & { updateClientsCache: (
    { __typename?: 'ClientListApiResponseDto' }
    & { payload?: Maybe<Array<(
      { __typename?: 'Client' }
      & ClientFragment
    )>> }
  ), updateTasksCache: (
    { __typename?: 'TaskListApiResponseDto' }
    & { payload?: Maybe<Array<(
      { __typename?: 'Task' }
      & TaskFragment
    )>> }
  ), updateUsersCache: (
    { __typename?: 'UserListApiResponseDto' }
    & { payload?: Maybe<Array<(
      { __typename?: 'User' }
      & UserFragment
    )>> }
  ) }
);

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = (
  { __typename?: 'Query' }
  & { currentUser: (
    { __typename?: 'CurrentUserDto' }
    & { payload: (
      { __typename?: 'CurrentUserPayload' }
      & { user: (
        { __typename?: 'User' }
        & UserFragment
      ), punchRecord?: Maybe<(
        { __typename?: 'PunchRecord' }
        & Pick<PunchRecord, 'userId' | 'clientId' | 'taskId'>
      )> }
    ) }
  ) }
);

export type DashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type DashboardQuery = (
  { __typename?: 'Query' }
  & { getQueueInformation: (
    { __typename?: 'QueueInformationDto' }
    & { payload: (
      { __typename?: 'QueueInformationPayload' }
      & { queueManager: (
        { __typename?: 'QueueManager' }
        & Pick<QueueManager, 'state' | 'currentQueueName' | 'uptime'>
      ), matchmakerCreateQueue: (
        { __typename?: 'MatchmakerCreateQueue' }
        & MatchmakerCreateQueueFragment
      ), overtimeCalculatorQueue: (
        { __typename?: 'OvertimeCalculatorQueue' }
        & OvertimeCalculatorQueueFragment
      ), matchmakerUpdateQueue: (
        { __typename?: 'MatchmakerUpdateQueue' }
        & MatchmakerUpdateQueueFragment
      ) }
    ) }
  ), findAllClients: (
    { __typename?: 'ClientListApiResponseDto' }
    & { payload?: Maybe<Array<(
      { __typename?: 'Client' }
      & ClientFragment
    )>> }
  ), findAllTasks: (
    { __typename?: 'TaskListApiResponseDto' }
    & { payload?: Maybe<Array<(
      { __typename?: 'Task' }
      & TaskFragment
    )>> }
  ), findAllUsers: (
    { __typename?: 'UserListApiResponseDto' }
    & { payload?: Maybe<Array<(
      { __typename?: 'User' }
      & UserFragment
    )>> }
  ) }
);

export type FindAllClientsQueryVariables = Exact<{ [key: string]: never; }>;


export type FindAllClientsQuery = (
  { __typename?: 'Query' }
  & { findAllClients: (
    { __typename?: 'ClientListApiResponseDto' }
    & { payload?: Maybe<Array<(
      { __typename?: 'Client' }
      & ClientFragment
    )>> }
  ) }
);

export type FindAllTasksQueryVariables = Exact<{ [key: string]: never; }>;


export type FindAllTasksQuery = (
  { __typename?: 'Query' }
  & { findAllTasks: (
    { __typename?: 'TaskListApiResponseDto' }
    & { payload?: Maybe<Array<(
      { __typename?: 'Task' }
      & TaskFragment
    )>> }
  ) }
);

export type FindAllUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type FindAllUsersQuery = (
  { __typename?: 'Query' }
  & { findAllUsers: (
    { __typename?: 'UserListApiResponseDto' }
    & { payload?: Maybe<Array<(
      { __typename?: 'User' }
      & UserFragment
    )>> }
  ) }
);

export type GetQueueInformationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetQueueInformationQuery = (
  { __typename?: 'Query' }
  & { getQueueInformation: (
    { __typename?: 'QueueInformationDto' }
    & { payload: (
      { __typename?: 'QueueInformationPayload' }
      & { queueManager: (
        { __typename?: 'QueueManager' }
        & Pick<QueueManager, 'state' | 'currentQueueName' | 'uptime'>
      ), matchmakerCreateQueue: (
        { __typename?: 'MatchmakerCreateQueue' }
        & MatchmakerCreateQueueFragment
      ), overtimeCalculatorQueue: (
        { __typename?: 'OvertimeCalculatorQueue' }
        & OvertimeCalculatorQueueFragment
      ), matchmakerUpdateQueue: (
        { __typename?: 'MatchmakerUpdateQueue' }
        & MatchmakerUpdateQueueFragment
      ) }
    ) }
  ) }
);

export const ClientFragmentDoc = gql`
    fragment Client on Client {
  id
  name
}
    `;
export const MatchmakerCreateQueueFragmentDoc = gql`
    fragment MatchmakerCreateQueue on MatchmakerCreateQueue {
  name
  state
  previousJobDuration
  rowsInSmartsheetTable
  numberOfJobsInQueue
  averageDayJobDuration
  averageWeekJobDuration
  jobsCompletedToday
  jobsCompletedThisWeek
  retryCounter
}
    `;
export const OvertimeCalculatorQueueFragmentDoc = gql`
    fragment OvertimeCalculatorQueue on OvertimeCalculatorQueue {
  name
  state
  previousJobDuration
  rowsInSmartsheetTable
  numberOfJobsInQueue
  averageDayJobDuration
  averageWeekJobDuration
  jobsCompletedToday
  jobsCompletedThisWeek
  retryCounter
}
    `;
export const MatchmakerUpdateQueueFragmentDoc = gql`
    fragment MatchmakerUpdateQueue on MatchmakerUpdateQueue {
  name
  state
  previousJobDuration
  rowsInSmartsheetTable
  numberOfJobsInQueue
  averageDayJobDuration
  averageWeekJobDuration
  jobsCompletedToday
  jobsCompletedThisWeek
  retryCounter
}
    `;
export const TaskFragmentDoc = gql`
    fragment Task on Task {
  id
  name
  isHeader
  department
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  name
  role
  department
  status
}
    `;
export const CreateBugReportDocument = gql`
    mutation CreateBugReport($data: CreateBugReportInput!) {
  createBugReport(data: $data) {
    message
  }
}
    `;
export type CreateBugReportMutationFn = Apollo.MutationFunction<CreateBugReportMutation, CreateBugReportMutationVariables>;

/**
 * __useCreateBugReportMutation__
 *
 * To run a mutation, you first call `useCreateBugReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBugReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBugReportMutation, { data, loading, error }] = useCreateBugReportMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateBugReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateBugReportMutation, CreateBugReportMutationVariables>) {
        return Apollo.useMutation<CreateBugReportMutation, CreateBugReportMutationVariables>(CreateBugReportDocument, baseOptions);
      }
export type CreateBugReportMutationHookResult = ReturnType<typeof useCreateBugReportMutation>;
export type CreateBugReportMutationResult = Apollo.MutationResult<CreateBugReportMutation>;
export type CreateBugReportMutationOptions = Apollo.BaseMutationOptions<CreateBugReportMutation, CreateBugReportMutationVariables>;
export const CreateFeatureRequestDocument = gql`
    mutation CreateFeatureRequest($data: CreateFeatureRequestInput!) {
  createFeatureRequest(data: $data) {
    message
  }
}
    `;
export type CreateFeatureRequestMutationFn = Apollo.MutationFunction<CreateFeatureRequestMutation, CreateFeatureRequestMutationVariables>;

/**
 * __useCreateFeatureRequestMutation__
 *
 * To run a mutation, you first call `useCreateFeatureRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeatureRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeatureRequestMutation, { data, loading, error }] = useCreateFeatureRequestMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateFeatureRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreateFeatureRequestMutation, CreateFeatureRequestMutationVariables>) {
        return Apollo.useMutation<CreateFeatureRequestMutation, CreateFeatureRequestMutationVariables>(CreateFeatureRequestDocument, baseOptions);
      }
export type CreateFeatureRequestMutationHookResult = ReturnType<typeof useCreateFeatureRequestMutation>;
export type CreateFeatureRequestMutationResult = Apollo.MutationResult<CreateFeatureRequestMutation>;
export type CreateFeatureRequestMutationOptions = Apollo.BaseMutationOptions<CreateFeatureRequestMutation, CreateFeatureRequestMutationVariables>;
export const CreateGeneralFeedbackReportDocument = gql`
    mutation CreateGeneralFeedbackReport($data: CreateGeneralFeedbackReportInput!) {
  createGeneralFeedbackReport(data: $data) {
    message
  }
}
    `;
export type CreateGeneralFeedbackReportMutationFn = Apollo.MutationFunction<CreateGeneralFeedbackReportMutation, CreateGeneralFeedbackReportMutationVariables>;

/**
 * __useCreateGeneralFeedbackReportMutation__
 *
 * To run a mutation, you first call `useCreateGeneralFeedbackReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGeneralFeedbackReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGeneralFeedbackReportMutation, { data, loading, error }] = useCreateGeneralFeedbackReportMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateGeneralFeedbackReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateGeneralFeedbackReportMutation, CreateGeneralFeedbackReportMutationVariables>) {
        return Apollo.useMutation<CreateGeneralFeedbackReportMutation, CreateGeneralFeedbackReportMutationVariables>(CreateGeneralFeedbackReportDocument, baseOptions);
      }
export type CreateGeneralFeedbackReportMutationHookResult = ReturnType<typeof useCreateGeneralFeedbackReportMutation>;
export type CreateGeneralFeedbackReportMutationResult = Apollo.MutationResult<CreateGeneralFeedbackReportMutation>;
export type CreateGeneralFeedbackReportMutationOptions = Apollo.BaseMutationOptions<CreateGeneralFeedbackReportMutation, CreateGeneralFeedbackReportMutationVariables>;
export const CreateInPunchDocument = gql`
    mutation CreateInPunch($data: CreateInPunchInput!) {
  createInPunch(data: $data) {
    message
  }
}
    `;
export type CreateInPunchMutationFn = Apollo.MutationFunction<CreateInPunchMutation, CreateInPunchMutationVariables>;

/**
 * __useCreateInPunchMutation__
 *
 * To run a mutation, you first call `useCreateInPunchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInPunchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInPunchMutation, { data, loading, error }] = useCreateInPunchMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateInPunchMutation(baseOptions?: Apollo.MutationHookOptions<CreateInPunchMutation, CreateInPunchMutationVariables>) {
        return Apollo.useMutation<CreateInPunchMutation, CreateInPunchMutationVariables>(CreateInPunchDocument, baseOptions);
      }
export type CreateInPunchMutationHookResult = ReturnType<typeof useCreateInPunchMutation>;
export type CreateInPunchMutationResult = Apollo.MutationResult<CreateInPunchMutation>;
export type CreateInPunchMutationOptions = Apollo.BaseMutationOptions<CreateInPunchMutation, CreateInPunchMutationVariables>;
export const CreateInPunchOverrideDocument = gql`
    mutation CreateInPunchOverride($data: CreateInPunchOverrideInput!) {
  createInPunchOverride(data: $data) {
    message
  }
}
    `;
export type CreateInPunchOverrideMutationFn = Apollo.MutationFunction<CreateInPunchOverrideMutation, CreateInPunchOverrideMutationVariables>;

/**
 * __useCreateInPunchOverrideMutation__
 *
 * To run a mutation, you first call `useCreateInPunchOverrideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInPunchOverrideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInPunchOverrideMutation, { data, loading, error }] = useCreateInPunchOverrideMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateInPunchOverrideMutation(baseOptions?: Apollo.MutationHookOptions<CreateInPunchOverrideMutation, CreateInPunchOverrideMutationVariables>) {
        return Apollo.useMutation<CreateInPunchOverrideMutation, CreateInPunchOverrideMutationVariables>(CreateInPunchOverrideDocument, baseOptions);
      }
export type CreateInPunchOverrideMutationHookResult = ReturnType<typeof useCreateInPunchOverrideMutation>;
export type CreateInPunchOverrideMutationResult = Apollo.MutationResult<CreateInPunchOverrideMutation>;
export type CreateInPunchOverrideMutationOptions = Apollo.BaseMutationOptions<CreateInPunchOverrideMutation, CreateInPunchOverrideMutationVariables>;
export const CreateNewTaskPunchDocument = gql`
    mutation CreateNewTaskPunch($data: CreateNewTaskPunchInput!) {
  createNewTaskPunch(data: $data) {
    message
  }
}
    `;
export type CreateNewTaskPunchMutationFn = Apollo.MutationFunction<CreateNewTaskPunchMutation, CreateNewTaskPunchMutationVariables>;

/**
 * __useCreateNewTaskPunchMutation__
 *
 * To run a mutation, you first call `useCreateNewTaskPunchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewTaskPunchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewTaskPunchMutation, { data, loading, error }] = useCreateNewTaskPunchMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateNewTaskPunchMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewTaskPunchMutation, CreateNewTaskPunchMutationVariables>) {
        return Apollo.useMutation<CreateNewTaskPunchMutation, CreateNewTaskPunchMutationVariables>(CreateNewTaskPunchDocument, baseOptions);
      }
export type CreateNewTaskPunchMutationHookResult = ReturnType<typeof useCreateNewTaskPunchMutation>;
export type CreateNewTaskPunchMutationResult = Apollo.MutationResult<CreateNewTaskPunchMutation>;
export type CreateNewTaskPunchMutationOptions = Apollo.BaseMutationOptions<CreateNewTaskPunchMutation, CreateNewTaskPunchMutationVariables>;
export const CreateNewTaskPunchOverrideDocument = gql`
    mutation CreateNewTaskPunchOverride($data: CreateNewTaskPunchOverrideInput!) {
  createNewTaskPunchOverride(data: $data) {
    message
  }
}
    `;
export type CreateNewTaskPunchOverrideMutationFn = Apollo.MutationFunction<CreateNewTaskPunchOverrideMutation, CreateNewTaskPunchOverrideMutationVariables>;

/**
 * __useCreateNewTaskPunchOverrideMutation__
 *
 * To run a mutation, you first call `useCreateNewTaskPunchOverrideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewTaskPunchOverrideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewTaskPunchOverrideMutation, { data, loading, error }] = useCreateNewTaskPunchOverrideMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateNewTaskPunchOverrideMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewTaskPunchOverrideMutation, CreateNewTaskPunchOverrideMutationVariables>) {
        return Apollo.useMutation<CreateNewTaskPunchOverrideMutation, CreateNewTaskPunchOverrideMutationVariables>(CreateNewTaskPunchOverrideDocument, baseOptions);
      }
export type CreateNewTaskPunchOverrideMutationHookResult = ReturnType<typeof useCreateNewTaskPunchOverrideMutation>;
export type CreateNewTaskPunchOverrideMutationResult = Apollo.MutationResult<CreateNewTaskPunchOverrideMutation>;
export type CreateNewTaskPunchOverrideMutationOptions = Apollo.BaseMutationOptions<CreateNewTaskPunchOverrideMutation, CreateNewTaskPunchOverrideMutationVariables>;
export const CreateOutPunchDocument = gql`
    mutation CreateOutPunch($data: CreateOutPunchInput!) {
  createOutPunch(data: $data) {
    message
  }
}
    `;
export type CreateOutPunchMutationFn = Apollo.MutationFunction<CreateOutPunchMutation, CreateOutPunchMutationVariables>;

/**
 * __useCreateOutPunchMutation__
 *
 * To run a mutation, you first call `useCreateOutPunchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOutPunchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOutPunchMutation, { data, loading, error }] = useCreateOutPunchMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOutPunchMutation(baseOptions?: Apollo.MutationHookOptions<CreateOutPunchMutation, CreateOutPunchMutationVariables>) {
        return Apollo.useMutation<CreateOutPunchMutation, CreateOutPunchMutationVariables>(CreateOutPunchDocument, baseOptions);
      }
export type CreateOutPunchMutationHookResult = ReturnType<typeof useCreateOutPunchMutation>;
export type CreateOutPunchMutationResult = Apollo.MutationResult<CreateOutPunchMutation>;
export type CreateOutPunchMutationOptions = Apollo.BaseMutationOptions<CreateOutPunchMutation, CreateOutPunchMutationVariables>;
export const CreateOutPunchOverrideDocument = gql`
    mutation CreateOutPunchOverride($data: CreateOutPunchOverrideInput!) {
  createOutPunchOverride(data: $data) {
    message
  }
}
    `;
export type CreateOutPunchOverrideMutationFn = Apollo.MutationFunction<CreateOutPunchOverrideMutation, CreateOutPunchOverrideMutationVariables>;

/**
 * __useCreateOutPunchOverrideMutation__
 *
 * To run a mutation, you first call `useCreateOutPunchOverrideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOutPunchOverrideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOutPunchOverrideMutation, { data, loading, error }] = useCreateOutPunchOverrideMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOutPunchOverrideMutation(baseOptions?: Apollo.MutationHookOptions<CreateOutPunchOverrideMutation, CreateOutPunchOverrideMutationVariables>) {
        return Apollo.useMutation<CreateOutPunchOverrideMutation, CreateOutPunchOverrideMutationVariables>(CreateOutPunchOverrideDocument, baseOptions);
      }
export type CreateOutPunchOverrideMutationHookResult = ReturnType<typeof useCreateOutPunchOverrideMutation>;
export type CreateOutPunchOverrideMutationResult = Apollo.MutationResult<CreateOutPunchOverrideMutation>;
export type CreateOutPunchOverrideMutationOptions = Apollo.BaseMutationOptions<CreateOutPunchOverrideMutation, CreateOutPunchOverrideMutationVariables>;
export const LoginDocument = gql`
    mutation Login($data: LoginInput!) {
  login(data: $data) {
    message
    payload {
      accessToken
      user {
        ...User
      }
    }
  }
}
    ${UserFragmentDoc}`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout {
    message
  }
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const RefreshSmartsheetCachesDocument = gql`
    mutation RefreshSmartsheetCaches {
  updateClientsCache {
    payload {
      ...Client
    }
  }
  updateTasksCache {
    payload {
      ...Task
    }
  }
  updateUsersCache {
    payload {
      ...User
    }
  }
}
    ${ClientFragmentDoc}
${TaskFragmentDoc}
${UserFragmentDoc}`;
export type RefreshSmartsheetCachesMutationFn = Apollo.MutationFunction<RefreshSmartsheetCachesMutation, RefreshSmartsheetCachesMutationVariables>;

/**
 * __useRefreshSmartsheetCachesMutation__
 *
 * To run a mutation, you first call `useRefreshSmartsheetCachesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshSmartsheetCachesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshSmartsheetCachesMutation, { data, loading, error }] = useRefreshSmartsheetCachesMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshSmartsheetCachesMutation(baseOptions?: Apollo.MutationHookOptions<RefreshSmartsheetCachesMutation, RefreshSmartsheetCachesMutationVariables>) {
        return Apollo.useMutation<RefreshSmartsheetCachesMutation, RefreshSmartsheetCachesMutationVariables>(RefreshSmartsheetCachesDocument, baseOptions);
      }
export type RefreshSmartsheetCachesMutationHookResult = ReturnType<typeof useRefreshSmartsheetCachesMutation>;
export type RefreshSmartsheetCachesMutationResult = Apollo.MutationResult<RefreshSmartsheetCachesMutation>;
export type RefreshSmartsheetCachesMutationOptions = Apollo.BaseMutationOptions<RefreshSmartsheetCachesMutation, RefreshSmartsheetCachesMutationVariables>;
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    payload {
      user {
        ...User
      }
      punchRecord {
        userId
        clientId
        taskId
      }
    }
  }
}
    ${UserFragmentDoc}`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const DashboardDocument = gql`
    query Dashboard {
  getQueueInformation {
    payload {
      queueManager {
        state
        currentQueueName
        uptime
      }
      matchmakerCreateQueue {
        ...MatchmakerCreateQueue
      }
      overtimeCalculatorQueue {
        ...OvertimeCalculatorQueue
      }
      matchmakerUpdateQueue {
        ...MatchmakerUpdateQueue
      }
    }
  }
  findAllClients {
    payload {
      ...Client
    }
  }
  findAllTasks {
    payload {
      ...Task
    }
  }
  findAllUsers {
    payload {
      ...User
    }
  }
}
    ${MatchmakerCreateQueueFragmentDoc}
${OvertimeCalculatorQueueFragmentDoc}
${MatchmakerUpdateQueueFragmentDoc}
${ClientFragmentDoc}
${TaskFragmentDoc}
${UserFragmentDoc}`;

/**
 * __useDashboardQuery__
 *
 * To run a query within a React component, call `useDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardQuery(baseOptions?: Apollo.QueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
        return Apollo.useQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, baseOptions);
      }
export function useDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
          return Apollo.useLazyQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, baseOptions);
        }
export type DashboardQueryHookResult = ReturnType<typeof useDashboardQuery>;
export type DashboardLazyQueryHookResult = ReturnType<typeof useDashboardLazyQuery>;
export type DashboardQueryResult = Apollo.QueryResult<DashboardQuery, DashboardQueryVariables>;
export const FindAllClientsDocument = gql`
    query FindAllClients {
  findAllClients {
    payload {
      ...Client
    }
  }
}
    ${ClientFragmentDoc}`;

/**
 * __useFindAllClientsQuery__
 *
 * To run a query within a React component, call `useFindAllClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllClientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindAllClientsQuery(baseOptions?: Apollo.QueryHookOptions<FindAllClientsQuery, FindAllClientsQueryVariables>) {
        return Apollo.useQuery<FindAllClientsQuery, FindAllClientsQueryVariables>(FindAllClientsDocument, baseOptions);
      }
export function useFindAllClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllClientsQuery, FindAllClientsQueryVariables>) {
          return Apollo.useLazyQuery<FindAllClientsQuery, FindAllClientsQueryVariables>(FindAllClientsDocument, baseOptions);
        }
export type FindAllClientsQueryHookResult = ReturnType<typeof useFindAllClientsQuery>;
export type FindAllClientsLazyQueryHookResult = ReturnType<typeof useFindAllClientsLazyQuery>;
export type FindAllClientsQueryResult = Apollo.QueryResult<FindAllClientsQuery, FindAllClientsQueryVariables>;
export const FindAllTasksDocument = gql`
    query FindAllTasks {
  findAllTasks {
    payload {
      ...Task
    }
  }
}
    ${TaskFragmentDoc}`;

/**
 * __useFindAllTasksQuery__
 *
 * To run a query within a React component, call `useFindAllTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllTasksQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindAllTasksQuery(baseOptions?: Apollo.QueryHookOptions<FindAllTasksQuery, FindAllTasksQueryVariables>) {
        return Apollo.useQuery<FindAllTasksQuery, FindAllTasksQueryVariables>(FindAllTasksDocument, baseOptions);
      }
export function useFindAllTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllTasksQuery, FindAllTasksQueryVariables>) {
          return Apollo.useLazyQuery<FindAllTasksQuery, FindAllTasksQueryVariables>(FindAllTasksDocument, baseOptions);
        }
export type FindAllTasksQueryHookResult = ReturnType<typeof useFindAllTasksQuery>;
export type FindAllTasksLazyQueryHookResult = ReturnType<typeof useFindAllTasksLazyQuery>;
export type FindAllTasksQueryResult = Apollo.QueryResult<FindAllTasksQuery, FindAllTasksQueryVariables>;
export const FindAllUsersDocument = gql`
    query FindAllUsers {
  findAllUsers {
    payload {
      ...User
    }
  }
}
    ${UserFragmentDoc}`;

/**
 * __useFindAllUsersQuery__
 *
 * To run a query within a React component, call `useFindAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindAllUsersQuery(baseOptions?: Apollo.QueryHookOptions<FindAllUsersQuery, FindAllUsersQueryVariables>) {
        return Apollo.useQuery<FindAllUsersQuery, FindAllUsersQueryVariables>(FindAllUsersDocument, baseOptions);
      }
export function useFindAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllUsersQuery, FindAllUsersQueryVariables>) {
          return Apollo.useLazyQuery<FindAllUsersQuery, FindAllUsersQueryVariables>(FindAllUsersDocument, baseOptions);
        }
export type FindAllUsersQueryHookResult = ReturnType<typeof useFindAllUsersQuery>;
export type FindAllUsersLazyQueryHookResult = ReturnType<typeof useFindAllUsersLazyQuery>;
export type FindAllUsersQueryResult = Apollo.QueryResult<FindAllUsersQuery, FindAllUsersQueryVariables>;
export const GetQueueInformationDocument = gql`
    query GetQueueInformation {
  getQueueInformation {
    payload {
      queueManager {
        state
        currentQueueName
        uptime
      }
      matchmakerCreateQueue {
        ...MatchmakerCreateQueue
      }
      overtimeCalculatorQueue {
        ...OvertimeCalculatorQueue
      }
      matchmakerUpdateQueue {
        ...MatchmakerUpdateQueue
      }
    }
  }
}
    ${MatchmakerCreateQueueFragmentDoc}
${OvertimeCalculatorQueueFragmentDoc}
${MatchmakerUpdateQueueFragmentDoc}`;

/**
 * __useGetQueueInformationQuery__
 *
 * To run a query within a React component, call `useGetQueueInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQueueInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQueueInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetQueueInformationQuery(baseOptions?: Apollo.QueryHookOptions<GetQueueInformationQuery, GetQueueInformationQueryVariables>) {
        return Apollo.useQuery<GetQueueInformationQuery, GetQueueInformationQueryVariables>(GetQueueInformationDocument, baseOptions);
      }
export function useGetQueueInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQueueInformationQuery, GetQueueInformationQueryVariables>) {
          return Apollo.useLazyQuery<GetQueueInformationQuery, GetQueueInformationQueryVariables>(GetQueueInformationDocument, baseOptions);
        }
export type GetQueueInformationQueryHookResult = ReturnType<typeof useGetQueueInformationQuery>;
export type GetQueueInformationLazyQueryHookResult = ReturnType<typeof useGetQueueInformationLazyQuery>;
export type GetQueueInformationQueryResult = Apollo.QueryResult<GetQueueInformationQuery, GetQueueInformationQueryVariables>;