import styled from "styled-components";

type Props = {
  fontWeight?: "normal" | "bolder";
};

/**
 * Styled `p` tag for all generic text elements.
 */
export const Text = styled.p<Props>`
  font-weight: ${(props) => props.fontWeight || "normal"};
`;
