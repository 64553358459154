import * as React from "react";

import { Flexbox } from "../../../../components";
import { SupportAndOverrideFormCleanupButton } from "../SupportAndOverrideFormCleanupButton";

type Props = {
  apiResponseCard: React.ReactNode;
};

/**
 * View to display to the user upon successfully submitting a punch form.
 */
export const SupportAndOverrideFormSuccessScreen: React.FC<Props> = ({
  apiResponseCard,
}) => {
  return (
    <Flexbox direction="top-down">
      {apiResponseCard}

      <SupportAndOverrideFormCleanupButton />
    </Flexbox>
  );
};
