import styled from "styled-components";

import { spacing } from "../../../utils";

/**
 * Wraps the Numpad to provide vertical spacing.
 */
export const NumpadWrapper = styled.section`
  padding: ${spacing[4]} 0;
`;
