import * as React from "react";

import {
  CheckboxWrapper,
  HiddenCheckbox,
  Icon,
  Label,
  StyledCheckbox,
} from "./Checkbox.style";
import { FieldType } from "../../../store/types";

type Props = {
  checked: boolean;
  formTestId: string;
  onChange: () => void;
} & FieldType;

/**
 * Checkbox for users to indicate a boolean value within a form.
 */
export const Checkbox: React.FC<Props> = ({
  name,
  formTestId,
  required,
  displayName,
  checked,
  onChange,
  /* eslint-disable @typescript-eslint/no-unused-vars -- These properties are not required for Checkbox inputs */
  type: _type,
  autoComplete: _autoComplete,
  /* eslint-enable @typescript-eslint/no-unused-vars */
  ...rest
}) => {
  return (
    <CheckboxWrapper>
      {/* The hidden checkbox will maintain the state of the component */}
      <HiddenCheckbox
        data-testid={`${formTestId}__${name}-input`}
        id={`${formTestId}__${name}-input`}
        name={name}
        required={required}
        onChange={onChange}
        {...rest}
      />

      {/* The display checkbox will be interacted with by the user to modify the component state */}
      <StyledCheckbox checked={checked} onClick={onChange}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>

      <Label htmlFor={`${formTestId}__${name}-input`}>{displayName}</Label>
    </CheckboxWrapper>
  );
};
