import * as React from "react";

import { Container } from "../Container";
import { DefaultPageLayoutWrapper, Main } from "./DefaultPageLayout.style";
import { Footer, Props as FooterProps } from "../../organisms/Footer";
import { Header } from "../../organisms/Header";

type Props = {} & FooterProps;

/**
 * Template to create the default page layout for the App.
 */
export const DefaultPageLayout: React.FC<Props> = ({
  children,
  displayNavigation,
}) => {
  return (
    <DefaultPageLayoutWrapper>
      <Header />

      <Main>
        <Container>{children}</Container>
      </Main>

      <Footer displayNavigation={displayNavigation} />
    </DefaultPageLayoutWrapper>
  );
};
