import { SelectionOption } from "../../store/types";
import { useFindAllClientsQuery } from "../../generated/graphql";

/**
 * Fetches Client information needed to populate a dropdown menu containing all Clients.
 */
export function useClientsDropdownValues() {
  // Fetch data from API
  const { data } = useFindAllClientsQuery();

  // Create an array containing all Client IDs
  const clientIds: string[] = data?.findAllClients.payload
    ? data.findAllClients.payload?.map((client) => client.id)
    : [];

  // Map each raw Client to a SelectionOption to be used in a dropdown
  const clientsDropdownValues: SelectionOption[] = data?.findAllClients.payload
    ? data.findAllClients.payload.map((client) => {
        return {
          name: client.name,
          value: client.id,
        };
      })
    : [];

  return {
    clientIds,
    clientsDropdownValues,
  };
}
