import * as React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

import { Routes } from "../../constants/routes";
import { getAccessToken } from "../../store/accessToken";

type Props = {
  component: React.ElementType;
} & RouteProps;

/**
 * Wraps the default `Route` component from React Router DOM and checks if an Access Token is present,
 * which will indicate if an authenticated user is requesting the route. If no token is found the route
 * will render as requested, otherwise the user will be redirected to the Home screen.
 * This route should be used to ensure certain routes can not be accessed by users who are already authenticated,
 * for example the Login page.
 */
export const UnauthorizedRoute: React.FC<Props> = ({
  // eslint-disable-next-line @typescript-eslint/naming-convention -- React requires components to be PascalCased
  component: Component,
  ...routeProps
}) => {
  return (
    <Route
      {...routeProps}
      render={(props) => {
        // Get the Access Token stored in memory
        const accessToken = getAccessToken();

        // If a token is found
        // redirect the user to the Home screen
        if (accessToken) {
          return (
            <Redirect
              to={{
                pathname: Routes.Home,
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }

        // If no token is found render the route as normal
        return <Component {...props} />;
      }}
    />
  );
};
