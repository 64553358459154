import * as React from "react";

import { CardBody, CardTitle } from "./Card.style";
import { StatusType } from "../../../store/types";

type Props = {
  title?: string | React.ReactNode;
  titleAs?: "h2" | "h3";
  modifier?: StatusType;
  style?: React.CSSProperties;
};

/**
 * Common card element used to visually group information or elements together.
 * Can be passed a `modifier` prop to help indicate a semantic message contained within the card.
 */
export const Card: React.FC<Props> = ({
  children,
  modifier,
  title,
  titleAs = "h2",
  ...rest
}) => {
  return (
    <CardBody modifier={modifier} {...rest}>
      {title && <CardTitle as={titleAs}>{title}</CardTitle>}
      {children}
    </CardBody>
  );
};
