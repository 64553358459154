import * as React from "react";
import { Formik } from "formik";

import { PunchFormSuccessScreen } from "../common/PunchFormSuccessScreen";
import { formikConfig } from "../../../../lib/formik";
import {
  getCurrentRequiredFields,
  validateCurrentFields,
} from "../../../../utils";
import { useMultiStepMultiSubmitFormBuilder } from "../../../../hooks";
import { useNewTaskFormConfig } from "./useNewTaskFormConfig";

/**
 * Form that allows an authorized user to change their task.
 */
export const NewTaskForm: React.FC = () => {
  // Track the submission state of the form
  const [didSubmitSuccessfully, setDidSubmitSuccessfully] = React.useState(
    false,
  );

  // Get configuration to pass to form builder
  const newTaskFormConfig = useNewTaskFormConfig({ setDidSubmitSuccessfully });

  // Use a Form Builder hook to generate elements to display to the user
  const {
    ApiResponseCard,
    CurrentFormGroup,
    ControlButtons,
    Form,
    SubmitButtons,
    helpers,
  } = useMultiStepMultiSubmitFormBuilder(newTaskFormConfig);

  // Extract values needed to configure Formik
  const { initialValues, onSubmit, validationSchema } = newTaskFormConfig;

  return (
    <Formik {...formikConfig({ initialValues, onSubmit, validationSchema })}>
      {({ setFieldValue, isValid, isSubmitting, errors, touched }) => {
        const isCurrentFieldGroupValid = validateCurrentFields({
          errors,
          touched,
          initialValues,
          requiredFields: getCurrentRequiredFields(
            helpers.currentGroup,
            newTaskFormConfig,
          ),
        });

        return (
          <Form
            success={didSubmitSuccessfully}
            renderOnSuccess={
              <PunchFormSuccessScreen apiResponseCard={<ApiResponseCard />} />
            }
          >
            <ApiResponseCard />

            <CurrentFormGroup />

            {/* The Submit Buttons should only be rendered when the user has reached the last page of the form */}
            {helpers.isLastStepOfForm && (
              <SubmitButtons
                setFieldValue={setFieldValue}
                disabled={!isValid || isSubmitting}
              />
            )}

            <ControlButtons isValid={isCurrentFieldGroupValid} />
          </Form>
        );
      }}
    </Formik>
  );
};
