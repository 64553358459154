import styled from "styled-components";

import { Breakpoint, spacing } from "../../../utils";

type Props = {
  gapSize?: "small" | "medium";
};

/**
 * Spaces buttons evenly across a container.
 */
export const ButtonGroup = styled.section<Props>`
  --gapSize: ${(props) => {
    switch (props.gapSize) {
      case "small":
        return spacing[2];

      case "medium":
      default:
        return spacing[4];
    }
  }};

  --buttonMargin: var(--gapSize) 0;

  display: flex;
  flex-direction: column;

  > * {
    margin: var(--buttonMargin);
    flex: 1;
  }

  @media screen and (min-width: ${Breakpoint.Medium}) {
    --buttonMargin: 0 var(--gapSize);

    flex-direction: row;
    padding: var(--gapSize) 0;
  }
`;
