import { css } from "styled-components";

/**
 * Creates CSS needed to implement a transition and sets defaults for each property.
 */
export function transition({
  property = "all",
  speed = "300ms",
  ease = "ease",
  delay = "0ms",
}) {
  return css`
    transition: ${property} ${speed} ${ease};
    transition-delay: ${delay};
  `;
}
