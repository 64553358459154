import "react-datepicker/dist/react-datepicker.css";

import * as React from "react";
import ReactDatePicker, { CalendarContainer } from "react-datepicker";

import { FieldType } from "../../../store/types";
import { Flexbox } from "../../templates";
import { TextInput } from "../TextInput/TextInput.display";
import { buttonColors, rounded, spacing } from "../../../utils";

type Props = {
  formTestId: string;
  selected: Date;
  onChange: (
    date: Date | [Date, Date],
    event: React.SyntheticEvent<any, Event>,
  ) => void;
} & FieldType;

/**
 * Custom Date Picker component for filling out Date fields in forms. Features a popup calendar and time selector.
 */
export const DatePicker: React.FC<Props> = ({
  name,
  selected,
  onChange,
  formTestId,
}) => {
  /**
   * Custom calendar with styles applied to bring it in line with the rest of the app's theme.
   */
  const CustomCalendar: React.FC<any> = ({ className, children }) => {
    return (
      <div
        style={{
          padding: spacing[2],
          background: buttonColors.primary.background,
          color: buttonColors.primary.text,
          borderRadius: rounded,
        }}
      >
        {/* The CalendarContainer provided by react-datepicker does not pass TypeScript type checking */}
        {/* @ts-ignore */}
        <CalendarContainer className={className}>{children}</CalendarContainer>
      </div>
    );
  };

  /**
   * Custom input to make the component match the regular text inputs of the app.
   */
  /* eslint-disable @typescript-eslint/no-unused-vars -- The `_reference` variable is not needed, but React requires it to be declared. */
  // eslint-disable-next-line react/display-name -- Wrapping the `TextInputDisplay` component in `React.forwardRef` causes an issue with the component's displayName.
  const CustomInput = React.forwardRef((props, _reference) => (
    /* eslint-enable @typescript-eslint/no-unused-vars */
    <Flexbox direction="top-down">
      <TextInput formTestId={formTestId} name={name} {...props} />
    </Flexbox>
  ));

  return (
    <ReactDatePicker
      popperPlacement="top-start"
      showTimeInput
      selected={selected}
      onChange={onChange}
      calendarContainer={CustomCalendar}
      customInput={<CustomInput />}
    />
  );
};
