import * as React from "react";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";

import { Button } from "../../../../../components";
import { usePunchFormSubmissionCleanup } from "../../../../../hooks";

/**
 * A button that, when clicked, will return the user to the login screen or home screen
 * depending on their settings.
 */
export const PunchFormCleanupButton: React.FC = () => {
  const { cleanup } = usePunchFormSubmissionCleanup();

  return (
    <Button
      label="Return to Home Screen"
      icon={faArrowLeft}
      onClick={cleanup}
    />
  );
};
