/**
 * Configuration object containing all error messages.
 */
// eslint-disable-next-line no-shadow -- https://github.com/typescript-eslint/typescript-eslint/issues/325
export enum Errors {
  PinIsRequired = "Please enter your pin.",
  LocationIsRequired = "Please select a location.",
  ClientMustBeInDropdown = "You must select a client from the provided list.",
  TaskMustBeInDropdown = "You must select a task from the provided list.",
  UserMustBeInDropdown = "You must select a user from the provided list.",
}
