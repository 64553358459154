import { ApolloClient } from "@apollo/client";

import { GRAPHQL_API_URL } from "../../constants/apiUrl";
import { cache } from "./cache";
import { link } from "./link";

/**
 * Instantiate the Apollo Client to be passed to the Apollo Provider
 * and used throughout the app.
 */
export const apolloClient = new ApolloClient({
  uri: GRAPHQL_API_URL,
  cache,
  link,
});
