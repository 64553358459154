import { FormType, MultiSubmitFormType } from "../store/types";

/**
 * Returns a list of required fields in the current group in a multistep form.
 */
export function getCurrentRequiredFields<T>(
  currentGroup: number,
  formConfig: FormType<T> | MultiSubmitFormType<T>,
) {
  return (
    // Get the fields in the current group by its index
    formConfig.fieldGroups[currentGroup].fields
      // Exclude all fields that are not required
      .filter((field) => field.required)
      // Return the name of each field
      .map((field) => field.name)
  );
}
