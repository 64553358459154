import { keyframes } from "styled-components";

/**
 * Fades an element in from 0 (completely transparent)
 * to 1 (completely opaque).
 */
export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

/**
 * Fades an element out from 1 (completely opaque)
 * to 0 (completely transparent).
 */
export const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;
