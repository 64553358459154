import {
  faBuilding,
  faFarm,
  faLaptopHouse,
} from "@fortawesome/pro-solid-svg-icons";

import { LocationId } from "../generated/graphql";
import { MultiSubmitButtonType } from "../store/types";

/**
 * Configuration for Submit buttons used in New Task and Clock In forms.
 */
export const locationIdButtons: MultiSubmitButtonType[] = [
  {
    icon: faBuilding,
    label: "office/ warehouse",
    testId: "officeWarehouse",
    value: LocationId.OfficeWarehouse,
  },
  {
    icon: faFarm,
    label: "on site",
    testId: "onSite",
    value: LocationId.OnSite,
  },
  {
    icon: faLaptopHouse,
    label: "remote",
    testId: "remote",
    value: LocationId.Remote,
  },
];
