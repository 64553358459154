import { REST_API_URL } from "../constants/apiUrl";
import { apiConfig } from "./utils/apiConfig";

/**
 * Encapsulates logic for performing requests to the REST API endpoint.
 */
export const api = {
  /**
   * Sends a `POST` request to `/api/auth/refresh_tokens`
   * to attempt to use a Refresh token to get a new set of Refresh and Access tokens.
   * If there is no refresh token (the user is not logged in,
   * or has not opted in to persisting their login through the use of a cookie)
   * then the request will not return any tokens, and the user should be required to log in
   * to get an Access token to use the app.
   */
  async refreshTokens(): Promise<{
    message: string;
    payload: string;
  }> {
    // Send fetch request to refresh token endpoint
    const response = await fetch(`${REST_API_URL}/auth/refreshTokens`, {
      method: "POST",
      ...apiConfig(),
    });

    // Parse JSON and destructure payload and message
    const { payload, message } = await response.json();

    return {
      payload,
      message,
    };
  },
};
