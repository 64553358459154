import * as React from "react";
import {
  faArrowAltCircleLeft,
  faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";

import { Button } from "../../atoms";
import { NumpadSpecialValues } from "./Numpad.types";
import { NumpadWrapper } from "./Numpad.style";

type Props = {
  handleButtonClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
};

/**
 * Renders a ten-key keypad for users to input numeric values.
 */
export const Numpad: React.FC<Props> = ({ handleButtonClick }) => {
  // List of numbers to be rendered
  // Note: Numbers are declared in this order to create the expected ten-key shape
  const buttons = ["7", "8", "9", "4", "5", "6", "1", "2", "3", "0"];

  return (
    <NumpadWrapper>
      {buttons.map((number) => (
        <Button
          label={number}
          key={`${number}-button`}
          dataValue={number}
          onClick={handleButtonClick}
        />
      ))}

      <Button
        icon={faArrowAltCircleLeft}
        level="secondary"
        dataValue={NumpadSpecialValues.BACKSPACE}
        onClick={handleButtonClick}
      />

      <Button
        icon={faTimesCircle}
        level="secondary"
        dataValue={NumpadSpecialValues.CLEAR}
        onClick={handleButtonClick}
      />
    </NumpadWrapper>
  );
};
