import * as React from "react";

import { Router } from "../pages";

/**
 * The main entrypoint of the app, within all wrapping components.
 */
export const App: React.FC = () => {
  return <Router />;
};
