/**
 * Barn Pros Brand Color Pallette
 */
export const brandColors = {
  gray: {
    100: "#f8f8f8",
    300: "#c1c7d0",
    500: "#a5adbb",
    600: "#5b666c",
    700: "#565656",
  },
  teal: {
    100: "#508994",
    200: "#366f7a",
  },
  white: "#fff",
  black: "#1d1b17",
};
