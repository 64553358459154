import {
  FORTY_FIVE_SECONDS,
  THIRTY_SECONDS,
} from "../../../../constants/timeValues";
import { StatusType } from "../../../../store/types";

/**
 * Updates a status variable based on a time input to increase in severity
 * the longer the provided duration lasts.
 */
export function getStatusByJobDuration(time: number): StatusType {
  switch (true) {
    case time <= THIRTY_SECONDS:
      return "success";

    case time <= FORTY_FIVE_SECONDS:
      return "warning";

    default:
      return "error";
  }
}
