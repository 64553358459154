import * as React from "react";

import { FIVE_HUNDRED_MS } from "../constants/timeValues";

/**
 * Accepts a value and a time in milliseconds (defaults to half a second, five hundred milliseconds) after which any updates to the provided value will be reflected.
 * This delayed updating can provide smoother, less jarring transitions in UI elements that would otherwise update
 * so quickly it may cause a user to have a negative experience.
 */
export function useDebouncedValue<T>(
  value: T,
  delay: number = FIVE_HUNDRED_MS,
) {
  // Store the provided value in state
  const [debouncedValue, setDebouncedValue] = React.useState<T>(value);

  React.useEffect(() => {
    // Update the value after waiting for the desired amount of time
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Clean up any existing timeouts when this hook unmounts
    return () => {
      clearTimeout(handler);
    };
  }, [delay, value]);

  // Return the debounced value
  return debouncedValue;
}
