import * as React from "react";

import { useUserSettingsContext } from "../store/contexts/userSettings";

/**
 * Reads the current user's settings to determine if
 * the user is allowed to autofill a client or task field in a form,
 * and if they are it will use the corresponding value from their most recently
 * submitted `In` or `New Task` punch.
 */
export function useFormValueAutoFilling() {
  // The user's settings are accessed to determine if members of the user's department are
  // allowed to auto fill either value
  const { userSettings } = useUserSettingsContext();

  return React.useMemo(() => {
    let initialClientId = "";
    let initialTaskId = "";

    // If the user's settings allow for the clientId field to be autofilled
    // when creating punches, prepopulate that field with the value from the user's
    // previous `In` or `New Task` punch
    if (userSettings.departmentPermissions.canClientBeAutoFilled) {
      initialClientId = userSettings.recentPunchData.clientId;
    }

    // If the user's settings allow for the taskId field to be autofilled
    // when creating punches, prepopulate that field with the value from the user's
    // previous `In` or `New Task` punch
    if (userSettings.departmentPermissions.canTaskBeAutoFilled) {
      initialTaskId = userSettings.recentPunchData.taskId;
    }

    return {
      initialClientId,
      initialTaskId,
    };
  }, [userSettings.departmentPermissions, userSettings.recentPunchData]);
}
