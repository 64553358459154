import { FormikErrors, FormikTouched } from "formik";

/**
 * Validates a set of fields to ensure that no fields contain errors
 * and all required fields contain a value.
 */
export function validateCurrentFields({
  errors,
  touched,
  requiredFields,
  initialValues,
}: {
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
  requiredFields: string[];
  initialValues: any;
}) {
  // If any errors exist, reject validation
  const doFieldsContainErrors = Object.keys(errors).length !== 0;
  if (doFieldsContainErrors) {
    return false;
  }

  // If any touched fields contain empty values, reject validation
  const touchedFields = Object.keys(touched);
  for (const field of requiredFields) {
    if (!touchedFields.includes(field)) {
      if (initialValues[field] === "") {
        return false;
      }
    }
  }

  // Once all checks have passed, approve validation
  return true;
}
