import * as React from "react";

import {
  Columns,
  DataCacheCard,
  QueueCard,
  QueueManagerCard,
} from "../../components";
import { ONE_MINUTE, ONE_SECOND } from "../../constants/timeValues";
import {
  QueueManagerState,
  QueueName,
  QueueState,
  useDashboardQuery,
  useRefreshSmartsheetCachesMutation,
} from "../../generated/graphql";

/**
 * Displays information about the health and status of the back end systems this app relies on and
 * communicates with.
 */
export const SystemDashboard: React.FC = () => {
  // The frequency at which this component will poll the API for data
  const [pollInterval, setPollInterval] = React.useState(ONE_MINUTE);

  const refreshSmartsheetCachesMutation = useRefreshSmartsheetCachesMutation();

  // Get information about the status of the Queue system
  const { data, refetch, loading: isLoading } = useDashboardQuery({
    // Refetch data on the interval stored in state
    pollInterval,
    partialRefetch: true,
  });
  const queueManager = data?.getQueueInformation.payload.queueManager;
  const matchmakerCreateQueue =
    data?.getQueueInformation.payload.matchmakerCreateQueue;
  const overtimeCalculatorQueue =
    data?.getQueueInformation.payload.overtimeCalculatorQueue;
  const matchmakerUpdateQueue =
    data?.getQueueInformation.payload.matchmakerUpdateQueue;

  // Get all entities to determine how many of each are stored in the cache
  const users = data?.findAllUsers.payload;
  const clients = data?.findAllClients.payload;
  const tasks = data?.findAllTasks.payload;

  // Every time data is fetched check the state of the Queue Manager
  // if it is in an idle state data should be fetched every minute,
  // if it is an state other than idle, data should be fetched every second
  React.useEffect(() => {
    if (
      data?.getQueueInformation.payload.queueManager.state ===
      QueueManagerState.Idle
    ) {
      setPollInterval(ONE_MINUTE);
    } else {
      setPollInterval(ONE_SECOND);
    }
  }, [data?.getQueueInformation.payload.queueManager.state]);

  return (
    <>
      <QueueManagerCard
        isLoading={isLoading}
        refetch={refetch}
        currentQueueName={
          queueManager?.currentQueueName || QueueName.MatchmakerCreate
        }
        state={queueManager?.state || QueueManagerState.Idle}
        uptime={queueManager?.uptime || 0}
      />

      <Columns number={3} flexible>
        <QueueCard
          currentQueue={queueManager?.currentQueueName}
          name={QueueName.MatchmakerCreate}
          retryCounter={matchmakerCreateQueue?.retryCounter || 0}
          state={matchmakerCreateQueue?.state || QueueState.Idle}
          numberOfJobsInQueue={matchmakerCreateQueue?.numberOfJobsInQueue || 0}
          jobsCompletedToday={matchmakerCreateQueue?.jobsCompletedToday || []}
          jobsCompletedThisWeek={
            matchmakerCreateQueue?.jobsCompletedThisWeek || []
          }
          previousJobDuration={matchmakerCreateQueue?.previousJobDuration || 0}
          averageDayJobDuration={
            matchmakerCreateQueue?.averageDayJobDuration || 0
          }
          averageWeekJobDuration={
            matchmakerCreateQueue?.averageWeekJobDuration || 0
          }
          rowsInSmartsheetTable={
            matchmakerCreateQueue?.rowsInSmartsheetTable || 0
          }
        />

        <QueueCard
          currentQueue={queueManager?.currentQueueName}
          name={QueueName.OvertimeCalculator}
          retryCounter={overtimeCalculatorQueue?.retryCounter || 0}
          state={overtimeCalculatorQueue?.state || QueueState.Idle}
          numberOfJobsInQueue={
            overtimeCalculatorQueue?.numberOfJobsInQueue || 0
          }
          jobsCompletedToday={overtimeCalculatorQueue?.jobsCompletedToday || []}
          jobsCompletedThisWeek={
            overtimeCalculatorQueue?.jobsCompletedThisWeek || []
          }
          previousJobDuration={
            overtimeCalculatorQueue?.previousJobDuration || 0
          }
          averageDayJobDuration={
            overtimeCalculatorQueue?.averageDayJobDuration || 0
          }
          averageWeekJobDuration={
            overtimeCalculatorQueue?.averageWeekJobDuration || 0
          }
          rowsInSmartsheetTable={
            overtimeCalculatorQueue?.rowsInSmartsheetTable || 0
          }
        />

        <QueueCard
          currentQueue={queueManager?.currentQueueName}
          name={QueueName.MatchmakerUpdate}
          retryCounter={matchmakerUpdateQueue?.retryCounter || 0}
          state={matchmakerUpdateQueue?.state || QueueState.Idle}
          numberOfJobsInQueue={matchmakerUpdateQueue?.numberOfJobsInQueue || 0}
          jobsCompletedToday={matchmakerUpdateQueue?.jobsCompletedToday || []}
          jobsCompletedThisWeek={
            matchmakerUpdateQueue?.jobsCompletedThisWeek || []
          }
          previousJobDuration={matchmakerUpdateQueue?.previousJobDuration || 0}
          averageDayJobDuration={
            matchmakerUpdateQueue?.averageDayJobDuration || 0
          }
          averageWeekJobDuration={
            matchmakerUpdateQueue?.averageWeekJobDuration || 0
          }
          rowsInSmartsheetTable={
            matchmakerUpdateQueue?.rowsInSmartsheetTable || 0
          }
        />
      </Columns>

      <DataCacheCard
        refreshSmartsheetCachesMutation={refreshSmartsheetCachesMutation}
        numberOfUsers={users?.length || 0}
        numberOfClients={clients?.length || 0}
        numberOfTasks={tasks?.length || 0}
      />
    </>
  );
};
