/**
 * Assign all Environment Variables to a constant with the same name
 * for ease of use throughout the app.
 *
 * (Note: The default Create React App starter which this project uses
 * requires environment variables to be prefixed with `REACT_APP_`.)
 */
export const {
  // The base url for the API
  REACT_APP_API_URL,
  // The endpoint for all REST API communication
  REACT_APP_API_ENDPOINT = "/api",
  // The endpoint for all GraphQL API communication
  REACT_APP_GRAPHQL_ENDPOINT = "/graphql",
} = process.env;
