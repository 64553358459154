import * as React from "react";
import { Formik } from "formik";

import { Checkbox, Form, FormAutoSubmit, PinInput } from "../../../components";
import { FirstTimeUserHelpCard } from "./FirstTimeUserHelpCard";
import { InputWrapper } from "./LoginForm.style";
import { formikConfig } from "../../../lib/formik";
import { useFormBuilder } from "../../../hooks";
import { useLoginFormConfig } from "./useLoginFormConfig";
import { useUserSettingsContext } from "../../../store/contexts/userSettings";

/**
 * Form for logging users in using their four digit pin.
 */
export const LoginForm: React.FC = () => {
  // Access User Settings to determine how the user wants to log in
  // and if they want to see the checkbox to keep them logged in
  const { userSettings } = useUserSettingsContext();

  // Get form configuration
  const loginFormConfig = useLoginFormConfig();

  // Use configuration to create form elements
  const { ApiResponseCard, SubmitButton } = useFormBuilder(loginFormConfig);

  // Extract values needed to configure Formik
  const { initialValues, onSubmit, validationSchema } = loginFormConfig;

  return (
    <>
      {userSettings.shouldFirstTimeLoginToastBeDisplayed && (
        <FirstTimeUserHelpCard />
      )}

      <Formik {...formikConfig({ initialValues, onSubmit, validationSchema })}>
        {({ isSubmitting, values, submitForm }) => (
          <>
            <ApiResponseCard />

            <Form>
              <InputWrapper>
                <PinInput formTestId={loginFormConfig.testId} focusOnMount />
              </InputWrapper>

              {/* Certain user will want to hide this checkbox, for example the common login point in the Warehouse or on a job site */}
              {userSettings.shouldPersistLoginCheckboxBeVisable && (
                <InputWrapper>
                  <Checkbox
                    formTestId={loginFormConfig.testId}
                    name="doesUserAcceptCookies"
                    displayName="Keep Me Logged In"
                  />
                </InputWrapper>
              )}

              {/* The user may want their PIN to submit automatically, mirroring the behavior they are familiar with when unlocking their phone */}
              {userSettings.shouldPinInputAutoSubmit ? (
                <FormAutoSubmit
                  condition={values.pin.length === 4}
                  submitForm={submitForm}
                />
              ) : (
                <SubmitButton disabled={isSubmitting} />
              )}
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
