import { UserSettingsStateType } from "./types";

/**
 * Default initial state to be used if no user settings
 * were loaded from Local Storage.
 */
export const initialState: UserSettingsStateType = {
  shouldPersistLoginCheckboxBeVisable: true,
  shouldPinInputAutoSubmit: true,
  shouldFirstTimeLoginToastBeDisplayed: true,
  shouldAllTasksBeDisplayed: false,
  shouldLoginPersist: false,
  departmentPermissions: {
    canClientBeAutoFilled: false,
    canTaskBeAutoFilled: false,
  },
  recentPunchData: {
    clientId: "",
    taskId: "",
  },
};
