import { StatusType } from "../../store/types";
import { roleColors } from "./colors/roleColors";

/**
 * Converts a status variable to its corresponding color in the color palette.
 */
export function convertStatusToColor(status: StatusType) {
  switch (true) {
    case status === "success":
      return roleColors.success;

    case status === "warning":
      return roleColors.warning;

    case status === "info":
      return roleColors.info;

    default:
      return roleColors.error;
  }
}
