import { SelectionOption } from "../../store/types";
import { useFindAllUsersQuery } from "../../generated/graphql";

/**
 * Fetches User information needed to populate a dropdown menu containing all Users.
 */
export function useUsersDropdownValues() {
  // Fetch data from API
  const { data } = useFindAllUsersQuery();

  // Create an array containing all User IDs
  const userIds: string[] = data?.findAllUsers.payload
    ? data.findAllUsers.payload.map((user) => user.id)
    : [];

  // Map each raw User to a SelectionOption to be used in a dropdown
  const usersDropdownValues: SelectionOption[] = data?.findAllUsers.payload
    ? data.findAllUsers.payload.map((user) => {
        return {
          name: user.name,
          value: user.id,
        };
      })
    : [];

  return {
    userIds,
    usersDropdownValues,
  };
}
