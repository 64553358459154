import * as React from "react";

import { Card, Flexbox, Text } from "../../components";
import { Checkbox } from "../../components/atoms/Checkbox/Checkbox.display";
import { ItemWrapper } from "./UserSettingsConfiguration.style";
import {
  UserSettingsActionTypes,
  useUserSettingsContext,
} from "../../store/contexts/userSettings";

/**
 * Displays checkboxes to allow users to customize certain behaviors
 * or aspects of the app.
 */
export const UserSettingsConfiguration: React.FC = () => {
  // Access the User Settings context to read its current values
  // and dispatch actions to modify those values
  const { userSettings, dispatch } = useUserSettingsContext();

  return (
    <Flexbox direction="top-down">
      <Text>Change your settings here.</Text>

      <ItemWrapper>
        <Checkbox
          formTestId="userSettings"
          displayName='Display "Keep me Logged In" Checkbox'
          name="shouldPersistLoginCheckboxBeVisable"
          checked={userSettings.shouldPersistLoginCheckboxBeVisable}
          onChange={() => {
            dispatch({
              type: UserSettingsActionTypes.ToggleDisplayPersistLoginCheckbox,
            });
          }}
        />
      </ItemWrapper>

      <ItemWrapper>
        <Checkbox
          formTestId="userSettings"
          displayName="Automatically submit PIN"
          name="shouldPinInputAutoSubmit"
          checked={userSettings.shouldPinInputAutoSubmit}
          onChange={() => {
            dispatch({
              type: UserSettingsActionTypes.ToggleAutoSubmitPinInput,
            });
          }}
        />
      </ItemWrapper>

      <ItemWrapper>
        <Checkbox
          formTestId="userSettings"
          displayName="Display all task options in forms"
          name="shouldAllTasksBeDisplayed"
          checked={userSettings.shouldAllTasksBeDisplayed}
          onChange={() => {
            dispatch({
              type: UserSettingsActionTypes.ToggleDisplayAllTasksInDropdown,
            });
          }}
        />
      </ItemWrapper>
    </Flexbox>
  );
};
