import { SelectionOption } from "../../store/types";
import {
  UserDepartment,
  useCurrentUserQuery,
  useFindAllTasksQuery,
} from "../../generated/graphql";
import { useUserSettingsContext } from "../../store/contexts/userSettings";

/**
 * Fetches Task information needed to populate a dropdown menu containing all Tasks.
 */
export function useTasksDropdownValues() {
  // User Information query is called to get the user's department
  const { data: userInformationData } = useCurrentUserQuery();
  const user = userInformationData?.currentUser.payload.user;

  // Fetch Task data from API
  const { data } = useFindAllTasksQuery();

  // Access user settings to determine how the user wants to view the Tasks
  const { userSettings } = useUserSettingsContext();

  // Create an array containing all Task IDs
  const taskIds: string[] = data?.findAllTasks.payload
    ? data.findAllTasks.payload.map((task) => task.id)
    : [];

  // Map each raw Task to a SelectionOption to be used in a dropdown
  // Tasks are filtered based on the setting accessed from the User Settings context
  const tasksDropdownValues: SelectionOption[] = data?.findAllTasks.payload
    ? data.findAllTasks.payload
        .filter((task) => {
          // If the user wants to view all tasks return each task
          if (userSettings.shouldAllTasksBeDisplayed) {
            return task;
          }

          // Otherwise only return tasks which match the user's department
          return (
            ((task.department as unknown) as UserDepartment) ===
            user?.department
          );
        })
        .map((task) => {
          return {
            name: task.name,
            value: task.id,
            // Certain tasks are not intended to be selectable
            // and instead are used to deliniate between categories
            // this behavior is indicated by the `isHeader` value
            disabled: task.isHeader,
          };
        })
    : [];

  return {
    taskIds,
    tasksDropdownValues,
  };
}
