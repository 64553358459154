import { LocationId } from "../generated/graphql";
import { SelectionOption } from "../store/types";

/**
 * Configuration for Radio buttons used in Override Forms.
 */
export const locationIdSelectionOptions: SelectionOption[] = [
  {
    name: "office/ warehouse",
    value: LocationId.OfficeWarehouse,
  },
  {
    name: "on site",
    value: LocationId.OnSite,
  },
  {
    name: "remote",
    value: LocationId.Remote,
  },
];
