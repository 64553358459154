/**
 * Common prefix to scope Local Storage values.
 */
const PREFIX = "time_";

/**
 * Storage location for user setting configuration.
 */
export const USER_SETTINGS = `${PREFIX}userSettings`;

/**
 * Storage location for user's most recent punch.
 */
export const RECENT_PUNCH_CACHE = `${PREFIX}recentPunchCache`;
