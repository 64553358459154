import styled from "styled-components";

import { Flexbox, Text } from "../../components";
import { spacing } from "../../utils";

/**
 * Provides substantial vertical padding around the welcome message.
 */
export const WelcomeMessage = styled(Text)`
  padding: ${spacing[8]} 0;
  text-align: center;
`;

/**
 * Wraps the Logout Button to provide consistant whitespace.
 */
export const LogoutButtonWrapper = styled(Flexbox).attrs({
  direction: "right-left",
})`
  margin-top: ${spacing[8]};
  padding: 0 ${spacing[4]};
`;
