import * as React from "react";
import { useHistory } from "react-router-dom";

import { FIVE_SECONDS } from "../constants/timeValues";
import { Routes } from "../constants/routes";

/**
 * Returns a function that performs all tasks that need to be ran after a Support form has been submitted
 * and a second function that will call the first after a five (5) second delay.
 */
export function useSupportAndOverrideFormSubmissionCleanup() {
  // History must be accessed to redirect the user to the home screen if they
  // have selected to remain logged in
  const history = useHistory();

  // Store a reference to whether the hook is mounted or unmounted
  const didUnmountReference = React.useRef(false);

  // When this hook unmounts, flip the reference to true
  React.useEffect(() => {
    return () => {
      didUnmountReference.current = true;
    };
  }, []);

  // Store a reference to the timeout that will call the cleanup functions after a delay
  const timeoutReference: React.MutableRefObject<NodeJS.Timeout | null> = React.useRef(
    null,
  );

  const cleanup = () => {
    history.push(Routes.Home);
  };

  const delayedCleanup = () => {
    // After a five second delay return the user to the home screen
    timeoutReference.current = setTimeout(() => {
      // If the hook has unmounted do not perform cleanup actions
      if (didUnmountReference.current) {
        return;
      }

      cleanup();
    }, FIVE_SECONDS);
  };

  // If the hook has unmounted clear the timeout to clean up potential memory leaks
  React.useEffect(() => {
    return () => {
      if (timeoutReference.current) {
        clearTimeout(timeoutReference.current);
      }
    };
  }, []);

  return {
    cleanup,
    delayedCleanup,
  };
}
