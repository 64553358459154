import * as React from "react";
import { Field } from "formik";

import { DatePicker as DatePickerDisplay } from "./DatePicker.display";
import { FieldType } from "../../../store/types";

type Props = {
  formTestId: string;
} & FieldType;

/**
 * Wraps a DatePicker component in a Formik Field.
 */
export const DatePicker: React.FC<Props> = ({ ...props }) => {
  return (
    <Field>
      {({ form, field }: any) => {
        return (
          <DatePickerDisplay
            selected={field.value[props.name]}
            onChange={(date: Date) => {
              form.setFieldValue(props.name, date);
            }}
            {...props}
          />
        );
      }}
    </Field>
  );
};
