import * as React from "react";

import { Container } from "../../templates/Container";
import {
  FooterContentWrapper,
  FooterWrapperInner,
  FooterWrapperOuter,
} from "./Footer.style";
import { NavMenu } from "../NavMenu";
import { Text } from "../../atoms/Text";

export type Props = {
  displayNavigation?: boolean;
};

export const Footer: React.FC<Props> = ({ displayNavigation = true }) => {
  return (
    <FooterWrapperOuter>
      <FooterWrapperInner>
        <Container style={{ width: "100%" }}>
          <FooterContentWrapper>
            <Text>Barn Pros &copy; {new Date().getFullYear()}</Text>

            {displayNavigation && <NavMenu />}
          </FooterContentWrapper>
        </Container>
      </FooterWrapperInner>
    </FooterWrapperOuter>
  );
};
