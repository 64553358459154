import * as React from "react";
import { useField } from "formik";

import { Numpad, NumpadSpecialValues } from "../../molecules";
import { NumpadWrapper } from "./PinInput.style";
import { TextInput } from "../../atoms";

type Props = {
  inputName?: string;
  focusOnMount?: boolean;
  formTestId: string;
};

/**
 * Combines a Text Input and a Numpad to create an input
 * for users to enter a PIN value.
 */
export const PinInput: React.FC<Props> = ({
  focusOnMount,
  formTestId,
  inputName = "pin",
}) => {
  // Access the Formik field properties and helper functions
  const [field, _meta, helpers] = useField(inputName);

  return (
    <>
      <TextInput
        name={inputName}
        type="text"
        autoComplete={false}
        formTestId={formTestId}
        focusOnMount={focusOnMount}
      />

      <NumpadWrapper>
        <Numpad
          handleButtonClick={(event) => {
            // Get the value stored in the clicked button's dataset
            const { value } = event.currentTarget.dataset;

            // If the backspace button was clicked remove the last character
            // from the input value
            if (value === NumpadSpecialValues.BACKSPACE) {
              helpers.setValue(field.value.slice(0, -1));
              return;
            }

            // If the clear button was clicked reset the input to an empty string
            if (value === NumpadSpecialValues.CLEAR) {
              helpers.setValue("");
              return;
            }

            // For all other values concatenate the value to the end
            // of the current value
            helpers.setValue(`${field.value}${value}`);
          }}
        />
      </NumpadWrapper>
    </>
  );
};
