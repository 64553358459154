import * as React from "react";
import { faSignOut } from "@fortawesome/pro-solid-svg-icons";

import { Button } from "../../components";
import { useLogoutCleanup } from "../../hooks";

/**
 * A button to call the `logout` mutation and safely log users out.
 */
export const LogoutButton: React.FC = () => {
  // `handleLogoutCleanup` will perform all tasks needed to log out a user
  const handleLogoutCleanup = useLogoutCleanup();

  return (
    <Button
      onClick={handleLogoutCleanup}
      icon={faSignOut}
      label="Logout"
      level="secondary"
    />
  );
};
