import {
  faCheck,
  faClock,
  faEllipsisH,
  faExclamation,
  faSpinner,
} from "@fortawesome/pro-solid-svg-icons";

import { QueueState } from "../../../../generated/graphql";

/**
 * Returns a specific icon based on the state of a queue.
 */
export function getHeaderIconByState(state: QueueState) {
  switch (true) {
    case state === QueueState.Waiting:
      return faClock;

    case state === QueueState.Queued:
      return faEllipsisH;

    case state === QueueState.ProcessingJob:
      return faSpinner;

    case state === QueueState.Failing:
      return faExclamation;

    case state === QueueState.Finishing:
    case state === QueueState.Idle:
    default:
      return faCheck;
  }
}
