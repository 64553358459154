import * as React from "react";

import { ClockFace } from "./Time.style";
import { Flexbox } from "../../templates/Flexbox";
import { ONE_SECOND } from "../../../constants/timeValues";

/**
 * Displays the current time, updating every second.
 */
export const Time: React.FC = () => {
  // Store the current time
  const [time, setTime] = React.useState(() => {
    // Get the initial time
    const newTime = new Date();

    // Convert to locale time string and store in state
    return newTime.toLocaleTimeString();
  });

  // Every second update the value of the current time
  React.useEffect(() => {
    // Set an interval to run every second
    const updateTimeEverySecondInterval = setInterval(() => {
      // Get the current time
      const newTime = new Date();

      // Convert to locale time string and store in state
      setTime(newTime.toLocaleTimeString());
    }, ONE_SECOND);

    // When the component unmounts clean up by clearing the interval
    return () => {
      clearInterval(updateTimeEverySecondInterval);
    };
  }, []);

  return (
    <Flexbox alignContent="center">
      <ClockFace>{time}</ClockFace>
    </Flexbox>
  );
};
