import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";

import { IconStyleProps, IconWrapper } from "./Icon.style";
import { buttonColors } from "../../../utils";

type Props = {
  icon: IconDefinition;
  spin?: boolean;
  iconColor?: string;
} & IconStyleProps;

export const Icon: React.FC<Props> = ({
  icon,
  backgroundColor,
  inline,
  size,
  spin,
  iconColor = buttonColors.primary.text,
}) => (
  <IconWrapper
    size={size || "medium"}
    inline={inline || false}
    backgroundColor={backgroundColor}
  >
    <FontAwesomeIcon color={iconColor} icon={icon} spin={spin} />
  </IconWrapper>
);
