import * as React from "react";

import { Card, Text } from "../../../../components";
import { spacing } from "../../../../utils";

/**
 * Displays a tooltip to help first time users Log in to time.
 */
export const FirstTimeUserHelpCard: React.FC = () => {
  return (
    <Card modifier="info" style={{ marginBottom: spacing[8] }}>
      <Text style={{ paddingBottom: spacing[2] }}>
        If this is your first time using Time, your default PIN is the last four
        digits of your phone number.
      </Text>
      <Text>You will be able to change your PIN to a custom number soon.</Text>
    </Card>
  );
};
