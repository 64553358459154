import styled, { css } from "styled-components";

import { StatusType } from "../../../store/types";
import { Title } from "../Title";
import { cardColors, elevation, rounded, spacing } from "../../../utils";

type Props = {
  modifier?: StatusType;
};

/**
 * The main body area of the card.
 * If a modifier is provided it will change the color of the card to help distinguish information within the UI.
 */
export const CardBody = styled.section<Props>`
  /* Adjust the color of the card based on which modifier (if any) is provided */
  ${(props) => {
    switch (props.modifier) {
      case "success":
        return css`
          --backgroundColor: ${cardColors.background.success};
          --textColor: ${cardColors.text.modifier};
        `;
      case "info":
        return css`
          --backgroundColor: ${cardColors.background.info};
          --textColor: ${cardColors.text.modifier};
        `;

      case "error":
        return css`
          --backgroundColor: ${cardColors.background.error};
          --textColor: ${cardColors.text.modifier};
        `;

      default:
        return css`
          --backgroundColor: ${cardColors.background.default};
          --textColor: ${cardColors.text.default};
        `;
    }
  }}

  ${elevation[4]};
  border-radius: ${rounded};
  padding: ${spacing[4]};
  background-color: var(--backgroundColor);
  color: var(--textColor);
`;

/**
 * The title of content contained within the card.
 */
export const CardTitle = styled(Title.H2)`
  margin-bottom: ${spacing[4]};
`;
