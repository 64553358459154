import styled, { css } from "styled-components";

type FlexboxProps = {
  direction?: "left-right" | "right-left" | "top-down" | "bottom-up";
  alignContent?: "between" | "around" | "evenly" | "center";
  fullHeight?: boolean;
};

/**
 * Wrapping component for the CSS Flexbox API to make styling easier and more consistent.
 */
export const Flexbox = styled.div<FlexboxProps>`
  /* Set the display to flex to enable Flexbox */
  display: flex;

  /* Adjust the direction of the content */
  ${(props) => {
    switch (props.direction) {
      case "bottom-up":
        return css`
          flex-direction: column-reverse;
        `;

      case "top-down":
        return css`
          flex-direction: column;
        `;

      case "right-left":
        return css`
          flex-direction: row-reverse;
        `;

      case "left-right":
      default:
        return css`
          flex-direction: row;
        `;
    }
  }}

  /* Adjust the spacing and alignment of the content */
  ${(props) => {
    switch (props.alignContent) {
      case "around":
        return css`
          justify-content: space-around;
          align-items: baseline;
        `;

      case "between":
        return css`
          justify-content: space-between;
          align-items: baseline;
        `;

      case "evenly":
        return css`
          justify-content: space-evenly;
          align-items: baseline;
        `;

      case "center":
        return css`
          justify-content: center;
          align-items: center;
        `;

      default:
        return css``;
    }
  }}

  /* Force the element to take up all available vertical space */
  ${(props) =>
    props.fullHeight &&
    css`
      height: 100%;
    `}
`;
