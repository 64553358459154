import * as React from "react";

import { LoadingScreen } from "../../../pages/LoadingScreen";
import {
  useFindAllClientsQuery,
  useFindAllTasksQuery,
  useFindAllUsersQuery,
} from "../../../generated/graphql";

/**
 * Calls the FindAllClients, FindAllTasks, and FindAllUsers queries to cache their results
 * which will allow Punch forms that auto fill values to validate on mount.
 */
export const DropdownMenuPrepopulationWrapper: React.FC = ({ children }) => {
  const clients = useFindAllClientsQuery();
  const tasks = useFindAllTasksQuery();
  const users = useFindAllUsersQuery();

  // If any query is still in a loading state display the loading screen
  if (clients.loading || tasks.loading || users.loading) {
    return <LoadingScreen />;
  }

  // Once all queries have been completed the rest of the app can be displayed
  return <>{children}</>;
};
