import * as React from "react";
import * as Yup from "yup";
import { faCalendarEdit } from "@fortawesome/pro-solid-svg-icons";

import {
  CreateInPunchOverrideInput,
  LocationId,
  useCreateInPunchOverrideMutation,
  useCurrentUserQuery,
} from "../../../../generated/graphql";
import { Errors } from "../../../../constants/errors";
import {
  FieldType,
  FormConfigProps,
  FormType,
  SelectionFieldType,
  StatusType,
} from "../../../../store/types";
import { TaskFilteringToggleCheckbox } from "../../common/TaskFilteringToggleCheckbox";
import { locationIdSelectionOptions } from "../../../../utils";
import {
  useClientsDropdownValues,
  useSupportAndOverrideFormSubmissionCleanup,
  useTasksDropdownValues,
  useUsersDropdownValues,
} from "../../../../hooks";

/**
 * Memoized configuration object for the Override In Punch Form to reduce
 * unneccessary rerenders.
 */
export function useOverrideInPunchFormConfig({
  setDidSubmitSuccessfully,
}: FormConfigProps): FormType<CreateInPunchOverrideInput> {
  // User Information query is called to get the user's id and department
  const { data: userInformationData } = useCurrentUserQuery();
  const user = userInformationData?.currentUser.payload.user;

  // The createInPunchOverride function will call the mutation to create the punch using the data entered into the form
  const [createInPunchOverride] = useCreateInPunchOverrideMutation();

  // Clients, Tasks, and Users are requested to populate dropdown menus
  const { clientIds, clientsDropdownValues } = useClientsDropdownValues();
  const { taskIds, tasksDropdownValues } = useTasksDropdownValues();
  const { userIds, usersDropdownValues } = useUsersDropdownValues();

  const { delayedCleanup } = useSupportAndOverrideFormSubmissionCleanup();

  // State value to store error and success response messages
  const [apiResponseMessage, setApiResponseMessage] = React.useState<
    [StatusType, string]
  >(["idle", ""]);

  return React.useMemo(() => {
    return {
      apiResponseMessage,
      fieldGroups: [
        {
          testId: "punchInformation",
          title: "Punch Information",
          description: "Please fill out these fields to complete the override.",
          fields: [
            {
              name: "userId",
              displayName: "User",
              type: "combobox",
              required: true,
              selectionOptions: usersDropdownValues,
            } as SelectionFieldType,
            {
              name: "clientId",
              displayName: "Client",
              type: "combobox",
              required: true,
              selectionOptions: clientsDropdownValues,
            } as SelectionFieldType,
            {
              name: "taskId",
              displayName: "Task",
              type: "combobox",
              required: true,
              selectionOptions: tasksDropdownValues,
              modifiers: <TaskFilteringToggleCheckbox />,
            } as SelectionFieldType,
            {
              name: "time",
              type: "date",
              required: true,
            } as FieldType,
            {
              name: "locationId",
              displayName: "Location",
              type: "radio",
              required: true,
              selectionOptions: locationIdSelectionOptions,
            } as SelectionFieldType,
          ],
        },
      ],
      initialValues: {
        clientId: "",
        locationId: LocationId.Unknown,
        supervisorId: user?.id || "",
        taskId: "",
        time: new Date(),
        userId: "",
      },
      onSubmit: async (values) => {
        try {
          // Attempt to create an In Punch Override with the provided user inputs
          const { data } = await createInPunchOverride({
            variables: {
              data: values,
            },
          });

          // Ensure data was returned from the API
          if (data) {
            // Display the resulting message to the user
            setApiResponseMessage([
              "success",
              data.createInPunchOverride.message,
            ]);

            // Set successful submit state to true to update UI
            setDidSubmitSuccessfully(true);

            // Perform all cleanup duties based on the user's settings
            delayedCleanup();
          }
        } catch (error) {
          setApiResponseMessage(["error", error.message]);
        }
      },
      submitButton: {
        icon: faCalendarEdit,
        label: "Submit Override",
      },
      testId: "overrideInPunchForm",
      title: "Override In Punch",
      validationSchema: Yup.object().shape<CreateInPunchOverrideInput>({
        clientId: Yup.string()
          .oneOf(clientIds, Errors.ClientMustBeInDropdown)
          .required(Errors.ClientMustBeInDropdown),
        locationId: Yup.mixed<LocationId>().required(Errors.LocationIsRequired),
        supervisorId: Yup.string().required(),
        taskId: Yup.string()
          .oneOf(taskIds, Errors.TaskMustBeInDropdown)
          .required(Errors.TaskMustBeInDropdown),
        time: Yup.date().required(),
        userId: Yup.string()
          .oneOf(userIds, Errors.UserMustBeInDropdown)
          .required(Errors.UserMustBeInDropdown),
      }),
    };
  }, [
    apiResponseMessage,
    clientIds,
    createInPunchOverride,
    delayedCleanup,
    setDidSubmitSuccessfully,
    taskIds,
    user?.id,
    userIds,
    clientsDropdownValues,
    tasksDropdownValues,
    usersDropdownValues,
  ]);
}
