import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/pro-solid-svg-icons";
import { useCombobox } from "downshift";
import { useField } from "formik";

import {
  ComboboxWrapper,
  Dropdown,
  DropdownWrapper,
  Field,
  Option,
  ToggleMenuButton,
  ToggleMenuButtonLabel,
} from "./Combobox.style";
import { SelectionFieldType, SelectionOption } from "../../../store/types";

type Props = {
  formTestId: string;
} & SelectionFieldType;

/**
 * A complex input that allows for users to select from a dropdown and/ or type into
 * an input to narrow the list of items displayed within that dropdown.
 *
 * Powered by Downshift and Formik.
 */
export const Combobox: React.FC<Props> = ({
  formTestId,
  name,
  required,
  displayName = name,
  placeholder = `Select a ${displayName}`,
  selectionOptions = [],
}) => {
  // State tracking the currently displayed items based on the user's input into the combobox
  const [items, setItems] = React.useState<SelectionOption[]>(selectionOptions);

  // Access Formik form in which this combobox is being used
  const [field, meta, helpers] = useField(name);

  // Downshift creates complex components by providing a configuration to a hook which returns
  // a set of utils and functions. These functions are then used to spread props onto unopinionated
  // components to provide functionality and accessibility controls.
  const {
    //* Prop Getters
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    //* Utils
    // Indicates if the dropdown menu is open or closed
    isOpen,
    // The index of the highlighted item within the list of available options
    // in the dropdown menu
    highlightedIndex,
    // Toggles the `open` state of the menu
    toggleMenu,
  } = useCombobox({
    // A default is provided to enable autofilling based on the form's initial values
    defaultSelectedItem: items.find((item) => item.value === field.value),
    // A unique identifier to ensure a combobox behaves as expected on a page with multiple combobox inputs
    id: `${formTestId}${name}`,
    // The item's `name` prop should be used for display purposes
    itemToString: (item) => item?.name || "",
    // All items stored in state should be used to populate the dropdown
    items,
    // Custom handler for filtering items based on user input
    onInputValueChange: ({ inputValue }) => {
      // If there is no value in the input (ie: the user has cleared all text from the input)
      // then the initial list provided from props should be used
      if (!inputValue) {
        setItems(selectionOptions);
        return;
      }

      // Items should be filtered based on text entered by the user to only include
      // items which partially match the user's input
      // Note: The item.name and the input value are converted to lower case to ensure text always matches
      setItems(
        selectionOptions.filter((item) =>
          item.name.toLowerCase().startsWith(inputValue.toLowerCase()),
        ),
      );
    },
    // Custom handler for updating the form state when a user selects an item
    onSelectedItemChange: (state) => {
      // Update the state with the internal value of the selected item
      helpers.setValue(state.selectedItem?.value);

      // If the field has already been touched its state does not need to be updated
      if (!meta.touched) {
        helpers.setTouched(true);
      }
    },
  });

  // Opens the dropdown if it is closed
  const handleOpenDropdown = () => {
    // If the dropdown is already open do not modify its state
    if (isOpen) {
      return;
    }

    // toggleMenu should always update the open state to true
    // since it should only be called here if the menu is closed
    toggleMenu();
  };

  // Custom onBlur handler that marks the field as touched within the form
  const handleBlur = () => {
    // If the field has already been touched its state does not need to be updated
    if (!meta.touched) {
      helpers.setTouched(true);
    }
  };

  return (
    <>
      <ComboboxWrapper
        {...getComboboxProps({ id: `${formTestId}__${name}-combobox` })}
      >
        <Field
          data-testid={`${formTestId}__${name}-field`}
          {...getInputProps({
            "aria-labelledby": `${formTestId}__${name}-input`,
            id: `${formTestId}__${name}-input`,
            onBlur: handleBlur,
            // The dropdown should open when the user clicks into the input
            onFocus: handleOpenDropdown,
            placeholder,
            required,
          })}
        />

        <ToggleMenuButton
          data-testid={`${formTestId}__${name}-button`}
          {...getToggleButtonProps({
            "aria-label": "toggle menu",
            "aria-labelledby": `${formTestId}__${name}-input`,
            id: `${formTestId}__${name}-button`,
            onClick: handleOpenDropdown,
          })}
        >
          <ToggleMenuButtonLabel menuIsOpen={isOpen}>
            <FontAwesomeIcon icon={faArrowDown} />
          </ToggleMenuButtonLabel>
        </ToggleMenuButton>
      </ComboboxWrapper>

      <DropdownWrapper id={`${formTestId}__${name}-dropdown`}>
        <Dropdown
          isOpen={isOpen}
          {...getMenuProps({
            "aria-labelledby": `${formTestId}__${name}-input`,
            id: `${formTestId}__${name}-menu`,
            name,
            autoComplete: "off",
          })}
          data-testid={`${formTestId}__${name}-input`}
        >
          {items.map((item, index) => (
            <Option
              key={`${formTestId}${item.value}`}
              highlighted={highlightedIndex === index}
              disabled={item.disabled}
              {...getItemProps({
                disabled: item.disabled,
                index,
                item,
                value: item.value,
              })}
            >
              {item.name}
            </Option>
          ))}
        </Dropdown>
      </DropdownWrapper>
    </>
  );
};
