import * as React from "react";
import { Formik } from "formik";

import { PunchFormSuccessScreen } from "../common/PunchFormSuccessScreen";
import { formikConfig } from "../../../../lib/formik";
import { useMultiSubmitFormBuilder } from "../../../../hooks";
import { usePunchInFormConfig } from "./usePunchInFormConfig";

/**
 * Form that allows an authorized user to clock in.
 */
export const PunchInForm: React.FC = () => {
  // Track the submission state of the form
  const [didSubmitSuccessfully, setDidSubmitSuccessfully] = React.useState(
    false,
  );

  // Get configuration to pass to form builder
  const punchInFormConfig = usePunchInFormConfig({ setDidSubmitSuccessfully });

  // Use a Form Builder hook to generate elements to display to the user
  const {
    ApiResponseCard,
    Form,
    FormGroups,
    SubmitButtons,
  } = useMultiSubmitFormBuilder(punchInFormConfig);

  // Extract values needed to configure Formik
  const { initialValues, onSubmit, validationSchema } = punchInFormConfig;

  return (
    <Formik {...formikConfig({ initialValues, onSubmit, validationSchema })}>
      {({ setFieldValue, isValid, isSubmitting }) => (
        <Form
          success={didSubmitSuccessfully}
          renderOnSuccess={
            <PunchFormSuccessScreen apiResponseCard={<ApiResponseCard />} />
          }
        >
          <ApiResponseCard />

          <FormGroups />

          <SubmitButtons
            setFieldValue={setFieldValue}
            disabled={!isValid || isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};
