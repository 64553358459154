import {
  REACT_APP_API_ENDPOINT,
  REACT_APP_API_URL,
  REACT_APP_GRAPHQL_ENDPOINT,
} from "./environmentVariables";

/**
 * The full API URL for the REST endpoint.
 */
export const REST_API_URL = REACT_APP_API_URL + REACT_APP_API_ENDPOINT;

/**
 * The full API URL for the GraphQL endpoint.
 */
export const GRAPHQL_API_URL = REACT_APP_API_URL + REACT_APP_GRAPHQL_ENDPOINT;
