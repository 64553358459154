import * as React from "react";
import * as Yup from "yup";

import {
  CreateNewTaskPunchInput,
  LocationId,
  useCreateNewTaskPunchMutation,
  useCurrentUserQuery,
} from "../../../../generated/graphql";
import { Errors } from "../../../../constants/errors";
import {
  FieldType,
  FormConfigProps,
  MultiSubmitFormType,
  SelectionFieldType,
  StatusType,
} from "../../../../store/types";
import { TaskFilteringToggleCheckbox } from "../../common/TaskFilteringToggleCheckbox";
import { locationIdButtons } from "../../../../utils";
import {
  useClientsDropdownValues,
  useFormValueAutoFilling,
  usePunchFormSubmissionCleanup,
  useTasksDropdownValues,
} from "../../../../hooks";

/**
 * Memoized configuration object for the New Task Form to reduce
 * unneccessary rerenders.
 */
export function useNewTaskFormConfig({
  setDidSubmitSuccessfully,
}: FormConfigProps): MultiSubmitFormType<CreateNewTaskPunchInput> {
  // User Information query is called to get the user's id and department
  const { data } = useCurrentUserQuery();
  const user = data?.currentUser.payload.user;

  // createNewTaskPunch function will call the mutation to create the punch using the data entered into the form
  const [createNewTaskPunch] = useCreateNewTaskPunchMutation();

  // Clients and Tasks are requested to populate dropdown menus
  const { clientIds, clientsDropdownValues } = useClientsDropdownValues();
  const { taskIds, tasksDropdownValues } = useTasksDropdownValues();

  // Get the initial value of the clientId field based on the user's recent punch and their department's permissions
  const { initialClientId } = useFormValueAutoFilling();

  // The cleanup function will log a user out or return them to the home screen
  // depending on their settings
  const { delayedCleanup } = usePunchFormSubmissionCleanup();

  // State value to store error and success response messages
  const [apiResponseMessage, setApiResponseMessage] = React.useState<
    [StatusType, string]
  >(["idle", ""]);

  return React.useMemo(() => {
    return {
      apiResponseMessage,
      fieldGroups: [
        {
          testId: "punchInformation",
          title: "New Task Information",
          fields: [
            {
              displayName: "Client",
              selectionOptions: clientsDropdownValues,
              name: "clientId",
              required: true,
              type: "combobox",
            } as SelectionFieldType,
            {
              displayName: "Task",
              selectionOptions: tasksDropdownValues,
              name: "newTaskId",
              required: true,
              type: "combobox",
              // Users should be able to toggle between showing all tasks
              // and only tasks relevant to their department
              modifiers: <TaskFilteringToggleCheckbox />,
            } as SelectionFieldType,
          ],
        },
        {
          testId: "punchInformation",
          title: "Previous Task Override",
          description:
            "If you need to change any details about your previous task you can do so here.",
          fields: [
            {
              displayName: "Previous Task",
              selectionOptions: tasksDropdownValues,
              name: "previousTaskId",
              required: false,
              type: "combobox",
              // Users should be able to toggle between showing all tasks
              // and only tasks relevant to their department
              modifiers: <TaskFilteringToggleCheckbox />,
            } as SelectionFieldType,
            {
              displayName: "Previous Task Comment",
              selectionOptions: tasksDropdownValues,
              name: "previousTaskComment",
              required: false,
              type: "textbox",
            } as FieldType,
          ],
        },
      ],
      initialValues: {
        clientId: initialClientId,
        locationId: LocationId.Unknown,
        newTaskId: "",
        userId: user?.id || "",
        previousTaskComment: "",
        previousTaskOverrideId: "",
      },
      onSubmit: async (values) => {
        try {
          // Attempt to change a user's task with the provided user inputs
          const { data: responseData } = await createNewTaskPunch({
            variables: {
              data: values,
            },
          });

          // Ensure data was returned from the API
          if (responseData) {
            // Display the resulting message to the user
            setApiResponseMessage([
              "success",
              responseData.createNewTaskPunch.message,
            ]);

            // Set successful submit state to true to update UI
            setDidSubmitSuccessfully(true);

            // Perform all cleanup duties based on the user's settings
            await delayedCleanup();
          }
        } catch (error) {
          // If an error occurs display the message to the user
          setApiResponseMessage(["error", error.message]);
        }
      },
      submitButtonField: {
        buttons: locationIdButtons,
        name: "locationId",
        displayName: "Location",
        description: "Where are you working today?",
      },
      testId: "newTaskForm",
      title: "Change Task",
      validationSchema: Yup.object().shape<CreateNewTaskPunchInput>({
        userId: Yup.string().required(),
        clientId: Yup.string()
          .oneOf(clientIds, Errors.ClientMustBeInDropdown)
          .required(Errors.ClientMustBeInDropdown),
        newTaskId: Yup.string()
          .oneOf(taskIds, Errors.TaskMustBeInDropdown)
          .required(Errors.TaskMustBeInDropdown),
        previousTaskOverrideId: Yup.string().oneOf(
          taskIds,
          Errors.TaskMustBeInDropdown,
        ),
        previousTaskComment: Yup.string(),
        locationId: Yup.mixed(),
      }),
    };
  }, [
    apiResponseMessage,
    clientsDropdownValues,
    tasksDropdownValues,
    initialClientId,
    user?.id,
    clientIds,
    taskIds,
    createNewTaskPunch,
    delayedCleanup,
    setDidSubmitSuccessfully,
  ]);
}
