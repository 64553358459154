import * as React from "react";
import * as Yup from "yup";
import { faCalendarEdit } from "@fortawesome/pro-solid-svg-icons";

import {
  CreateOutPunchOverrideInput,
  useCreateOutPunchOverrideMutation,
  useCurrentUserQuery,
} from "../../../../generated/graphql";
import { Errors } from "../../../../constants/errors";
import {
  FieldType,
  FormConfigProps,
  FormType,
  SelectionFieldType,
  StatusType,
} from "../../../../store/types";
import {
  useSupportAndOverrideFormSubmissionCleanup,
  useUsersDropdownValues,
} from "../../../../hooks";

/**
 * Memoized configuration object for the Override Out Punch Form to reduce
 * unneccessary rerenders.
 */
export function useOverrideOutPunchFormConfig({
  setDidSubmitSuccessfully,
}: FormConfigProps): FormType<CreateOutPunchOverrideInput> {
  // User Information query is called to get the user's id and department
  const { data: userInformationData } = useCurrentUserQuery();
  const user = userInformationData?.currentUser.payload.user;

  // The createInPunchOverride function will call the mutation to create the punch using the data entered into the form
  const [createOutPunchOverride] = useCreateOutPunchOverrideMutation();

  // Clients, Tasks, and Users are requested to populate dropdown menus

  const { userIds, usersDropdownValues } = useUsersDropdownValues();

  const { delayedCleanup } = useSupportAndOverrideFormSubmissionCleanup();

  // State value to store error and success response messages
  const [apiResponseMessage, setApiResponseMessage] = React.useState<
    [StatusType, string]
  >(["idle", ""]);

  return React.useMemo(() => {
    return {
      apiResponseMessage,
      fieldGroups: [
        {
          testId: "punchInformation",
          title: "Punch Information",
          description: "Please fill out these fields to complete the override.",
          fields: [
            {
              name: "userId",
              displayName: "User",
              type: "combobox",
              required: true,
              selectionOptions: usersDropdownValues,
            } as SelectionFieldType,
            {
              name: "comment",
              displayName: "Shift Comment",
              type: "textbox",
              required: false,
            } as FieldType,
            {
              name: "time",
              type: "date",
              required: true,
            } as FieldType,
          ],
        },
      ],
      initialValues: {
        comment: "",
        supervisorId: user?.id || "",
        time: new Date(),
        userId: "",
      },
      onSubmit: async (values) => {
        try {
          // Attempt to create an In Punch Override with the provided user inputs
          const { data } = await createOutPunchOverride({
            variables: {
              data: values,
            },
          });

          // Ensure data was returned from the API
          if (data) {
            // Display the resulting message to the user
            setApiResponseMessage([
              "success",
              data.createOutPunchOverride.message,
            ]);

            // Set successful submit state to true to update UI
            setDidSubmitSuccessfully(true);

            // Perform all cleanup duties based on the user's settings
            delayedCleanup();
          }
        } catch (error) {
          setApiResponseMessage(["error", error.message]);
        }
      },
      submitButton: {
        icon: faCalendarEdit,
        label: "Submit Override",
      },
      testId: "overrideOutPunchForm",
      title: "Override Out Punch",
      validationSchema: Yup.object().shape<CreateOutPunchOverrideInput>({
        supervisorId: Yup.string().required(),

        time: Yup.date().required(),
        userId: Yup.string()
          .oneOf(userIds, Errors.UserMustBeInDropdown)
          .required(Errors.UserMustBeInDropdown),
      }),
    };
  }, [
    apiResponseMessage,
    usersDropdownValues,
    user?.id,
    userIds,
    createOutPunchOverride,
    setDidSubmitSuccessfully,
    delayedCleanup,
  ]);
}
