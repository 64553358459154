import * as React from "react";
import { Field } from "formik";

import { Combobox as ComboboxDisplay } from "./Combobox.display";
import { SelectionFieldType } from "../../../store/types";

type Props = {
  formTestId: string;
} & SelectionFieldType;

/**
 * A Combobox wrapped in a Formik Field for use in forms controlled by Formik.
 */
export const Combobox: React.FC<Props> = (props) => (
  <Field as={ComboboxDisplay} {...props} />
);
