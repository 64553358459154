import * as React from "react";
import { useHistory } from "react-router-dom";

import { FIVE_SECONDS } from "../constants/timeValues";
import { Routes } from "../constants/routes";
import { useLogoutCleanup } from "./useLogoutCleanup";
import { useUserSettingsContext } from "../store/contexts/userSettings";

/**
 * Returns a function that performs all tasks that need to be ran after a Punch form has been submitted
 * and a second function that will call the first after a five (5) second delay.
 */
export function usePunchFormSubmissionCleanup() {
  // The user's settings must be accessed to determine if they expect
  // to remain logged in between app sessions
  const { userSettings } = useUserSettingsContext();

  // History must be accessed to redirect the user to the home screen if they
  // have selected to remain logged in
  const history = useHistory();

  // The logout cleanup will be used if the user does not expect to remain logged in
  const logout = useLogoutCleanup();

  // Store a reference to whether the hook is mounted or unmounted
  const didUnmountReference = React.useRef(false);

  // When this hook unmounts, flip the reference to true
  React.useEffect(() => {
    return () => {
      didUnmountReference.current = true;
    };
  }, []);

  // Store a reference to the timeout that will call the cleanup functions after a delay
  const timeoutReference: React.MutableRefObject<NodeJS.Timeout | null> = React.useRef(
    null,
  );

  const cleanup = async () => {
    // If the user did not indicate they want to remain logged in
    // perform logout duties
    if (!userSettings.shouldLoginPersist) {
      await logout();

      return;
    }

    // If a user did indicate they want to remain logged in return them to the home screen
    history.push(Routes.Home);
  };

  const delayedCleanup = async () => {
    // After a five second delay return the user to the home screen
    // or login screen depending on their settings
    timeoutReference.current = setTimeout(async () => {
      // If the hook has unmounted do not perform cleanup actions
      if (didUnmountReference.current) {
        return;
      }

      await cleanup();
    }, FIVE_SECONDS);
  };

  // If the hook has unmounted clear the timeout to clean up potential memory leaks
  React.useEffect(() => {
    return () => {
      if (timeoutReference.current) {
        clearTimeout(timeoutReference.current);
      }
    };
  }, []);

  return {
    cleanup,
    delayedCleanup,
  };
}
