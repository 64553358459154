/**
 * Gets a time apropriate greeting based on the current hour in the day
 */
const getTimeOfDayMessage = () => {
  const hours = new Date(Date.now()).getHours();

  switch (true) {
    // Between 5AM and 12PM
    case hours >= 5 && hours <= 11:
      return "Good morning";

    // Between 12PM and 5PM
    case hours <= 16:
      return "Good afternoon";

    // Between 5PM and 7PM
    case hours <= 18:
      return "Good evening";

    // Between 7PM and 5AM
    default:
      return "Good night";
  }
};

/**
 * Greets the user by name with a message based on the time of day
 * and informs the user how to proceed.
 */
export function getWelcomeMessage(userName: string): string {
  const firstName = userName.split(" ")[0];

  return `${getTimeOfDayMessage()}, ${firstName}! Please select one of the options below.`;
}
