import * as React from "react";

import { Checkbox } from "../../../../components/atoms/Checkbox/Checkbox.display";
import { Flexbox } from "../../../../components";
import {
  UserSettingsActionTypes as Types,
  useUserSettingsContext,
} from "../../../../store/contexts/userSettings";
import { spacing } from "../../../../utils";

/**
 * Renders a checkbox that reflects and can update the value of the user setting
 * controlling what tasks are displayed in dropdown menus.
 */
export const TaskFilteringToggleCheckbox: React.FC = () => {
  // User settings are accessed to read and modify the value of `shouldAllTasksBeDisplayed`
  const { userSettings, dispatch } = useUserSettingsContext();

  return (
    <Flexbox direction="right-left" style={{ paddingTop: spacing[4] }}>
      <Checkbox
        formTestId=""
        displayName="Show All Tasks"
        name="shouldAllTasksBeDisplayed"
        checked={userSettings.shouldAllTasksBeDisplayed}
        onChange={() => {
          dispatch({
            type: Types.ToggleDisplayAllTasksInDropdown,
          });
        }}
      />
    </Flexbox>
  );
};
