import { brandColors } from "./brandColors";
import { roleColors } from "./roleColors";
import { transparent } from "./genericColors";

/**
 * This file contains color configurations for specific UI elements.
 */

/**
 * Color configuration for the root of the App.
 *
 * Sets defaults for main body text and background,
 * as well as other major UI elements that create the main structure of the page.
 */
export const rootColors = {
  main: {
    background: brandColors.gray[100],
    text: brandColors.gray[700],
  },
  footer: {
    background: brandColors.gray[700],
    text: brandColors.white,
  },
};

/**
 * Color configuration for Button and Button-like elements.
 *
 * Sets a color for each tier of button's border, background, and text.
 * And defines any changes that should be made when the button is in a hover state.
 */
export const buttonColors = {
  primary: {
    border: brandColors.teal[200],
    background: brandColors.teal[200],
    text: brandColors.white,
    hover: {
      background: brandColors.teal[100],
    },
  },
  secondary: {
    border: brandColors.teal[200],
    background: brandColors.gray[100],
    text: rootColors.main.text,
    hover: {
      background: brandColors.teal[100],
      border: brandColors.teal[100],
      text: brandColors.white,
    },
  },
  tertiary: {
    border: transparent,
    background: transparent,
    text: rootColors.main.text,
    hover: {
      background: brandColors.gray[300],
    },
  },
};

/**
 * Color configuration for navigation elements.
 */
export const navigationColors = {
  link: brandColors.teal[100],
};

/**
 * Color configuration for Card elements.
 */
export const cardColors = {
  background: {
    default: brandColors.gray[100],
    error: roleColors.error,
    success: roleColors.success,
    info: roleColors.info,
  },
  text: {
    default: rootColors.main.text,
    modifier: brandColors.white,
  },
};

/**
 * Color configuration for Form elements.
 */
export const formColors = {
  input: {
    background: brandColors.gray[300],
    text: brandColors.black,
  },
};
