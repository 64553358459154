import * as React from "react";
import { Formik } from "formik";

import { SupportAndOverrideFormSuccessScreen } from "../../common/SupportAndOverrideFormSuccessScreen";
import { formikConfig } from "../../../../lib/formik";
import { useFormBuilder } from "../../../../hooks";
import { useGeneralFeedbackFormConfig } from "./useGeneralFeedbackFormConfig";

/**
 * Form that allows any user to submit feedback for the developers.
 */
export const GeneralFeedbackForm: React.FC = () => {
  // Track the submission state of the form
  const [didSubmitSuccessfully, setDidSubmitSuccessfully] = React.useState(
    false,
  );

  // Get configuration to pass to form builder
  const generalFeedbackFormConfig = useGeneralFeedbackFormConfig({
    setDidSubmitSuccessfully,
  });

  // Use a Form Builder hook to generate elements to display to the user
  const {
    Form,
    ApiResponseCard,
    FormGroups,
    SubmitButton,
    Title,
  } = useFormBuilder(generalFeedbackFormConfig);

  // Extract values needed to configure Formik
  const {
    initialValues,
    onSubmit,
    validationSchema,
  } = generalFeedbackFormConfig;

  return (
    <Formik {...formikConfig({ initialValues, onSubmit, validationSchema })}>
      {({ isSubmitting, isValid }) => (
        <Form
          success={didSubmitSuccessfully}
          renderOnSuccess={
            <SupportAndOverrideFormSuccessScreen
              apiResponseCard={<ApiResponseCard />}
            />
          }
        >
          <ApiResponseCard />

          <Title />

          <FormGroups />

          <SubmitButton disabled={!isValid || isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};
