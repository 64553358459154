import * as React from "react";
import * as Yup from "yup";
import { faBug } from "@fortawesome/pro-solid-svg-icons";

import {
  CreateBugReportInput,
  useCreateBugReportMutation,
} from "../../../../generated/graphql";
import {
  FieldType,
  FormConfigProps,
  FormType,
  StatusType,
} from "../../../../store/types";
import { useSupportAndOverrideFormSubmissionCleanup } from "../../../../hooks";

/**
 * Memoized configuration object for the Bug Report Form to reduce
 * unneccessary rerenders.
 */
export function useBugReportFormConfig({
  setDidSubmitSuccessfully,
}: FormConfigProps): FormType<CreateBugReportInput> {
  // The createBugReport function will call the mutation
  //  to create the report using the data entered into the form
  const [createBugReport] = useCreateBugReportMutation();

  // The cleanup function will log a user out or return them to the home screen
  // depending on their settings
  const { delayedCleanup } = useSupportAndOverrideFormSubmissionCleanup();

  // State value to store error and success response messages
  const [apiResponseMessage, setApiResponseMessage] = React.useState<
    [StatusType, string]
  >(["idle", ""]);

  return React.useMemo(() => {
    return {
      apiResponseMessage,
      fieldGroups: [
        {
          testId: "requiredInformation",
          title: "Required Information",
          description:
            "Please answer the following questions to help us solve the issue you are having.",
          fields: [
            {
              name: "currentBehavior",
              displayName: "Current Behavior",
              required: true,
              type: "textbox",
              placeholder: "Please describe how the app is behaving",
            } as FieldType,
            {
              name: "expectedBehavior",
              displayName: "Expected Behavior",
              required: true,
              type: "textbox",
              placeholder: "Please describe how the app was expected to behave",
            } as FieldType,
          ],
        },
        {
          testId: "optionalInformation",
          title: "Optional Information",
          description:
            "Answering the following questions is not required, but would be helpful for us to track down and solve your issue faster.",
          fields: [
            {
              name: "issueContext",
              displayName: "Context",
              required: false,
              type: "textbox",
              placeholder:
                "Please describe what you were doing when you encountered the issue",
            } as FieldType,
            {
              name: "userEnvironment",
              displayName: "Environment",
              required: false,
              type: "textbox",
              placeholder:
                "Please enter some information about the device you are using. What browser, operating system, etc.?",
            } as FieldType,
          ],
        },
      ],
      initialValues: {
        currentBehavior: "",
        expectedBehavior: "",
        issueContext: "",
        userEnvironment: "",
      },
      onSubmit: async (values) => {
        try {
          // Submit the feedback report
          const { data } = await createBugReport({
            variables: {
              data: values,
            },
          });

          // Ensure data was returned from the API
          if (data) {
            // Display the resulting message to the user
            setApiResponseMessage(["success", data.createBugReport.message]);

            // Set successful submit state to true to update UI
            setDidSubmitSuccessfully(true);

            delayedCleanup();
          }
        } catch (error) {
          // If an error occurs display the message to the user
          setApiResponseMessage(["error", error.message]);
        }
      },
      submitButton: {
        icon: faBug,
        label: "Submit Bug Report",
      },
      testId: "bugReportForm",
      title: "Bug Report",
      validationSchema: Yup.object().shape<CreateBugReportInput>({
        currentBehavior: Yup.string().required(),
        expectedBehavior: Yup.string().required(),
        issueContext: Yup.string(),
        userEnvironment: Yup.string(),
      }),
    };
  }, [
    apiResponseMessage,
    createBugReport,
    delayedCleanup,
    setDidSubmitSuccessfully,
  ]);
}
