/**
 * Configure breakpoints for Media Queries to change at.
 */
// eslint-disable-next-line no-shadow -- https://github.com/typescript-eslint/typescript-eslint/issues/325
export enum Breakpoint {
  /**
   * Large Phones
   */
  Small = "576px",
  /**
   * Tablets
   */
  Medium = "768px",
  /**
   * Laptops
   */
  Large = "992px",
  /**
   * Desktops
   */
  ExtraLarge = "1200px",
}
