/**
 * Five-hundred milliseconds.
 */
export const FIVE_HUNDRED_MS = 500;

/**
 * One second represented in milliseconds.
 */
export const ONE_SECOND = 1000;

/**
 * Five seconds represented in milliseconds.
 */
export const FIVE_SECONDS = ONE_SECOND * 5;

/**
 * Thirty seconds represented in milliseconds.
 */
export const THIRTY_SECONDS = ONE_SECOND * 30;

/**
 * Fourty-five seconds represented in milliseconds.
 */
export const FORTY_FIVE_SECONDS = ONE_SECOND * 45;

/**
 * One minute represented in milliseconds.
 */
export const ONE_MINUTE = ONE_SECOND * 60;

/**
 * Ten minutes represented in milliseconds.
 */
export const TEN_MINUTES = ONE_MINUTE * 15;
