import * as React from "react";

import { Button, ButtonGroup } from "../../components";
import {
  Description,
  FieldGroupWrapper,
  Title,
} from "../../components/molecules/FieldGroup/FieldGroup.style";
import { MultiSubmitFormType } from "../../store/types";
import { useCommonFormBuilderElements } from "./utils/useCommonFormBuilderElements";

/**
 * Accepts a multi-submit form configuration object and returns components generated
 * using that configuration to create the Form UI.
 */
export function useMultiSubmitFormBuilder<T>(
  formConfig: MultiSubmitFormType<T>,
) {
  // Use form elements common to all forms
  const commonFormElements = useCommonFormBuilderElements(formConfig);

  /**
   * Renders all submit buttons provided by the formConfig.
   */
  const SubmitButtons: React.FC<{
    disabled: boolean;
    setFieldValue: (field: string, value: any) => void;
  }> = ({ disabled, setFieldValue }) => {
    const {
      name,
      description,
      buttons,
      displayName = name,
    } = formConfig.submitButtonField;

    return (
      <FieldGroupWrapper>
        <Title>{displayName}</Title>

        {description && <Description>{description}</Description>}

        <ButtonGroup>
          {buttons.map(({ testId, label, value, icon }) => (
            <Button
              key={testId}
              data-testid={`${formConfig.testId}__${testId}-button`}
              type="submit"
              label={label}
              onClick={() => {
                setFieldValue(name, value);
              }}
              disabled={disabled}
              icon={icon}
            />
          ))}
        </ButtonGroup>
      </FieldGroupWrapper>
    );
  };

  return {
    ...commonFormElements,
    SubmitButtons,
  };
}
