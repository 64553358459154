import * as React from "react";
import { ApolloQueryResult } from "@apollo/client";
import {
  faClock,
  faInbox,
  faShield,
  faSync,
} from "@fortawesome/pro-solid-svg-icons";

import { Button, Card } from "../../atoms";
import { ButtonGroup, Flexbox } from "../../templates";
import {
  Exact,
  GetQueueInformationQuery,
  QueueManager,
} from "../../../generated/graphql";
import { LineItem } from "../../molecules";
import { convertMsToSeconds, convertStatusToColor } from "../../../utils";

type Props = {
  isLoading: boolean;
  refetch: (
    variables?:
      | Partial<
          Exact<{
            [key: string]: never;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<GetQueueInformationQuery>>;
} & QueueManager;

/**
 * Displays information about the current status of the Queue Manager system.
 */
export const QueueManagerCard: React.FC<Props> = ({
  state,
  uptime,
  currentQueueName,
  isLoading,
  refetch,
}) => {
  return (
    <Card title="Queue Manager">
      <Flexbox alignContent="evenly">
        <LineItem
          label="State"
          content={state}
          icon={faShield}
          iconBackgroundColor={convertStatusToColor("info")}
        />

        <LineItem
          label="Uptime"
          content={convertMsToSeconds(uptime || 0)}
          icon={faClock}
          iconBackgroundColor={convertStatusToColor("info")}
        />

        <LineItem
          label="Current Queue"
          content={currentQueueName}
          icon={faInbox}
          iconBackgroundColor={convertStatusToColor("info")}
        />
      </Flexbox>

      <ButtonGroup>
        <Button
          icon={faSync}
          label="Refresh Dashboard"
          disabled={isLoading}
          spin={isLoading}
          onClick={async () => {
            await refetch();
          }}
        />
      </ButtonGroup>
    </Card>
  );
};
