import * as React from "react";

type Props = {
  condition: boolean;
  submitForm: () => Promise<void>;
};

/**
 * Does not render any elements to the UI. Instead listens for a condition,
 * that when true will call a function to submit a form.
 * This component is used to allow forms to submit automatically without the need
 * for users to click a submit button.
 */
export const FormAutoSubmit: React.FC<Props> = ({ condition, submitForm }) => {
  // Monitor the status of the provided condition
  React.useEffect(() => {
    // When the condition resolves to true
    if (condition) {
      // Call the function needed to submit a form
      submitForm();
    }
  }, [condition, submitForm]);

  return null;
};
