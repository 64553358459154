import * as React from "react";
import Normalize from "react-normalize";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";

import { App } from "./App";
import { AuthWrapper, DropdownMenuPrepopulationWrapper } from "../containers";
import { GlobalStyles, asciiBarn } from "../utils";
import { UserSettingsContextProvider } from "../store/contexts/userSettings";
import { apolloClient } from "../lib/apollo";

/**
 * Wraps the App in all external providers and global stylesheets.
 */
export const Root: React.FC = () => {
  // Print the hidden message to the console
  asciiBarn();

  return (
    <>
      {/**
       * Global Stylesheets
       */}
      <Normalize />
      <GlobalStyles />

      {/* Run React in Strict Mode to catch potential issues in development */}
      <React.StrictMode>
        {/* Provides client side routing capabilities */}
        <BrowserRouter>
          {/* Apollo GraphQL Client Provider */}
          <ApolloProvider client={apolloClient}>
            {/* Performs background user authentication to ensure correct data is stored for the current user */}
            <AuthWrapper>
              {/* Stores user settings in a globally available context provider */}
              <UserSettingsContextProvider>
                {/* Fetches data needed to populate dropdown menus to store that data in the Apollo cache */}
                <DropdownMenuPrepopulationWrapper>
                  {/* App Entrypoint */}
                  <App />
                </DropdownMenuPrepopulationWrapper>
              </UserSettingsContextProvider>
            </AuthWrapper>
          </ApolloProvider>
        </BrowserRouter>
      </React.StrictMode>
    </>
  );
};
