import * as React from "react";

import { Card, DefaultPageLayout, RadioPicker } from "../../components";
import {
  OverrideInPunchForm,
  OverrideNewTaskPunchForm,
  OverrideOutPunchForm,
} from "../../containers";
import { useDocumentTitle } from "../../hooks";

/**
 * Renders a set of radio buttons, each which render a different form
 * a Supervisor user can fill out to create an Override punch.
 */
export const OverrideForms: React.FC = () => {
  useDocumentTitle("Override");

  return (
    <DefaultPageLayout>
      <Card title="Supervisor Override">
        <RadioPicker
          options={[
            {
              radio: {
                name: "In Punch",
                value: "inPunchOverride",
              },
              component: OverrideInPunchForm,
            },
            {
              radio: {
                name: "New Task Punch",
                value: "newTaskPunchOverride",
              },
              component: OverrideNewTaskPunchForm,
            },
            {
              radio: {
                name: "Out Punch",
                value: "outPunchOverride",
              },
              component: OverrideOutPunchForm,
            },
          ]}
        />
      </Card>
    </DefaultPageLayout>
  );
};
