import styled from "styled-components";

import { Flexbox } from "../../templates";
import { Text, Title as T } from "../../atoms";
import { spacing } from "../../../utils";

/**
 * Wrapping component for all Fields in the group.
 */
export const FieldGroupWrapper = styled(Flexbox).attrs({
  as: "section",
  direction: "top-down",
})`
  padding: ${spacing[6]} 0;
`;

/**
 * The title of the group.
 */
export const Title = styled(T.H3)``;

/**
 * A description providing context about the purpose of the Fields in the group.
 */
export const Description = styled(Text)`
  padding: ${spacing[2]} 0;
`;
