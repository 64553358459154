import * as React from "react";
import { Formik } from "formik";

import { SupportAndOverrideFormSuccessScreen } from "../../common/SupportAndOverrideFormSuccessScreen";
import { formikConfig } from "../../../../lib/formik";
import { useFormBuilder } from "../../../../hooks";
import { useOverrideInPunchFormConfig } from "./useOverrideInPunchFormConfig";

/**
 * Allows a Supervisor user to create an Override In punch as a means of
 * fixing issues resulting from users forgetting to clock in at the correct time.
 */
export const OverrideInPunchForm: React.FC = () => {
  // Track the submission state of the form
  const [didSubmitSuccessfully, setDidSubmitSuccessfully] = React.useState(
    false,
  );

  // Get configuration to pass to form builder
  const overrideInPunchFormConfig = useOverrideInPunchFormConfig({
    setDidSubmitSuccessfully,
  });

  // Use a Form Builder hook to generate elements to display to the user
  const {
    Form,
    ApiResponseCard,
    FormGroups,
    SubmitButton,
    Title,
  } = useFormBuilder(overrideInPunchFormConfig);

  // Extract values needed to configure Formik
  const {
    initialValues,
    onSubmit,
    validationSchema,
  } = overrideInPunchFormConfig;

  return (
    <Formik {...formikConfig({ initialValues, onSubmit, validationSchema })}>
      {({ isSubmitting, isValid }) => (
        <Form
          success={didSubmitSuccessfully}
          renderOnSuccess={
            <SupportAndOverrideFormSuccessScreen
              apiResponseCard={<ApiResponseCard />}
            />
          }
        >
          <ApiResponseCard />

          <Title />

          <FormGroups />

          <SubmitButton disabled={!isValid || isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};
