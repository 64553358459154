import styled from "styled-components";

import { Breakpoint, spacing } from "../../../utils";

// Clamp the content to be no wider than `1000px` on Extra Large screen sizes
const CONTAINER_MAX_WIDTH = "1000px";

/**
 * Container to enforce centered and correctly spaced content.
 */
export const Container = styled.div`
  --margin: ${spacing[2]};
  margin: 0 var(--margin);

  @media screen and (min-width: ${Breakpoint.Small}) {
    --margin: ${spacing[4]};
  }

  @media screen and (min-width: ${Breakpoint.Medium}) {
    --margin: ${spacing[8]};
  }

  @media screen and (min-width: ${Breakpoint.Large}) {
    --margin: ${spacing[16]};
  }

  @media screen and (min-width: ${Breakpoint.ExtraLarge}) {
    --margin: auto;
    max-width: ${CONTAINER_MAX_WIDTH};
  }
`;
