import * as React from "react";

import {
  HamburgerIcon,
  HamburgerStyleProps,
  HamburgerWrapper,
} from "./Hamburger.style";

type Props = {
  onClick: () => void;
} & HamburgerStyleProps;

/**
 * A "Hamburger" button for toggling menus to be in an open or closed state.
 */
export const Hamburger: React.FC<Props> = ({ toggled, onClick }) => {
  return (
    <HamburgerWrapper onClick={onClick}>
      <HamburgerIcon toggled={toggled} />
    </HamburgerWrapper>
  );
};
