/**
 * All possible actions the User Settings context
 * is capable of performing.
 */
// eslint-disable-next-line no-shadow -- https://github.com/typescript-eslint/typescript-eslint/issues/325
export enum UserSettingsActionTypes {
  HideFirstTimeLoginToast = "HIDE_FIRST_TIME_LOGIN_TOAST",
  LoadSettingsFromLocalStorage = "LOAD_SETTINGS_FROM_LOCAL_STORAGE",
  LoadUserDepartmentConfiguration = "LOAD_USER_DEPARTMENT_CONFIGURATION",
  ToggleDisplayPersistLoginCheckbox = "TOGGLE_DISPLAY_PERSIST_LOGIN_CHECKBOX",
  ToggleDisplayAllTasksInDropdown = "TOGGLE_DISPLAY_ALL_TASKS_IN_DROPDOWN",
  ToggleAutoSubmitPinInput = "TOGGLE_AUTO_SUBMIT_PIN_INPUT",
  ToggleShouldLoginPersist = "TOGGLE_SHOULD_LOGIN_PERSIST",
  StoreRecentPunchData = "STORE_RECENT_PUNCH_DATA",
}
