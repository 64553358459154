import styled from "styled-components";

import { spacing } from "../../utils";

/**
 * Wraps an individual item in the list of settings to provide vertical padding.
 */
export const ItemWrapper = styled.section`
  padding: ${spacing[4]} 0;
`;
