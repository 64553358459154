import { DepartmentPermissionsType } from "../types";
import { UserDepartment } from "../../../../generated/graphql";

/**
 * List of User Departments whose users should be allowed to
 * auto-fill the Client value based on their most recent punch.
 */
const CLIENT_AUTOFILL_WHITELIST = new Set<UserDepartment>([
  UserDepartment.Software,
  UserDepartment.Design,
]);

/**
 * List of User Departments whose users should be allowed to
 * auto-fill the Task value based on their most recent punch.
 */
const TASK_AUTOFILL_WHITELIST = new Set<UserDepartment>([
  UserDepartment.Software,
  UserDepartment.Design,
]);

/**
 * Configures the User Department Permissions based on a provided User Department.
 */
export function handleSetUserDepartmentPermissions(
  userDepartment: UserDepartment,
): DepartmentPermissionsType {
  // Default all values to false
  const permissions = {
    canClientBeAutoFilled: false,
    canTaskBeAutoFilled: false,
  };

  // If the user's department is included in the client autofill whitelist
  // flip the setting to true
  if (CLIENT_AUTOFILL_WHITELIST.has(userDepartment)) {
    permissions.canClientBeAutoFilled = true;
  }

  // If the user's department is included in the task autofill whitelist
  // flip the setting to true
  if (TASK_AUTOFILL_WHITELIST.has(userDepartment)) {
    permissions.canTaskBeAutoFilled = true;
  }

  // Return configured permissions
  return permissions;
}
