import * as React from "react";
import { Route, Switch } from "react-router-dom";

import { Home } from "./Home";
import { Login } from "./Login";
import { NewTask } from "./NewTask";
import { NotFound } from "./404";
import { OverrideForms } from "./OverrideForms";
import { ProtectedRoute, UnauthorizedRoute } from "../containers";
import { PunchIn } from "./PunchIn";
import { PunchOut } from "./PunchOut";
import { Routes } from "../constants/routes";
import { Settings } from "./Settings";
import { SupportForms } from "./SupportForms";
import { SystemDashboard } from "./SystemDashboard";
import { UserRole } from "../generated/graphql";

/**
 * Router which will render specific page components based on the current url path
 * and the Role of the current user.
 */
export const Router: React.FC = () => {
  return (
    <Switch>
      <UnauthorizedRoute exact path={Routes.Login} component={Login} />

      <Route exact path={Routes.Settings} component={Settings} />

      <ProtectedRoute exact path={Routes.Support} component={SupportForms} />

      <ProtectedRoute exact path={Routes.Home} component={Home} />

      <ProtectedRoute exact path={Routes.PunchIn} component={PunchIn} />

      <ProtectedRoute exact path={Routes.PunchOut} component={PunchOut} />

      <ProtectedRoute exact path={Routes.PunchNewTask} component={NewTask} />

      <ProtectedRoute
        exact
        path={Routes.Override}
        component={OverrideForms}
        requiredRoles={[UserRole.Admin, UserRole.Supervisor]}
      />

      <ProtectedRoute
        exact
        path={Routes.Dashboard}
        component={SystemDashboard}
        requiredRoles={[UserRole.Admin]}
      />

      <Route path={Routes.CatchAll} component={NotFound} />
    </Switch>
  );
};
