import * as React from "react";
import { Link } from "react-router-dom";

import { HeaderContainer, HeaderWrapper, Logo } from "./Header.style";
import { Routes } from "../../../constants/routes";

/**
 * Default Header element to be used at the top of each page.
 */
export const Header: React.FC = () => {
  return (
    <HeaderWrapper>
      <HeaderContainer>
        {/* Clicking the logo to return to the home page is a commonly expected feature */}
        <Link to={Routes.Home}>
          <Logo>Barn Pros: TimePunch</Logo>
        </Link>
      </HeaderContainer>
    </HeaderWrapper>
  );
};
