import { UserSettingsActionTypes as Types } from "./actions";
import { UserSettingsActions, UserSettingsStateType } from "./types";
import { handleLoadSettingsFromLocalStorage } from "./utils/handleLoadSettingsFromLocalStorage";
import { handlePersistToLocalStorage } from "./utils/handlePersistToLocalStorage";
import { initialState } from "./initialState";

/**
 * Reducer to handle actions dispatched to the User Settings context.
 * When settings are changed they are immediately saved to Local Storage
 * to ensure consistant user experiences.
 */
export function userSettingsContextReducer(
  state: UserSettingsStateType = initialState,
  action: UserSettingsActions,
): UserSettingsStateType {
  /* eslint-disable no-case-declarations -- Case declarations make persisting to local storage much easier  */
  switch (action.type) {
    /**
     * Flips the value of `shouldPersistLoginCheckboxBeVisable` to the opposite of its current value.
     */
    case Types.ToggleDisplayPersistLoginCheckbox:
      // Update user settings
      const stateWithPersistLoginOption = {
        ...state,
        shouldPersistLoginCheckboxBeVisable: !state.shouldPersistLoginCheckboxBeVisable,
      };

      // Save new settings configuration to Local Storage
      handlePersistToLocalStorage(stateWithPersistLoginOption);

      // Return updated state
      return stateWithPersistLoginOption;

    /**
     * Flips the value of `shouldPinInputAutoSubmit` to the opposite of its current value.
     */
    case Types.ToggleAutoSubmitPinInput:
      // Update user settings
      const stateWithAutoSubmitOption = {
        ...state,
        shouldPinInputAutoSubmit: !state.shouldPinInputAutoSubmit,
      };

      // Save new settings configuration to Local Storage
      handlePersistToLocalStorage(stateWithAutoSubmitOption);

      // Return updated state
      return stateWithAutoSubmitOption;

    /**
     * Sets the value of `shouldFirstTimeLoginToastBeDisplayed` to false, which should be read to conditionally hide the toast message.
     */
    case Types.HideFirstTimeLoginToast:
      // Update user settings
      const stateWithFirstTimeLoginToastOption = {
        ...state,
        shouldFirstTimeLoginToastBeDisplayed: false,
      };

      // Save new settings configuration to Local Storage
      handlePersistToLocalStorage(stateWithFirstTimeLoginToastOption);

      // Return updated state
      return stateWithFirstTimeLoginToastOption;

    /**
     * Flips the value of `shouldAllTasksBeDisplayed` to the opposite of its current value.
     */
    case Types.ToggleDisplayAllTasksInDropdown:
      // Update user settings
      const stateWithTaskFilteringOption = {
        ...state,
        shouldAllTasksBeDisplayed: !state.shouldAllTasksBeDisplayed,
      };

      // Save new settings configuration to Local Storage
      handlePersistToLocalStorage(stateWithTaskFilteringOption);

      // Return updated state
      return stateWithTaskFilteringOption;

    /**
     * Flips the value of `shouldLoginPersist` to the opposite of its current value.
     */
    case Types.ToggleShouldLoginPersist:
      // Update user settings
      const stateWithLoginPersistOption = {
        ...state,
        shouldLoginPersist: !state.shouldLoginPersist,
      };

      // Save new settings configuration to Local Storage
      handlePersistToLocalStorage(stateWithLoginPersistOption);

      // Return updated state
      return stateWithLoginPersistOption;

    /**
     * Loads settings based on the user's department.
     */
    case Types.LoadUserDepartmentConfiguration:
      // Update user settings
      const stateWithDepartmentPermissions = {
        ...state,
        departmentPermissions: action.payload,
      };
      // Save new settings configuration to Local Storage
      handlePersistToLocalStorage(stateWithDepartmentPermissions);

      // Return updated state
      return stateWithDepartmentPermissions;

    /**
     * Loads saved user settings from Local Storage.
     */
    case Types.LoadSettingsFromLocalStorage:
      // Find user settings saved in Local Storage
      const userSettings = handleLoadSettingsFromLocalStorage();

      // Return updated state
      return { ...state, ...userSettings };

    /**
     * Saves recent punch data in the user's settings.
     */
    case Types.StoreRecentPunchData:
      // Update user settings
      const stateWithRecentPunchData = {
        ...state,
        recentPunchData: action.payload,
      };
      // Save new settings configuration to Local Storage
      handlePersistToLocalStorage(stateWithRecentPunchData);

      // Return updated state
      return stateWithRecentPunchData;

    /**
     * If an unexpected action type is passed to the reducer do not update
     * any settings and return the state in its current form.
     */
    default:
      return state;
  }
  /* eslint-enable no-case-declarations */
}
