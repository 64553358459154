import { USER_SETTINGS } from "../../../../constants/localStorageVariables";
import { UserSettingsStateType } from "../types";
import { initialState } from "../initialState";

/**
 * Finds settings saved in the user's Local Storage and returns them
 * to populate the user's settings configuration.
 */
export function handleLoadSettingsFromLocalStorage(): UserSettingsStateType {
  // Find settings saved in Local Storage
  const localStorageSettings = localStorage.getItem(USER_SETTINGS);

  // If no settings are found return the default settings
  if (!localStorageSettings) {
    return initialState;
  }

  // Parse dehydrated settings
  const parsedSettings: UserSettingsStateType = JSON.parse(
    localStorageSettings,
  );

  return parsedSettings;
}
