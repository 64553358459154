/**
 * Numeric values to be used in Z-Index CSS properties,
 * configured here to alleviate confusion and ensure consistency.
 */
// eslint-disable-next-line no-shadow -- https://github.com/typescript-eslint/typescript-eslint/issues/325
export enum ZIndex {
  Base = 0,
  MainContent = 10,
  MainContent_DropdownMenu = 15,
  NavMenu = 20,
  NavMenuToggleButton = 30,
}
