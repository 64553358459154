import styled from "styled-components";

import { Flexbox } from "../../templates";
import { StatusMessage } from "../../atoms";
import { spacing } from "../../../utils";

/**
 * Wraps the Input, Label, and error message.
 */
export const InputControllerWrapper = styled(Flexbox).attrs({
  as: "article",
  direction: "top-down",
})`
  margin: ${spacing[4]} 0;
`;

/**
 * Labels the associated input.
 */
export const InputLabel = styled.label`
  display: block;
  padding-bottom: ${spacing[2]};
  text-transform: capitalize;
`;

/**
 * Displays any error messages resulting from the input.
 */
export const Error = styled(StatusMessage).attrs({ type: "error" })`
  padding-top: ${spacing[2]};
`;
