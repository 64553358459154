/**
 * Store the Access Token in memory.
 * By storing the token in memory it is more secure than storing it in session or local storage.
 */
let accessToken = "";

/**
 * Returns the Access Token.
 */
export const getAccessToken = () => {
  return accessToken;
};

/**
 * Set the value of the Access Token to the passed value.
 */
export const setAccessToken = (token: string) => {
  accessToken = token;
};

/**
 * Reset the Access Token to an empty string.
 */
export const clearAccessToken = () => {
  accessToken = "";
};
