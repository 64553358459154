import * as React from "react";

import {
  Card,
  FieldGroup,
  Form as FormComponent,
  Text,
  Title as TitleComponent,
} from "../../../components";
import { FormType, MultiSubmitFormType } from "../../../store/types";
import { spacing } from "../../../utils";

/**
 * Creates elements common to all forms.
 */
export function useCommonFormBuilderElements<T>(
  formConfig: FormType<T> | MultiSubmitFormType<T>,
) {
  type FormProps = {
    success?: boolean;
    renderOnSuccess?: React.ReactNode;
  };

  /**
   * Wrapping component for all elements of a form. When the form has been submitted successfully it may
   * display an optional success screen.
   */
  const Form: React.FC<FormProps> = ({
    success,
    renderOnSuccess,
    children,
  }) => {
    // Render a secondary view after the form has been submitted if one is provided
    if (success && renderOnSuccess) {
      return <>{renderOnSuccess}</>;
    }

    return <FormComponent>{children}</FormComponent>;
  };

  /**
   * Displays a message returned from the API to display to the user.
   * If no message has been recieved (for example, if the API has not yet returned a response),
   * then nothing will be displayed.
   */
  const ApiResponseCard: React.FC = () => {
    // Destructure status and message from the saved response
    const [status, message] = formConfig.apiResponseMessage;

    // If there is no message, do not display anything
    if (status === "idle") {
      return null;
    }

    return (
      <Card
        title={status}
        titleAs="h3"
        modifier={status}
        style={{ margin: `${spacing[4]} 0` }}
      >
        <Text>{message}</Text>
      </Card>
    );
  };

  /**
   * Renders all Field Groups.
   */
  const FormGroups: React.FC = () => {
    return (
      <>
        {formConfig.fieldGroups.map((group) => (
          <FieldGroup
            key={group.testId}
            formTestId={formConfig.testId}
            {...group}
          />
        ))}
      </>
    );
  };

  /**
   * Renders the title of the form.
   */
  const Title: React.FC = () => {
    return <TitleComponent.H3>{formConfig.title}</TitleComponent.H3>;
  };

  return {
    ApiResponseCard,
    Form,
    FormGroups,
    Title,
  };
}
