import styled from "styled-components";

import barn from "../../../assets/barn@2x.png";
import { Breakpoint, ZIndex, rootColors, spacing } from "../../../utils";
import { Flexbox } from "../../templates/Flexbox";

// These values are used to ensure that the elements that make up the footer use the same values
// for referencing the component's height at various screen sizes
const MOBILE_HEIGHT = "66px";
const DESKTOP_HEIGHT = "60px";

/**
 * Styled `footer` tag to wrap all Footer content.
 */
export const FooterWrapperOuter = styled.footer`
  /* This style rule in conjunction with the DefaultPageWrapper's height being set to 100vh
     will push the footer to the bottom of the page */
  margin-top: auto;

  /* Display the Barn image just above the footer */
  position: relative;
  &::before {
    content: "";
    background-image: url(${barn});
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-position-x: right;
    width: 100vw;
    height: 100vh;
    display: block;
    position: absolute;
    right: 0;

    /* The bottom value must be hard coded based on the hight of the footer element.
       By setting it to the *exact* height of the footer the Barn rests perfectly on top */
    bottom: ${MOBILE_HEIGHT};
    @media screen and (min-width: ${Breakpoint.Medium}) {
      bottom: ${DESKTOP_HEIGHT};
    }
  }
`;

/**
 * Wraps the visual element of the Footer that exists underneath the Barn.
 */
export const FooterWrapperInner = styled.div`
  position: relative;
  background-color: ${rootColors.footer.background};
  color: ${rootColors.footer.text};
  padding: ${spacing[4]} 0;
  z-index: ${ZIndex.MainContent};
  display: flex;
  align-items: center;

  height: ${MOBILE_HEIGHT};
  @media screen and (min-width: ${Breakpoint.Medium}) {
    height: ${DESKTOP_HEIGHT};
  }
`;

/**
 * Wraps the actual content of the footer,
 * aligns them vertically center and as far to either direction as the screen will allow.
 */
export const FooterContentWrapper = styled(Flexbox).attrs({
  as: "section",
  alignContent: "between",
})``;
