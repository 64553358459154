import styled from "styled-components";
import { Form as FormikForm } from "formik";

/**
 * Overwrites the Form component provided by Formik
 * to display its children in a vertical stack.
 */
export const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
`;
