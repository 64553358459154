import styled from "styled-components";

import { Text } from "../Text";
import { spacing } from "../../../utils";

/**
 * Wrapping tag for the current time to be displayed to the user.
 */
export const ClockFace = styled(Text)`
  padding: ${spacing[4]} 0;
  font-weight: bolder;
  font-size: 22px;
`;
