import * as React from "react";
import {
  faCog,
  faHome,
  faTachometer,
  faUserHeadset,
} from "@fortawesome/pro-solid-svg-icons";

import { Hamburger } from "../../atoms/Hamburger";
import { Icon } from "../../atoms/Icon";
import {
  NavItem,
  NavLink,
  NavMenuList,
  NavMenuWrapper,
  NavText,
} from "./NavMenu.style";
import { Routes } from "../../../constants/routes";
import { UserRole, useCurrentUserQuery } from "../../../generated/graphql";
import { buttonColors } from "../../../utils";

/**
 * The Nav menu contained within the Footer of the app.
 */
export const NavMenu: React.FC = () => {
  const { data } = useCurrentUserQuery();
  const user = data?.currentUser.payload.user;

  // Open and closed state of the mobile navigation menu
  const [isNavMenuOpen, setIsNavMenuOpen] = React.useState(false);

  // Toggles the state to be the opposite of its current (now previous) state
  const toggleNavMenu = () => {
    setIsNavMenuOpen((previousState) => !previousState);
  };

  // Nav items contained within the Nav menu
  const items = [
    {
      icon: faHome,
      to: Routes.Home,
      text: "Home",
    },
    {
      icon: faCog,
      to: Routes.Settings,
      text: "Settings",
    },
  ];

  // If a user has logged in they should be allowed to file support tickets
  if (user) {
    items.push({
      icon: faUserHeadset,
      to: Routes.Support,
      text: "Support",
    });
  }

  // If the user has sufficient permissions, display the route to the monitoring dashboard
  if (user?.role === UserRole.Admin) {
    items.push({
      icon: faTachometer,
      to: Routes.Dashboard,
      text: "Monitor",
    });
  }

  return (
    <NavMenuWrapper>
      <Hamburger toggled={isNavMenuOpen} onClick={toggleNavMenu} />

      <NavMenuList toggled={isNavMenuOpen}>
        {items.map((item) => (
          <NavItem key={item.to}>
            <NavLink to={item.to} onClick={toggleNavMenu}>
              <Icon
                size="small"
                icon={item.icon}
                backgroundColor={buttonColors.primary.hover.background}
              />
              <NavText>{item.text}</NavText>
            </NavLink>
          </NavItem>
        ))}
      </NavMenuList>
    </NavMenuWrapper>
  );
};
