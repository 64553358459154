import * as React from "react";
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";

import {
  HiddenRadioButton,
  Icon,
  Label,
  RadioInputWrapper,
  StyledRadioButton,
} from "./RadioInput.style";
import { SelectionOption } from "../../../store/types";

type Props = {
  inputName: string;
  checked: boolean;
  onChange: () => void;
} & SelectionOption;

/**
 * A styled Radio Button with a label to be used instead of the default HTML Radio input.
 */
export const RadioInput: React.FC<Props> = ({
  inputName,
  name,
  value,
  checked,
  onChange,
  disabled,
}) => {
  return (
    <RadioInputWrapper htmlFor={name}>
      <HiddenRadioButton
        id={name}
        onChange={onChange}
        checked={checked}
        name={inputName}
        value={value}
        disabled={disabled}
      />

      <StyledRadioButton checked={checked} onClick={onChange}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledRadioButton>

      <Label>{name}</Label>
    </RadioInputWrapper>
  );
};
