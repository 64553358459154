import styled, { css } from "styled-components";

import {
  ZIndex,
  buttonColors,
  elevation,
  formColors,
  rounded,
  spacing,
  transition,
} from "../../../utils";

/**
 * Wraps the input component of the Combobox.
 */
export const ComboboxWrapper = styled.div`
  background-color: ${formColors.input.background};
  color: ${buttonColors.primary.text};
  display: flex;
  ${elevation[0]};
  border-radius: ${rounded};
  overflow: hidden;

  > * {
    padding: ${spacing[2]} ${spacing[4]};
    border: none;
    font-size: 16px;
    background: transparent;
  }
`;

/**
 * Input for users to narrow the scope of items displayed in the dropdown menu.
 */
export const Field = styled.input.attrs({ type: "text" })`
  width: 100%;
  text-transform: capitalize;
`;

/**
 * Wraps the icon indicating if the menu is open or closed.
 */
export const ToggleMenuButtonLabel = styled.div<{ menuIsOpen: boolean }>`
  ${(props) =>
    props.menuIsOpen
      ? "transform: rotate(180deg);"
      : "transform: rotate(0deg);"}
  ${transition({ property: "transform" })};
`;

/**
 * Button for opening and closing the menu.
 */
export const ToggleMenuButton = styled.button.attrs({ type: "button" })`
  border: none;
  cursor: pointer;
  ${transition({})};

  &:hover {
    background: ${buttonColors.primary.background};
    color: ${buttonColors.primary.text};
  }
`;

/**
 * Wraps the dropdown menu to ensure it is displayed over other content.
 */
export const DropdownWrapper = styled.div`
  position: relative;
  z-index: ${ZIndex.MainContent_DropdownMenu};
`;

/**
 * The dropdown menu containing items the user can select from.
 */
export const Dropdown = styled.ul<{ isOpen: boolean }>`
  text-transform: capitalize;
  font-size: 16px;
  list-style: none;
  overflow-y: scroll;
  position: absolute;
  width: 100%;
  background-color: ${formColors.input.background};
  color: ${formColors.input.text};
  ${elevation[2]};
  border-radius: ${rounded};
  max-height: 0px;
  ${transition({ property: "max-height" })};

  > li {
    display: none;
  }

  ${(props) =>
    props.isOpen &&
    css`
      max-height: 250px;

      > li {
        display: block;
      }
    `}

  &:focus {
    outline: none;
  }
`;

/**
 * An individual item within the dropdown list.
 */
export const Option = styled.li<{
  highlighted: boolean;
  disabled: boolean;
}>`
  cursor: pointer;
  padding: ${spacing[2]} ${spacing[4]};

  ${(props) =>
    props.highlighted &&
    css`
      color: ${buttonColors.primary.text};
      background-color: ${buttonColors.primary.background};
    `}

  ${(props) =>
    props.disabled &&
    css`
      text-align: center;
    `}
`;
