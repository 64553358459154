import * as React from "react";
import { Field } from "formik";

import {
  Props as DisplayProps,
  TextInput as TextInputDisplay,
} from "./TextInput.display";

type Props = {} & DisplayProps;

/**
 * A TextInput wrapped in a Formik Field for use in forms controlled by Formik.
 */
export const TextInput: React.FC<Props> = ({ ...props }) => {
  return <Field as={TextInputDisplay} {...props} />;
};
