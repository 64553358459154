import styled from "styled-components";

import { spacing } from "../../../utils";

/**
 * Wraps the buttons of the numpad.
 */
export const NumpadWrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-gap: ${spacing[4]};
`;
