import * as React from "react";
import * as Yup from "yup";
import { faClock } from "@fortawesome/pro-solid-svg-icons";

import {
  CreateOutPunchInput,
  useCreateOutPunchMutation,
  useCurrentUserQuery,
} from "../../../../generated/graphql";
import {
  FieldType,
  FormConfigProps,
  FormType,
  StatusType,
} from "../../../../store/types";
import { usePunchFormSubmissionCleanup } from "../../../../hooks";

/**
 * Memoized configuration object for the Punch Out Form to reduce
 * unneccessary rerenders.
 */
export function usePunchOutFormConfig({
  setDidSubmitSuccessfully,
}: FormConfigProps): FormType<CreateOutPunchInput> {
  // User Information query is called to get the user's id and department
  const { data: userInformationData } = useCurrentUserQuery();
  const user = userInformationData?.currentUser.payload.user;

  // The createOutPunch function will call the mutation to create the punch using the data entered into the form
  const [createOutPunch] = useCreateOutPunchMutation();

  // The cleanup function will log a user out or return them to the home screen
  // depending on their settings
  const { delayedCleanup } = usePunchFormSubmissionCleanup();

  // State value to store error and success response messages
  const [apiResponseMessage, setApiResponseMessage] = React.useState<
    [StatusType, string]
  >(["idle", ""]);

  return React.useMemo(() => {
    return {
      apiResponseMessage,
      fieldGroups: [
        {
          testId: "punchInformation",
          title: "Punch Information",
          fields: [
            {
              name: "comment",
              required: false,
              type: "textbox",
            } as FieldType,
          ],
        },
      ],
      initialValues: {
        userId: user?.id || "",
        comment: "",
      },
      onSubmit: async (values) => {
        try {
          // Clock the user out
          // Note: This request should never fail because it requires no direct input from the user
          // to execute a successful punch
          const { data } = await createOutPunch({
            variables: {
              data: values,
            },
          });

          // Ensure data was returned from the API
          if (data) {
            // Display the resulting message to the user
            setApiResponseMessage(["success", data.createOutPunch.message]);

            // Set successful submit state to true to update UI
            setDidSubmitSuccessfully(true);

            // Perform all cleanup duties based on the user's settings
            await delayedCleanup();
          }
        } catch (error) {
          // If an error occurs display the message to the user
          setApiResponseMessage(["error", error.message]);
        }
      },
      submitButton: {
        icon: faClock,
        label: "Clock Out",
      },
      testId: "clockOutForm",
      title: "Clock Out",
      validationSchema: Yup.object().shape<CreateOutPunchInput>({
        userId: Yup.string().required(),
        comment: Yup.string(),
      }),
    };
  }, [
    apiResponseMessage,
    user?.id,
    createOutPunch,
    setDidSubmitSuccessfully,
    delayedCleanup,
  ]);
}
