import * as React from "react";
import {
  faHourglassStart,
  faInbox,
  faRedo,
  faShield,
  faTable,
} from "@fortawesome/pro-solid-svg-icons";

import { Card, Icon, StatusMessage } from "../../atoms";
import { Flexbox } from "../../templates";
import { LineItem } from "../../molecules";
import {
  MatchmakerCreateQueue,
  MatchmakerUpdateQueue,
  OvertimeCalculatorQueue,
  QueueName,
  QueueState,
} from "../../../generated/graphql";
import { convertMsToSeconds, convertStatusToColor } from "../../../utils";
import { getHeaderIconByState } from "./utils/getHeaderIconByState";
import { getStatusByJobDuration } from "./utils/getStatusByJobDuration";
import { getStatusByNumberOfJobs } from "./utils/getStatusByNumberOfJobs";
import { getStatusByNumberOfRetries } from "./utils/getStatusByNumberOfRetries";
import { getStatusByState } from "./utils/getStatusByState";

type Props = {
  currentQueue?: QueueName;
} & (MatchmakerCreateQueue | OvertimeCalculatorQueue | MatchmakerUpdateQueue);

/**
 * Displays information about the health and status of an individual queue within the system.
 */
export const QueueCard: React.FC<Props> = ({
  currentQueue,
  name,
  retryCounter,
  numberOfJobsInQueue,
  rowsInSmartsheetTable,
  state,
  previousJobDuration,
  averageDayJobDuration,
  averageWeekJobDuration,
  jobsCompletedToday,
  jobsCompletedThisWeek,
}) => {
  return (
    <Card titleAs="h3" title={name}>
      <Flexbox alignContent="center">
        <Icon
          backgroundColor={convertStatusToColor(getStatusByState(state))}
          icon={getHeaderIconByState(state)}
          spin={currentQueue === name && state === QueueState.ProcessingJob}
        />
      </Flexbox>

      <section>
        <LineItem
          label="State"
          content={state}
          icon={faShield}
          iconBackgroundColor={convertStatusToColor("info")}
        />
      </section>

      <section>
        <LineItem
          label="Retry Counter"
          content={
            <StatusMessage
              as="span"
              type={getStatusByNumberOfRetries(retryCounter)}
            >
              {retryCounter}
            </StatusMessage>
          }
          icon={faRedo}
          iconBackgroundColor={convertStatusToColor(
            getStatusByNumberOfRetries(retryCounter),
          )}
        />
      </section>

      <section>
        <LineItem
          label="Jobs in Queue"
          content={
            <StatusMessage
              as="span"
              type={getStatusByNumberOfJobs(numberOfJobsInQueue)}
            >
              {numberOfJobsInQueue}
            </StatusMessage>
          }
          icon={faInbox}
          iconBackgroundColor={convertStatusToColor(
            getStatusByNumberOfJobs(numberOfJobsInQueue),
          )}
        />

        <LineItem
          label="Jobs Completed Today"
          content={
            <StatusMessage as="span" type="success">
              {jobsCompletedToday ? jobsCompletedToday.length : 0}
            </StatusMessage>
          }
          icon={faInbox}
          iconBackgroundColor={convertStatusToColor("success")}
        />

        <LineItem
          label="Jobs Completed This Week"
          content={
            <StatusMessage as="span" type="success">
              {jobsCompletedThisWeek ? jobsCompletedThisWeek.length : 0}
            </StatusMessage>
          }
          icon={faInbox}
          iconBackgroundColor={convertStatusToColor("success")}
        />
      </section>

      <section>
        <LineItem
          label="Previous Job Duration"
          content={
            <StatusMessage
              as="span"
              type={getStatusByJobDuration(previousJobDuration)}
            >
              {convertMsToSeconds(previousJobDuration)}
            </StatusMessage>
          }
          icon={faHourglassStart}
          iconBackgroundColor={convertStatusToColor(
            getStatusByJobDuration(previousJobDuration),
          )}
        />

        <LineItem
          label="Average Daily Duration"
          content={
            <StatusMessage
              as="span"
              type={getStatusByJobDuration(averageDayJobDuration)}
            >
              {convertMsToSeconds(averageDayJobDuration)}
            </StatusMessage>
          }
          icon={faHourglassStart}
          iconBackgroundColor={convertStatusToColor(
            getStatusByJobDuration(averageDayJobDuration),
          )}
        />

        <LineItem
          label="Average Weekly Duration"
          content={
            <StatusMessage
              as="span"
              type={getStatusByJobDuration(averageWeekJobDuration)}
            >
              {convertMsToSeconds(averageWeekJobDuration)}
            </StatusMessage>
          }
          icon={faHourglassStart}
          iconBackgroundColor={convertStatusToColor(
            getStatusByJobDuration(averageWeekJobDuration),
          )}
        />
      </section>

      <section>
        <LineItem
          label="Rows in Smartsheet"
          content={
            <StatusMessage
              as="span"
              type={getStatusByJobDuration(previousJobDuration)}
            >
              {rowsInSmartsheetTable}
            </StatusMessage>
          }
          icon={faTable}
          iconBackgroundColor={convertStatusToColor(
            getStatusByJobDuration(previousJobDuration),
          )}
        />
      </section>
    </Card>
  );
};
