import * as React from "react";

import { TEN_MINUTES } from "../constants/timeValues";
import { api } from "../api";
import { setAccessToken } from "../store/accessToken";

/**
 * Calls the REST API to offer a Refresh token and recieve
 * a fresh set of Access and Refresh Tokens for the user.
 * If a user does not have a Refresh Token or the Token is expired
 * the request will not return tokens  and the user will need to reauthenticate.
 *
 * Returns a boolean value that reflects if the process has been completed.
 */
export function useAuthentication() {
  // Indicates if the Authentication checking process has been completed
  const [didCheckAuth, setDidCheckAuth] = React.useState(false);

  const getFreshTokens = async () => {
    // Request a new set of tokens from the API
    const { payload } = await api.refreshTokens();

    if (payload) {
      // If the response includes a Token the request was successful
      // and the new Token should be stored so it may be sent to allow privilaged access
      // on future requests
      // If the response was not successful it will return an empty string instead of a token
      setAccessToken(payload);
    }

    // After the request has been completed set the state to `true`
    // to indicate the process has been completed
    setDidCheckAuth(true);
  };

  // This effect will rerun every ten minutes to ensure the user's Access token never expires
  React.useEffect(() => {
    (async () => {
      await getFreshTokens();
    })();

    const interval = setInterval(getFreshTokens, TEN_MINUTES);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // Return the status of the Authentication checking proccess
  return didCheckAuth;
}
