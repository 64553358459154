import * as React from "react";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

import { Link } from "../Link";
import {
  buttonColors,
  elevation,
  roleColors,
  rounded,
  spacing,
  transition,
} from "../../../utils";

type Props = {
  level?: "primary" | "secondary" | "tertiary";
  label?: string;
  type?: "button" | "reset" | "submit";
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  spin?: boolean;
  icon?: IconDefinition;
  rightSideIcon?: IconDefinition;
  floating?: boolean;
  dataValue?: any;
  linkTo?: string;
};

/**
 * Common button element used throughout the app.
 * Semantic hierarchy consists of primary, secondary, and tertiary.
 */
export const Button = styled(
  ({
    label,
    icon,
    rightSideIcon,
    dataValue,
    onClick,
    linkTo,
    type = "button",
    disabled = false,
    spin = false,
    ...rest
  }: Props) => {
    // Optionally a component can visually appear as a button
    // but require the functionality of a link
    if (linkTo) {
      return (
        <Link to={linkTo} {...rest}>
          {icon && (
            <FontAwesomeIcon
              spin={spin}
              icon={icon}
              style={{ marginRight: spacing[2] }}
            />
          )}
          {label}
          {rightSideIcon && (
            <FontAwesomeIcon
              spin={spin}
              icon={rightSideIcon}
              style={{ marginLeft: spacing[2] }}
            />
          )}
        </Link>
      );
    }

    return (
      /* eslint-disable react/button-has-type -- The button type is provided by a prop that will default to "button" */
      <button
        type={type}
        disabled={disabled}
        data-value={dataValue}
        onClick={onClick}
        {...rest}
      >
        {icon && (
          <FontAwesomeIcon
            spin={spin}
            icon={icon}
            style={{ marginRight: spacing[2] }}
          />
        )}
        {label}
        {rightSideIcon && (
          <FontAwesomeIcon
            spin={spin}
            icon={rightSideIcon}
            style={{ marginLeft: spacing[2] }}
          />
        )}
      </button>
      /* eslint-enable react/button-has-type */
    );
  },
)<Props>`
  /* Adjust the colors of the button based on which level of priority the button has within the UI */
  ${(props) => {
    switch (props.level) {
      case "secondary":
        return css`
          --textColor: ${buttonColors.secondary.text};
          --borderColor: ${buttonColors.secondary.border};
          --backgroundColor: ${buttonColors.secondary.background};

          &:focus,
          &:hover {
            --textColor: ${buttonColors.secondary.hover.text};
            --backgroundColor: ${buttonColors.secondary.hover.background};
            --borderColor: ${buttonColors.secondary.hover.border};
          }
        `;

      case "tertiary":
        return css`
          --backgroundColor: ${buttonColors.tertiary.background};
          --textColor: ${buttonColors.tertiary.text};
          --borderColor: ${buttonColors.tertiary.border};

          &:focus,
          &:hover {
            --backgroundColor: ${buttonColors.tertiary.hover.background};
          }
        `;

      case "primary":
      default:
        return css`
          --textColor: ${buttonColors.primary.text};
          --backgroundColor: ${buttonColors.primary.background};
          --borderColor: ${buttonColors.primary.border};

          &:focus,
          &:hover {
            --backgroundColor: ${buttonColors.primary.hover.background};
            --borderColor: ${buttonColors.primary.hover.background};
          }
        `;
    }
  }}

  border: 2px solid var(--borderColor);
  color: var(--textColor);
  background-color: var(--backgroundColor);
  display: inline-block;

  padding: ${spacing[2]} ${spacing[4]};
  text-transform: capitalize;
  cursor: pointer;
  border-radius: ${rounded};
  font-size: 16px;
  text-decoration: none;
  text-align: center;
  ${transition({})};

  ${(props) => props.floating && elevation[2]};

  /* If the button is disabled, overwrite the existing colors with disabled button styles */
  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
      --textColor: ${roleColors.disabled.text};
      --backgroundColor: ${roleColors.disabled.background};
      --borderColor: transparent;
      ${elevation[0]};

      &:hover {
        --textColor: ${roleColors.disabled.text};
        --backgroundColor: ${roleColors.disabled.background};
        --borderColor: transparent;
      }
    `}

  &:focus:enabled {
    outline: none;
  }
`;
