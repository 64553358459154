import * as React from "react";

import { FormType, MultiSubmitFormType } from "../../../store/types";

/**
 * Tracks the user's current progress as they interact with a form that
 * uses a MultiStep UI.
 */
export function useMultiStepFormProgressTracking<T>(
  formConfig: FormType<T> | MultiSubmitFormType<T>,
) {
  // Track the current group of fields the user is interacting with
  const [currentGroup, setCurrentGroup] = React.useState(0);

  // Moves the form to the next page of fields
  const goToNextPage = () => {
    setCurrentGroup((current) => current + 1);
  };

  // Moves the user to the previous page of fields
  const goToPreviousPage = () => {
    setCurrentGroup((current) => current - 1);
  };

  // Will return true if the user is currently viewing the first page of fields
  const isFirstStepOfForm = currentGroup === 0;

  // Will return true if the user is currently viewing the last page of fields
  const isLastStepOfForm = currentGroup >= formConfig.fieldGroups.length - 1;

  return {
    currentGroup,
    goToNextPage,
    goToPreviousPage,
    isFirstStepOfForm,
    isLastStepOfForm,
  };
}
