import * as React from "react";

import { Description, FieldGroupWrapper, Title } from "./FieldGroup.style";
import { FieldGroupType } from "../../../store/types";
import { InputController } from "../InputController";

type Props = {
  formTestId: string;
} & FieldGroupType;

/**
 * Wraps multiple Fields together to create clear visual organization.
 */
export const FieldGroup: React.FC<Props> = ({
  formTestId,
  title,
  description,
  fields,
}) => {
  return (
    <FieldGroupWrapper>
      <Title>{title}</Title>

      {description && <Description>{description}</Description>}

      {fields.map((field) => (
        <InputController key={field.name} formTestId={formTestId} {...field} />
      ))}
    </FieldGroupWrapper>
  );
};
