import styled from "styled-components";

import { spacing } from "../../../utils";

/**
 * Hierarchy of titles.
 * Note: No H1 is included as that element is reserved for the main page title located in the header
 * and each page should have no more than one H1.
 */
export const Title = {
  H2: styled.h2`
    text-transform: capitalize;
  `,
  H3: styled.h3`
    text-transform: capitalize;
    font-size: ${spacing[6]};
  `,
  H4: styled.h4`
    text-transform: capitalize;
  `,
  H5: styled.h5`
    text-transform: capitalize;
  `,
  H6: styled.h6`
    text-transform: capitalize;
  `,
};
