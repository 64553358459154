import styled from "styled-components";

import { spacing } from "../../../utils";

/**
 * Wraps a single line of data displayed on the card.
 */
export const LineItemWrapper = styled.article`
  display: flex;
  align-items: center;
  padding: ${spacing[4]} 0;
`;

/**
 * Provides horizontal spacing on the right side of an icon.
 */
export const IconWrapper = styled.div`
  padding-right: ${spacing[2]};
`;
