import * as React from "react";

import { Card, DefaultPageLayout } from "../../components";
import { PunchOutForm } from "../../containers";
import { useDocumentTitle } from "../../hooks";

/**
 * Displays the Punch Out form to allow users to clock out.
 */
export const PunchOut: React.FC = () => {
  useDocumentTitle("Clock Out");

  return (
    <DefaultPageLayout>
      <Card title="Clock Out">
        <PunchOutForm />
      </Card>
    </DefaultPageLayout>
  );
};
