import * as React from "react";
import { MutationTuple } from "@apollo/client";
import {
  faBriefcase,
  faFarm,
  faSync,
  faUser,
} from "@fortawesome/pro-solid-svg-icons";

import { Button, Card, Text } from "../../atoms";
import { ButtonGroup, Flexbox } from "../../templates";
import {
  Exact,
  RefreshSmartsheetCachesMutation,
} from "../../../generated/graphql";
import { LineItem } from "../../molecules";
import { convertStatusToColor } from "../../../utils";

type Props = {
  numberOfUsers: number;
  numberOfClients: number;
  numberOfTasks: number;
  refreshSmartsheetCachesMutation: MutationTuple<
    RefreshSmartsheetCachesMutation,
    Exact<{
      [key: string]: never;
    }>
  >;
};

/**
 * Displays the number of each type of entity stored in the cache. Used on the monitoring dashboard.
 */
export const DataCacheCard: React.FC<Props> = ({
  numberOfUsers,
  numberOfClients,
  numberOfTasks,
  refreshSmartsheetCachesMutation,
}) => {
  const [
    refreshSmartsheetCaches,
    { loading: isLoading },
  ] = refreshSmartsheetCachesMutation;
  return (
    <Card title="Data Cache">
      <Text>
        User, Client, and Task entities are fetched from Smartsheet on a fixed
        schedule (Monday through Friday, at 06:00, 10:00, and 14:00) to limit
        requests sent to the Smartsheet API.
      </Text>

      <Flexbox alignContent="around">
        <LineItem
          label="Users"
          content={numberOfUsers}
          icon={faUser}
          iconBackgroundColor={convertStatusToColor(
            numberOfUsers !== 0 ? "info" : "error",
          )}
        />

        <LineItem
          label="Clients"
          content={numberOfClients}
          icon={faFarm}
          iconBackgroundColor={convertStatusToColor(
            numberOfClients !== 0 ? "info" : "error",
          )}
        />

        <LineItem
          label="Tasks"
          content={numberOfTasks}
          icon={faBriefcase}
          iconBackgroundColor={convertStatusToColor(
            numberOfTasks !== 0 ? "info" : "error",
          )}
        />
      </Flexbox>

      <ButtonGroup>
        <Button
          icon={faSync}
          spin={isLoading}
          label="Refresh Smartsheet Cache"
          disabled={isLoading}
          onClick={async () => {
            await refreshSmartsheetCaches();
          }}
        />
      </ButtonGroup>
    </Card>
  );
};
