import * as React from "react";

import {
  BugReportForm,
  FeatureRequestForm,
  GeneralFeedbackForm,
} from "../../containers";
import { Card, DefaultPageLayout, RadioPicker } from "../../components";
import { useDocumentTitle } from "../../hooks";

/**
 * Renders a set of radio buttons, each which render a different form
 * the user can fill out to contact the development team.
 */
export const SupportForms: React.FC = () => {
  useDocumentTitle("Support");

  return (
    <DefaultPageLayout>
      <Card title="Support">
        <RadioPicker
          options={[
            {
              radio: {
                name: "General Feedback",
                value: "generalFeedback",
              },
              component: GeneralFeedbackForm,
            },
            {
              radio: {
                name: "Bug Report",
                value: "bugReport",
              },
              component: BugReportForm,
            },
            {
              radio: {
                name: "Feature Request",
                value: "featureRequest",
              },
              component: FeatureRequestForm,
            },
          ]}
        />
      </Card>
    </DefaultPageLayout>
  );
};
