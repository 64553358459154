import * as React from "react";

import { Card, DefaultPageLayout } from "../../components";
import { NewTaskForm } from "../../containers";
import { useDocumentTitle } from "../../hooks";

/**
 * Displays the New Task form to allow users to change their task.
 */
export const NewTask: React.FC = () => {
  useDocumentTitle("Change Task");

  return (
    <DefaultPageLayout>
      <Card title="Change Task">
        <NewTaskForm />
      </Card>
    </DefaultPageLayout>
  );
};
