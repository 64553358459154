import { brandColors } from "./brandColors";
import { gray, green, indigo, red, yellow } from "./genericColors";

/**
 * Named (Role) Colors
 *
 * Named colors reference generic colors and describe their role in the app. It is acceptable if multiple
 * named colors may reference the same generic color, but they should be distinct from one another whenever possible.
 */
export const roleColors = {
  disabled: {
    background: gray[500],
    text: gray[700],
  },
  error: red[700],
  success: green[700],
  info: indigo[500],
  unused: brandColors.gray[500],
  warning: yellow[500],
};
