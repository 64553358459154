import * as React from "react";

import { Card, DefaultPageLayout } from "../../components";
import { LoginForm } from "../../containers";
import { useDocumentTitle } from "../../hooks";

/**
 * Displays the login form to allow users to enter the app.
 */
export const Login: React.FC = () => {
  useDocumentTitle("Login");

  return (
    <DefaultPageLayout>
      <Card title="Login">
        <LoginForm />
      </Card>
    </DefaultPageLayout>
  );
};
