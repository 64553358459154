import * as React from "react";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";

import { Button } from "../../../../components";
import { useSupportAndOverrideFormSubmissionCleanup } from "../../../../hooks";

/**
 * A button that, when clicked, will return the user to the home screen.
 */
export const SupportAndOverrideFormCleanupButton: React.FC = () => {
  const { cleanup } = useSupportAndOverrideFormSubmissionCleanup();

  return (
    <Button
      label="Return to Home Screen"
      icon={faArrowLeft}
      onClick={cleanup}
    />
  );
};
