import styled, { css } from "styled-components";

import { ZIndex, spacing, transition } from "../../../utils";

// Style props are exported so they may be included in the component's API
export type IconStyleProps = {
  backgroundColor: string;
  inline?: boolean;
  size?: "extra-small" | "small" | "medium" | "large" | "extra-large";
};

/**
 * Colored field on which the icon will be displayed
 */
export const IconWrapper = styled.div<IconStyleProps>`
  /* Allow for icons to be used inline with text */
  ${(props) =>
    props.inline &&
    css`
      display: inline-block;
    `}

  /* Adjust the size of the element */
  ${(props) => {
    switch (props.size) {
      case "extra-large":
        return css`
          --size: ${spacing[32]};
        `;

      case "large":
        return css`
          --size: ${spacing[24]};
        `;

      case "small":
        return css`
          --size: ${spacing[7]};
        `;

      case "extra-small":
        return css`
          --size: ${spacing[6]};
        `;

      // By default an Icon will be of `Medium` size
      case "medium":
      default:
        return css`
          --size: ${spacing[12]};
        `;
    }
  }}

  background-color: ${(props) => props.backgroundColor};
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  border-radius: 50%;
  /* The width, height, and line-height all use the exact value determined by the size prop switch
     and the font-size will be exactly half that value */
  width: var(--size);
  height: var(--size);
  line-height: var(--size);
  font-size: calc(var(--size) / 2);
  z-index: ${ZIndex.MainContent};
  ${transition({})};
`;
