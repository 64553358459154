import * as React from "react";

import { Card, DefaultPageLayout } from "../../components";
import { PunchInForm } from "../../containers";
import { useDocumentTitle } from "../../hooks";

/**
 * Displays the Punch In form to allow users to clock in.
 */
export const PunchIn: React.FC = () => {
  useDocumentTitle("Clock In");

  return (
    <DefaultPageLayout>
      <Card title="Clock In">
        <PunchInForm />
      </Card>
    </DefaultPageLayout>
  );
};
