import styled from "styled-components";

import { elevation, formColors, rounded, spacing } from "../../../utils";

/**
 * Styles to be used for text inputs.
 */
export const StyledInput = styled.input`
  padding: ${spacing[2]};
  border: none;
  ${elevation[0]};
  border-radius: ${rounded};
  background-color: ${formColors.input.background};
  color: ${formColors.input.text};
  /* Setting the font-size to 16px will prevent zooming in on mobile devices when the input focuses */
  font-size: 16px;
`;
