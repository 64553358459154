import * as React from "react";

import { Button } from "../../components";
import { FormType } from "../../store/types";
import { useCommonFormBuilderElements } from "./utils/useCommonFormBuilderElements";
import { useMultiStepFormBuilderElements } from "./utils/useMultiStepFormBuilderElements";

/**
 * Accepts a form configuration object and returns components generated
 * using that configuration to create the Form UI.
 */
export function useFormBuilder<T>(formConfig: FormType<T>) {
  // Use form elements common to all forms
  const commonFormElements = useCommonFormBuilderElements(formConfig);

  // Create elements to be used if the form requires a multistep UI
  const multiStepFormElements = useMultiStepFormBuilderElements(formConfig);

  /**
   * Renders a button to submit the form.
   */
  const SubmitButton: React.FC<{ disabled: boolean }> = ({ disabled }) => {
    return (
      <Button
        type="submit"
        disabled={disabled}
        data-testid={`${formConfig.testId}SubmitButton`}
        {...formConfig.submitButton}
      />
    );
  };

  return {
    ...commonFormElements,
    SubmitButton,
  };
}
