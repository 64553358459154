import * as React from "react";
import * as Yup from "yup";
import { faDoorOpen } from "@fortawesome/pro-solid-svg-icons";
import { useHistory } from "react-router-dom";

import { Errors } from "../../../constants/errors";
import { FormType, StatusType } from "../../../store/types";
import {
  LoginInput,
  useCurrentUserLazyQuery,
  useLoginMutation,
} from "../../../generated/graphql";
import { ONE_SECOND } from "../../../constants/timeValues";
import { Routes } from "../../../constants/routes";
import {
  UserSettingsActionTypes as Types,
  useUserSettingsContext,
} from "../../../store/contexts/userSettings";
import { setAccessToken } from "../../../store/accessToken";

/**
 * Memoized configuration object for the Login Form to reduce
 * unneccessary rerenders.
 */
export function useLoginFormConfig(): FormType<LoginInput> {
  // Login function will call mutation to be sent to API
  const [login] = useLoginMutation();

  // Information about the current user should be refetched after a successful login
  const [getCurrentUser, { data }] = useCurrentUserLazyQuery();

  // The User Settings context will be accessed to hide the first time login toast if it is displayed
  const { dispatch, userSettings } = useUserSettingsContext();

  // Access history to redirect user on successful login
  const history = useHistory();

  // State value to store error and success response messages
  const [apiResponseMessage, setApiResponseMessage] = React.useState<
    [StatusType, string]
  >(["idle", ""]);

  // Both side effects will run after the form is submitted and the Current User query has resolved
  // by monitoring the `data` property
  React.useEffect(() => {
    // If there is no data the query has not resolved yet and no further actions should be taken
    if (!data) {
      // Note: This cleanup function does not perform any tasks, however it prevents an eslint conflict
      return () => {};
    }

    // Delay by one second to give user time to read success message
    // then redirect the user to the root of the app
    const timeout = setTimeout(() => {
      history.push(Routes.Home);
    }, ONE_SECOND);

    // Clean up the hook by clearing the timeout
    // Note: This protects against a user manually leaving the page before the timeout has resolved
    return () => {
      clearTimeout(timeout);
    };
  }, [data, dispatch, history]);

  React.useEffect(() => {
    // If there is no data the query has not resolved yet and no further actions should be taken
    if (!data) {
      return;
    }

    // If the first time login toast is currently being displayed hide it
    if (userSettings.shouldFirstTimeLoginToastBeDisplayed) {
      dispatch({
        type: Types.HideFirstTimeLoginToast,
      });
    }
  }, [data, dispatch, userSettings.shouldFirstTimeLoginToastBeDisplayed]);

  // Return memoized configuration to avoid
  // reinstantiating unnecessarily
  return React.useMemo(() => {
    return {
      apiResponseMessage,
      initialValues: {
        pin: "",
        doesUserAcceptCookies: false,
      },
      fieldGroups: [
        {
          title: "Pin",
          testId: "pinInformation",
          fields: [
            {
              name: "pin",
              required: true,
              type: "number",
            },
            {
              name: "doesUserAcceptCookies",
              required: false,
              type: "checkbox",
            },
          ],
        },
      ],
      onSubmit: async (values, helpers) => {
        try {
          // Attempt login using provided user input
          const response = await login({
            variables: {
              data: values,
            },
          });

          // Confirm data was returned from the API
          if (response.data) {
            // Destructure the payload and message from the DTO
            const { payload, message } = response.data.login;

            // Display the response message to the user
            setApiResponseMessage(["success", message]);

            // Save the Access Token in memory to
            // enable privilaged access for secure information
            setAccessToken(payload.accessToken!);

            // If the user checked the box indicating they would like to stay logged in
            // toggle the setting
            if (values.doesUserAcceptCookies) {
              dispatch({
                type: Types.ToggleShouldLoginPersist,
              });
            }

            // Fetch information about the current user
            getCurrentUser();
          }
        } catch (error) {
          // If an error occurs display the message to the user
          setApiResponseMessage(["error", error.message]);

          // Clear out the form to give the user a clean slate
          // to reattempt login from
          helpers.resetForm();
        }
      },
      submitButton: {
        icon: faDoorOpen,
        label: "Login",
      },
      testId: "loginForm",
      title: "Login",
      validationSchema: Yup.object().shape<LoginInput>({
        pin: Yup.string().required(Errors.PinIsRequired),
        doesUserAcceptCookies: Yup.bool().required(),
      }),
    };
  }, [apiResponseMessage, dispatch, getCurrentUser, login]);
}
