import styled from "styled-components";

import { Flexbox } from "../../../components";
import { spacing } from "../../../utils";

/**
 * Wraps the Login inputs to provide vertical spacing.
 */
export const InputWrapper = styled(Flexbox).attrs({
  direction: "top-down",
})`
  padding: ${spacing[4]} 0;
`;
