import * as React from "react";

import { DefaultPageLayout } from "../../components";
import { WelcomeScreen } from "../../containers";
import { useDocumentTitle } from "../../hooks";

/**
 * The home page of the app, provides one click access to core tasks to speed up user interaction
 */
export const Home: React.FC = () => {
  useDocumentTitle();

  return (
    <DefaultPageLayout>
      <WelcomeScreen />
    </DefaultPageLayout>
  );
};
