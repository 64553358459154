import * as React from "react";
import * as Yup from "yup";

import {
  CreateInPunchInput,
  LocationId,
  useCreateInPunchMutation,
  useCurrentUserQuery,
} from "../../../../generated/graphql";
import { Errors } from "../../../../constants/errors";
import {
  FormConfigProps,
  MultiSubmitFormType,
  SelectionFieldType,
  StatusType,
} from "../../../../store/types";
import { TaskFilteringToggleCheckbox } from "../../common/TaskFilteringToggleCheckbox";
import { locationIdButtons } from "../../../../utils";
import {
  useClientsDropdownValues,
  useFormValueAutoFilling,
  usePunchFormSubmissionCleanup,
  useTasksDropdownValues,
} from "../../../../hooks";

/**
 * Memoized configuration object for the Punch In Form to reduce
 * unneccessary rerenders.
 */
export function usePunchInFormConfig({
  setDidSubmitSuccessfully,
}: FormConfigProps): MultiSubmitFormType<CreateInPunchInput> {
  // User Information query is called to get the user's id and department
  const { data: userInformationData } = useCurrentUserQuery();
  const user = userInformationData?.currentUser.payload.user;

  // The createInPunch function will call the mutation to create the punch using the data entered into the form
  const [createInPunch] = useCreateInPunchMutation();

  // Clients and Tasks are requested to populate dropdown menus
  const { clientIds, clientsDropdownValues } = useClientsDropdownValues();
  const { taskIds, tasksDropdownValues } = useTasksDropdownValues();

  // Get the initial value of the clientId field based on the user's recent punch and their department's permissions
  const { initialClientId, initialTaskId } = useFormValueAutoFilling();

  // The cleanup function will log a user out or return them to the home screen
  // depending on their settings
  const { delayedCleanup } = usePunchFormSubmissionCleanup();

  // State value to store error and success response messages
  const [apiResponseMessage, setApiResponseMessage] = React.useState<
    [StatusType, string]
  >(["idle", ""]);

  return React.useMemo(() => {
    return {
      apiResponseMessage,
      fieldGroups: [
        {
          testId: "punchInformation",
          title: "Punch Information",
          fields: [
            {
              displayName: "client",
              selectionOptions: clientsDropdownValues,
              name: "clientId",
              required: true,
              type: "combobox",
            } as SelectionFieldType,
            {
              displayName: "task",
              selectionOptions: tasksDropdownValues,
              name: "taskId",
              required: true,
              type: "combobox",
              // Users should be able to toggle between showing all tasks
              // and only tasks relevant to their department
              modifiers: <TaskFilteringToggleCheckbox />,
            } as SelectionFieldType,
          ],
        },
      ],
      initialValues: {
        clientId: initialClientId,
        taskId: initialTaskId,
        userId: user?.id || "",
        locationId: LocationId.Unknown,
      },
      onSubmit: async (values) => {
        try {
          // Attempt to clock a user in with the provided user inputs
          const { data } = await createInPunch({
            variables: {
              data: values,
            },
          });

          // Ensure data was returned from the API
          if (data) {
            // Display the resulting message to the user
            setApiResponseMessage(["success", data.createInPunch.message]);

            // Set successful submit state to true to update UI
            setDidSubmitSuccessfully(true);

            // Perform all cleanup duties based on the user's settings
            await delayedCleanup();
          }
        } catch (error) {
          // If an error occurs display the message to the user
          setApiResponseMessage(["error", error.message]);
        }
      },
      submitButtonField: {
        buttons: locationIdButtons,
        name: "locationId",
        displayName: "Location",
        description: "Where are you working today?",
      },
      testId: "punchInForm",
      title: "Clock In",
      validationSchema: Yup.object().shape<CreateInPunchInput>({
        userId: Yup.string().required(),
        clientId: Yup.string()
          .oneOf(clientIds, Errors.ClientMustBeInDropdown)
          .required(Errors.ClientMustBeInDropdown),
        taskId: Yup.string()
          .oneOf(taskIds, Errors.TaskMustBeInDropdown)
          .required(Errors.TaskMustBeInDropdown),
        locationId: Yup.mixed(),
      }),
    };
  }, [
    apiResponseMessage,
    clientsDropdownValues,
    tasksDropdownValues,
    user?.id,
    clientIds,
    taskIds,
    createInPunch,
    setDidSubmitSuccessfully,
    delayedCleanup,
    initialClientId,
    initialTaskId,
  ]);
}
