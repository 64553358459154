import styled, { css } from "styled-components";

import { Breakpoint, spacing } from "../../../utils";

type Props = {
  flexible?: boolean;
  number: number;
};

export const Columns = styled.section<Props>`
  --number: ${(props) => props.number};
  --gap: ${spacing[8]};

  display: grid;
  grid-template-columns: repeat(var(--number), 1fr);
  grid-auto-flow: column;
  grid-gap: var(--gap);
  padding: var(--gap) 0;

  ${(props) =>
    props.flexible &&
    css`
      grid-template-columns: initial;
      grid-template-rows: repeat(var(--number), 1fr);
      grid-auto-flow: row;

      @media screen and (min-width: ${Breakpoint.Medium}) {
        grid-template-columns: repeat(var(--number), 1fr);
        grid-template-rows: initial;
        grid-auto-flow: column;
      }
    `};
`;
