import styled, { css } from "styled-components";

import { StatusType } from "../../../store/types";
import { Text } from "../Text";
import { roleColors } from "../../../utils";

type Props = {
  type: StatusType;
};

/**
 * A Text component for displaying messages conveying the status of
 * an operation or user action.
 */
export const StatusMessage = styled(Text).attrs({ fontWeight: "bolder" })<
  Props
>`
  ${(props) => {
    switch (props.type) {
      case "success":
        return css`
          --color: ${roleColors.success};
        `;

      case "idle":
        return css`
          --color: ${roleColors.unused};
        `;

      case "warning":
        return css`
          --color: ${roleColors.warning};
        `;

      case "error":
      default:
        return css`
          --color: ${roleColors.error};
        `;
    }
  }}

  color: var(--color);
`;
