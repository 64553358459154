import * as React from "react";

import { LoadingScreen } from "../../../pages/LoadingScreen";
import { getAccessToken } from "../../../store/accessToken";
import { useAuthentication, useDebouncedValue } from "../../../hooks";
import { useCurrentUserLazyQuery } from "../../../generated/graphql";

/**
 * Handles user authentication by first attempting to fetch an Access Token,
 * after which, if the process returned a token, information about the current user
 * is fetched to populate user settings and references to the user.
 */
export const AuthWrapper: React.FC = ({ children }) => {
  // The current user query will be called if an authorized user
  // opens the app to prepare the welcome screen with their information
  const [
    currentUser,
    { called: hasBeenCalled, loading: isLoading },
  ] = useCurrentUserLazyQuery();

  // Attempt to authenticate the user
  const didCheckAuth = useAuthentication();

  // Debounce the result of the authentication check by 500ms
  // to create a smoother UI transition
  const didCheckAuthDebounced = useDebouncedValue(didCheckAuth);

  // If the authentication check has not been completed
  // display the loading screen
  if (!didCheckAuthDebounced) {
    return <LoadingScreen />;
  }

  // After auth has been checked if an Access Token is present
  // then an already logged in user has opened the app and their
  // information should be fetched to populate the welcome screen
  if (getAccessToken() && !hasBeenCalled) {
    // Fetch the required information
    currentUser();

    // Continue to render the loading screen
    return <LoadingScreen />;
  }

  // While the information is being fetched continue to display
  // the loading screen
  while (isLoading) {
    return <LoadingScreen />;
  }

  // Once the authentication check has been performed the app can be displayed
  return <>{children}</>;
};
