import * as React from "react";

/**
 * Sets the document title to a provided string,
 * which should be representative of the content on a given page.
 */
export function useDocumentTitle(title?: string) {
  // Store a reference to the current title
  const titleReference = React.useRef<string>();

  // Base title to be used on each page
  const baseTitle = "BarnPros: Time";

  // Check if a custom title has been provided
  if (title) {
    // Add the provided title to the base title, deliniated by a hyphen
    const newTitle = `${baseTitle} - ${title}`;

    // Check if this title matches the current title
    if (titleReference.current !== newTitle) {
      // If they do not match the title should be updated

      // Update the reference to be the new title
      titleReference.current = newTitle;

      // Set the document title to the new title
      document.title = titleReference.current;
    }

    // If no title was provided revert to the plain base title
  } else if (titleReference.current !== baseTitle) {
    titleReference.current = baseTitle;

    document.title = titleReference.current;
  }
}
