/* eslint-disable no-console -- This function is expected to print to the console */

/**
 * Prints an ascii art barn to the console.
 */
export function asciiBarn() {
  console.log(`
                           +&-
                         _.-^-._    .--.
                      .-'   _   '-. |__|
                     /     |_|     \\|  |
                    /               \\  |
                   /|     _____     |\\ |
                    |    |==|==|    |  |
|---|---|---|---|---|    |--|--|    |  |
|---|---|---|---|---|    |==|==|    |  |
^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
`);
}
/* eslint-enable no-console */
