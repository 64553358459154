/**
 * All possible routes of the application.
 */
// eslint-disable-next-line no-shadow -- https://github.com/typescript-eslint/typescript-eslint/issues/325
export enum Routes {
  /**
   * The landing page or root of the app.
   */
  Home = "/",
  /**
   * Admin dashboard for monitoring the performance and health of the app.
   */
  Dashboard = "/monitor",
  /**
   * Displays login for for users to log in.
   */
  Login = "/login",
  /**
   * Displays user configurable settings.
   */
  Settings = "/settings",
  /**
   * Displays a clock in form.
   */
  PunchIn = "/punch/in",
  /**
   * Displays a clock out form.
   */
  PunchOut = "/punch/out",
  /**
   * Displays a form to allow users to change their current task to a new one.
   */
  PunchNewTask = "/punch/new-task",
  /**
   * Displays forms for Supervisor users to create Override punches.
   */
  Override = "/override",
  /**
   * Displays forms for users to submit support tickets.
   */
  Support = "/support",
  /**
   * The Catch All route used for serving a 404 page.
   */
  CatchAll = "*",
}
