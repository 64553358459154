import * as React from "react";
import { Field } from "formik";

import { Checkbox as CheckboxDisplay } from "./Checkbox.display";
import { FieldType } from "../../../store/types";

type Props = {
  formTestId: string;
} & FieldType;

/**
 * A Checkbox wrapped in a Formik Field for use in forms controlled by Formik.
 */
export const Checkbox: React.FC<Props> = (props) => {
  return (
    // eslint-disable-next-line react/destructuring-assignment -- Name prop is required on Field as well as Checkbox
    <Field name={props.name}>
      {({ field, form }: any) => (
        <CheckboxDisplay
          checked={field.value}
          onChange={() => {
            form.setFieldValue(props.name, !field.value);
          }}
          {...props}
        />
      )}
    </Field>
  );
};
