import { getAccessToken } from "../../store/accessToken";

/**
 * Common configuration for REST API requests.
 *
 * Sets `credentials` to `include` to allow for cookies to be sent with requests
 * and sets the `authorization` header to `Bearer <token>` to pass the user's
 * Access Token for privilaged access.
 */
export function apiConfig() {
  return {
    credentials: "include" as "include" | "omit" | "same-origin" | undefined,
    headers: {
      "content-type": "application/json",
      Accept: "application/json",
      authorization: `Bearer ${getAccessToken()}`,
    },
  };
}
