import * as React from "react";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";

import {
  Card,
  DefaultPageLayout,
  Flexbox,
  Icon,
  Text,
  Title,
} from "../../components";
import { LoadingSpinnerWrapper } from "./LoadingScreen.style";
import { buttonColors } from "../../utils";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";

type Props = {
  message?: string;
};

/**
 * Displays a loading screen to occupy the user's screen while
 * background tasks are performed.
 */
export const LoadingScreen: React.FC<Props> = ({ message = "Please wait" }) => {
  useDocumentTitle();

  return (
    <DefaultPageLayout displayNavigation={false}>
      <Card>
        <Flexbox direction="top-down" alignContent="center">
          <Title.H2>Loading</Title.H2>

          <LoadingSpinnerWrapper>
            <Icon
              icon={faSpinner}
              backgroundColor={buttonColors.primary.background}
              spin
              size="extra-large"
            />
          </LoadingSpinnerWrapper>

          <Text>{message}</Text>
        </Flexbox>
      </Card>
    </DefaultPageLayout>
  );
};
