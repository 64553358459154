import * as React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

import { Routes } from "../../constants/routes";
import { UserRole, useCurrentUserQuery } from "../../generated/graphql";
import { getAccessToken } from "../../store/accessToken";

type Props = {
  component: React.ElementType;
  requiredRoles?: UserRole[];
} & RouteProps;

/**
 * Wraps the default `Route` component from React Router DOM and checks if an Access Token is present,
 * which will indicate if an authenticated user is requesting the route. If a token is found the route
 * will render the component as requested, otherwise the user will be redirected to the Login screen.
 *
 * Additionally, specific `UserRole`s can be provided which will require the current user to have a role matching
 * one of the provided roles. If the user does not pass this check they will be returned to the home screen.
 */
export const ProtectedRoute: React.FC<Props> = ({
  // eslint-disable-next-line @typescript-eslint/naming-convention -- React requires components to be PascalCased
  component: Component,
  requiredRoles,
  ...routeProps
}) => {
  // The current user is accessed to inspect their role
  // to determine if they have sufficient access
  const { data } = useCurrentUserQuery();
  const user = data?.currentUser.payload.user;

  return (
    <Route
      {...routeProps}
      render={(props) => {
        // Get the Access Token stored in memory
        const accessToken = getAccessToken();

        // If no token is found
        // redirect the user to the Login screen
        if (!accessToken) {
          return (
            <Redirect
              to={{
                pathname: Routes.Login,
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }

        if (requiredRoles && user) {
          if (!requiredRoles.includes(user.role)) {
            return (
              <Redirect
                to={{
                  pathname: Routes.Home,
                  state: {
                    from: props.location,
                  },
                }}
              />
            );
          }
        }

        // If a token is found render the route as normal
        return <Component {...props} />;
      }}
    />
  );
};
