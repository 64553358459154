import { ONE_MINUTE, ONE_SECOND } from "../constants/timeValues";

/**
 * Converts a number representing an amount of time in milliseconds
 * to a string representing that time in minutes and seconds.
 */
export function convertMsToSeconds(timeInMs: number) {
  // Round the time to the nearest minute value
  const minutes = Math.floor(timeInMs / ONE_MINUTE);

  // Round the remaining seconds to the nearest whole second
  const seconds = ((timeInMs % ONE_MINUTE) / ONE_SECOND).toFixed(0);

  // Return a formatted string
  return `${minutes}:${
    (Number.parseInt(seconds, 10) < 10 ? "0" : "") + seconds
  }`;
}
