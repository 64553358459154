import { USER_SETTINGS } from "../../../../constants/localStorageVariables";
import { UserSettingsStateType } from "../types";

/**
 * Stores a user's settings in Local Storage
 * so they may be used the next time the user opens the app.
 */
export function handlePersistToLocalStorage(
  userSettings: UserSettingsStateType,
) {
  localStorage.setItem(USER_SETTINGS, JSON.stringify(userSettings));
}
