import * as React from "react";
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";

import { Icon, Text } from "../../atoms";
import { IconWrapper, LineItemWrapper } from "./LineItem.style";

type Props = {
  icon: IconDefinition;
  iconBackgroundColor: string;
  label: string;
  content: string | number | React.ReactNode;
};

/**
 * Displays a single piece of information on a dashboard with an icon.
 */
export const LineItem: React.FC<Props> = ({
  content,
  label,
  icon,
  iconBackgroundColor,
}) => {
  return (
    <LineItemWrapper>
      <IconWrapper>
        <Icon icon={icon} backgroundColor={iconBackgroundColor} size="small" />
      </IconWrapper>

      <Text>
        {label}: {content}
      </Text>
    </LineItemWrapper>
  );
};
