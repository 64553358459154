import styled from "styled-components";

import logo from "../../../assets/logo-ds.png";
import { Container } from "../../templates/Container";
import { ZIndex, spacing } from "../../../utils";

/**
 * Styled `header` tag to wrap all Header content.
 */
export const HeaderWrapper = styled.header`
  padding: ${spacing[4]} 0;
  position: relative;
  z-index: ${ZIndex.MainContent};
`;

/**
 * Wrappers inner Header content and aligns that content to the center.
 */
export const HeaderContainer = styled(Container)`
  text-align: center;
`;

/**
 * Company logo as an `h1` tag to satisfy accessibility concerns.
 */
export const Logo = styled.h1`
  background-image: url(${logo});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: 0;
  height: 75px;
`;
