import * as React from "react";
import {
  faBriefcase,
  faCalendarEdit,
  faCoffee,
  faHome,
} from "@fortawesome/pro-solid-svg-icons";

import { Button, ButtonGroup, Card, Time } from "../../components";
import { LogoutButton } from "../LogoutButton";
import { LogoutButtonWrapper, WelcomeMessage } from "./WelcomeScreen.style";
import { Routes } from "../../constants/routes";
import { UserRole, useCurrentUserQuery } from "../../generated/graphql";
import { getWelcomeMessage } from "./utils/getWelcomeMessage";

/**
 * Displays the current time and a welcome message to the user, as well as
 * buttons used to perform each task a user might need to perform.
 */
export const WelcomeScreen: React.FC = () => {
  // Get data required to display on the welcome screen
  const { data } = useCurrentUserQuery();
  const user = data?.currentUser.payload.user;

  // Get the welcome messaged based on the time of day
  const welcomeMessge = getWelcomeMessage(user?.name || "");

  return (
    <Card>
      <Time />

      <WelcomeMessage>{welcomeMessge}</WelcomeMessage>

      <ButtonGroup>
        {/* If the user is allowed to perform Overrides render the Override button link */}
        {user?.role === (UserRole.Admin || UserRole.Supervisor) && (
          <Button
            linkTo={Routes.Override}
            label="Override"
            icon={faCalendarEdit}
            level="secondary"
          />
        )}

        <Button linkTo={Routes.PunchIn} label="Clock In" icon={faCoffee} />

        <Button
          linkTo={Routes.PunchNewTask}
          label="New Task"
          icon={faBriefcase}
        />

        <Button linkTo={Routes.PunchOut} label="Clock Out" icon={faHome} />
      </ButtonGroup>

      <LogoutButtonWrapper>
        <LogoutButton />
      </LogoutButtonWrapper>
    </Card>
  );
};
