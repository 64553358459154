import styled from "styled-components";

import clouds from "../../../assets/clouds.png";
import { ZIndex, rootColors, spacing } from "../../../utils";

/**
 * Wrapper for all content within the Default Page template.
 */
export const DefaultPageLayoutWrapper = styled.div`
  /* Set up the App to be the full height of the user's browser */
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  background-image: url(${clouds});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: ${rootColors.main.text};
  font-family: Arial, Helvetica, sans-serif;
  overflow-x: hidden;
`;

/**
 * Wrapper for main content in the page.
 */
export const Main = styled.main`
  padding: ${spacing[6]} 0;
  z-index: ${ZIndex.MainContent};
`;
