import * as React from "react";
import * as Yup from "yup";
import { faRocketLaunch } from "@fortawesome/pro-solid-svg-icons";

import {
  CreateFeatureRequestInput,
  useCreateFeatureRequestMutation,
} from "../../../../generated/graphql";
import {
  FieldType,
  FormConfigProps,
  FormType,
  StatusType,
} from "../../../../store/types";
import { useSupportAndOverrideFormSubmissionCleanup } from "../../../../hooks";

/**
 * Memoized configuration object for the Feature Request Form to reduce
 * unneccessary rerenders.
 */
export function useFeatureRequestFormConfig({
  setDidSubmitSuccessfully,
}: FormConfigProps): FormType<CreateFeatureRequestInput> {
  // The createFeatureRequest function will call the mutation
  //  to create the request using the data entered into the form
  const [createFeatureRequest] = useCreateFeatureRequestMutation();

  // The cleanup function will log a user out or return them to the home screen
  // depending on their settings
  const { delayedCleanup } = useSupportAndOverrideFormSubmissionCleanup();

  // State value to store error and success response messages
  const [apiResponseMessage, setApiResponseMessage] = React.useState<
    [StatusType, string]
  >(["idle", ""]);

  return React.useMemo(() => {
    return {
      apiResponseMessage,
      fieldGroups: [
        {
          testId: "requestDescription",
          title: "Description",
          description:
            "We are always looking for ways to improve your experience while using Time. If you have an idea for how to improve it let us know!",
          fields: [
            {
              name: "description",
              required: true,
              type: "textbox",
              placeholder: "Please describe your feature.",
            } as FieldType,
          ],
        },
      ],
      initialValues: {
        description: "",
      },
      onSubmit: async (values) => {
        try {
          // Submit the feedback report
          const { data } = await createFeatureRequest({
            variables: {
              data: values,
            },
          });

          // Ensure data was returned from the API
          if (data) {
            // Display the resulting message to the user
            setApiResponseMessage([
              "success",
              data.createFeatureRequest.message,
            ]);

            // Set successful submit state to true to update UI
            setDidSubmitSuccessfully(true);

            delayedCleanup();
          }
        } catch (error) {
          // If an error occurs display the message to the user
          setApiResponseMessage(["error", error.message]);
        }
      },
      submitButton: {
        icon: faRocketLaunch,
        label: "Submit Feature Request",
      },
      testId: "featureRequestForm",
      title: "Feature Request",
      validationSchema: Yup.object().shape<CreateFeatureRequestInput>({
        description: Yup.string().required(),
      }),
    };
  }, [
    apiResponseMessage,
    createFeatureRequest,
    delayedCleanup,
    setDidSubmitSuccessfully,
  ]);
}
