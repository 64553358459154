import * as React from "react";
import { Formik } from "formik";

import { PunchFormSuccessScreen } from "../common/PunchFormSuccessScreen";
import { formikConfig } from "../../../../lib/formik";
import { useFormBuilder } from "../../../../hooks";
import { usePunchOutFormConfig } from "./usePunchOutFormConfig";

/**
 * Form that allows an authorized user to clock out.
 */
export const PunchOutForm: React.FC = () => {
  // Track the submission state of the form
  const [didSubmitSuccessfully, setDidSubmitSuccessfully] = React.useState(
    false,
  );

  // Get configuration to pass to form builder
  const punchOutFormConfig = usePunchOutFormConfig({
    setDidSubmitSuccessfully,
  });

  // Use a Form Builder hook to generate elements to display to the user
  const { ApiResponseCard, Form, FormGroups, SubmitButton } = useFormBuilder(
    punchOutFormConfig,
  );

  // Extract values needed to configure Formik
  const { initialValues, onSubmit, validationSchema } = punchOutFormConfig;

  return (
    <Formik {...formikConfig({ initialValues, onSubmit, validationSchema })}>
      {({ isValid, isSubmitting }) => (
        <Form
          success={didSubmitSuccessfully}
          renderOnSuccess={
            <PunchFormSuccessScreen apiResponseCard={<ApiResponseCard />} />
          }
        >
          <ApiResponseCard />

          <FormGroups />

          <SubmitButton disabled={!isValid || isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};
