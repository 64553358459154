import * as React from "react";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-solid-svg-icons";

import { Button, ButtonGroup, FieldGroup } from "../../../components";
import { FormType, MultiSubmitFormType } from "../../../store/types";
import { useMultiStepFormProgressTracking } from "./useMultiStepFormProgressTracking";

/**
 * Creates elements use to create forms that require a multistep UI.
 */
export function useMultiStepFormBuilderElements<T>(
  formConfig: FormType<T> | MultiSubmitFormType<T>,
) {
  // The progress tracking hook manages the state of the user's progress through the form
  const {
    currentGroup,
    goToNextPage,
    goToPreviousPage,
    isFirstStepOfForm,
    isLastStepOfForm,
  } = useMultiStepFormProgressTracking(formConfig);

  /**
   * Renders the current group of fields the user is interacting with.
   */
  const CurrentFormGroup: React.FC = () => {
    return (
      <>
        {formConfig.fieldGroups
          .filter((_, index) => index === currentGroup)
          .map((group) => (
            <FieldGroup
              key={formConfig.testId + group.testId}
              formTestId={formConfig.testId}
              {...group}
            />
          ))}
      </>
    );
  };

  /**
   * Renders a pair of buttons that allow the user to progress forwards and backwards through the steps of the form.
   */
  const ControlButtons: React.FC<{ isValid: boolean }> = ({ isValid }) => {
    return (
      <ButtonGroup>
        <Button
          label="Previous"
          level="secondary"
          onClick={goToPreviousPage}
          icon={faArrowLeft}
          disabled={isFirstStepOfForm}
        />

        <Button
          label="Next"
          level="primary"
          onClick={goToNextPage}
          rightSideIcon={faArrowRight}
          // The button should be disabled if the current fields are not valid
          disabled={!isValid || isLastStepOfForm}
        />
      </ButtonGroup>
    );
  };

  return {
    ControlButtons,
    CurrentFormGroup,
    helpers: {
      currentGroup,
      isFirstStepOfForm,
      isLastStepOfForm,
    },
  };
}
