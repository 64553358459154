import styled from "styled-components";

import { Flexbox } from "../../components";
import { spacing } from "../../utils";

/**
 * Centers the Loading spinner and adds substantial vertical padding
 * to increase the size of the containing card.
 */
export const LoadingSpinnerWrapper = styled(Flexbox).attrs({
  alignContent: "center",
})`
  padding: ${spacing[8]};
`;
