import * as React from "react";

import { DefaultPageLayout } from "../../components";
import { SystemDashboard as SystemDashboardContainer } from "../../containers";
import { useDocumentTitle } from "../../hooks";

/**
 * Displays information about the state and health of the Queue Manager and Queue system.
 */
export const SystemDashboard: React.FC = () => {
  useDocumentTitle("Monitor");

  return (
    <DefaultPageLayout>
      <SystemDashboardContainer />
    </DefaultPageLayout>
  );
};
