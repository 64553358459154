import * as React from "react";
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";

import { ButtonGroup } from "../ButtonGroup";
import { RadioInput } from "../../atoms/RadioInput/RadioInput.display";
import { SelectionOption } from "../../../store/types";

type Props = {
  options: {
    radio: SelectionOption;
    component: React.ElementType;
  }[];
};

/**
 * Presents the user with a set of Radio Buttons and renders a component based on which button is selected.
 */
export const RadioPicker: React.FC<Props> = ({ options }) => {
  const [selectedOption, setSelectedOption] = React.useState(
    options[0].radio.value,
  );

  return (
    <>
      <ButtonGroup gapSize="small">
        {options.map(({ radio }) => (
          <RadioInput
            key={`${radio.value}Button`}
            inputName="radioPicker"
            name={radio.name}
            value={radio.value}
            checked={selectedOption === radio.value}
            onChange={() => {
              setSelectedOption(String(radio.value));
            }}
          />
        ))}
      </ButtonGroup>

      <>
        {options.map(
          ({
            // eslint-disable-next-line @typescript-eslint/naming-convention -- React requires components to be PascalCased
            component: Component,
            radio,
          }) => {
            if (radio.value !== selectedOption) {
              return null;
            }

            return <Component key={`${radio.value}Component`} />;
          },
        )}
      </>
    </>
  );
};
