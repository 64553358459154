import * as React from "react";

import { LoadingScreen } from "../../../pages/LoadingScreen";
import { UserSettingsActionTypes as Types } from "./actions";
import {
  UserDepartment,
  useCurrentUserQuery,
} from "../../../generated/graphql";
import { UserSettingsActions, UserSettingsStateType } from "./types";
import { handleSetUserDepartmentPermissions } from "./utils/handleSetUserDepartmentPermissions";
import { initialState } from "./initialState";
import { userSettingsContextReducer } from "./reducer";

/**
 * Create a context for storing and referencing user settings.
 */
const UserSettingsContext = React.createContext<UserSettingsStateType | any>(
  initialState,
);

type UserSettingsContextType = {
  userSettings: UserSettingsStateType;
  dispatch: React.Dispatch<UserSettingsActions>;
};

/**
 * Hook to access the User Settings context from anywhere in the app
 * to access settings and dispatch actions to modify settings.
 */
export function useUserSettingsContext(): UserSettingsContextType {
  const { userSettings, dispatch } = React.useContext(UserSettingsContext);

  return {
    userSettings,
    dispatch,
  };
}

/**
 * Provider component to wrap around the App. All children will have access to the context
 * which will allow them to read and write to the user's settings.
 */
export const UserSettingsContextProvider: React.FC = ({ children }) => {
  // Tracks if the settings have been loaded from the browser's local storage
  const [isLoaded, setIsLoaded] = React.useState(false);

  // Query the API for information about the current user
  const { data } = useCurrentUserQuery();
  const user = data?.currentUser.payload.user;
  const punchRecord = data?.currentUser.payload.punchRecord;

  // Connect the context to the userSettingsReducer to handle dispatching of actions
  const [userSettings, dispatch] = React.useReducer(
    userSettingsContextReducer,
    initialState,
  );

  // When the provider mounts, load saved settings from Local Storage
  // and additional settings based on the current user's Department
  React.useEffect(() => {
    // Configure permissions based on the user's Department
    const permissions = handleSetUserDepartmentPermissions(
      user?.department || UserDepartment.Unkown,
    );

    // Load saved settings from Local Storage
    dispatch({
      type: Types.LoadSettingsFromLocalStorage,
    });

    // Load permissions allowed by the user's Department
    // Note: This action is dispatched after the user's settings are loaded for settings
    // to be overwritten if permissions have been changed since the user last used the app
    dispatch({
      type: Types.LoadUserDepartmentConfiguration,
      payload: permissions,
    });

    dispatch({
      type: Types.StoreRecentPunchData,
      payload: {
        clientId: punchRecord?.clientId || "",
        taskId: punchRecord?.taskId || "",
      },
    });

    // Once settings have been loaded from local storage and department settings have been updated
    // set the `isLoaded` value to true to indicate the context is configured and ready to render its children
    setIsLoaded(true);
  }, [data, punchRecord, user?.department]);

  // If the settings have not finished loading render the loading screen to delay user interaction
  if (!isLoaded) {
    return <LoadingScreen />;
  }

  // Return a context provider to make the current user's settings and a dispatch method available
  return (
    <UserSettingsContext.Provider value={{ userSettings, dispatch }}>
      {children}
    </UserSettingsContext.Provider>
  );
};
