/* eslint-disable react/destructuring-assignment -- This rule is disabled so the formTestId and testId or name can be used to identify the `htmlFor` prop on the label */
import * as React from "react";
import { ErrorMessage, useField } from "formik";

import { Combobox, DatePicker, RadioInput, TextInput } from "../../atoms";
import {
  Error,
  InputControllerWrapper,
  InputLabel,
} from "./InputController.style";
import { FieldType } from "../../../store/types";

type Props = {
  formTestId: string;
} & FieldType;

/**
 * Renders content common to all Inputs (Labels, Error Messages, and Modifiers),
 * and renders the required input based on the `type` prop.
 */
export const InputController: React.FC<Props> = (props) => {
  // Connects the the input to Formik using the `name` prop
  const [_field, meta, _helpers] = useField(props.name);

  return (
    <InputControllerWrapper>
      <InputLabel htmlFor={`${props.formTestId}__${props.name}-input`}>
        {props.displayName || props.name}
        {props.required && "*"}
      </InputLabel>

      {(() => {
        switch (props.type) {
          case "radio":
            return <RadioInput {...props} />;

          // case "dropdown":
          //   return <Dropdown {...props} />;

          case "combobox":
            return <Combobox {...props} />;

          case "date":
            return <DatePicker {...props} />;

          case "text":
          case "textbox":
          case "password":
          case "number":
          default:
            return <TextInput {...props} />;
        }
      })()}

      {/* Render any modifiers used to augment the component */}
      {props.modifiers && props.modifiers}

      {/* Render any error messages the field has encountered if the field has been touched */}
      {meta.touched && meta.error && (
        <ErrorMessage
          name={props.name}
          render={(errorMessage) => <Error>{`Error: ${errorMessage}`}</Error>}
        />
      )}
    </InputControllerWrapper>
  );
};
/* eslint-enable react/destructuring-assignment */
