import * as React from "react";
import { Field } from "formik";

import { ButtonGroup } from "../../templates/ButtonGroup";
import { RadioInput as RadioInputDisplay } from "./RadioInput.display";
import { SelectionFieldType } from "../../../store/types";

type Props = {} & SelectionFieldType;

/**
 * Renders a list of Selection Options as Radio Buttons in a group.
 */
export const RadioInput: React.FC<Props> = ({ selectionOptions, name }) => {
  return (
    <ButtonGroup>
      {selectionOptions?.map((option) => (
        <Field key={`${name}-${option.name}`}>
          {({ field, form }: any) => {
            const isSelected = field.value[name] === option.value;

            return (
              <RadioInputDisplay
                checked={isSelected}
                onChange={() => {
                  form.setFieldValue(name, option.value);
                }}
                inputName={name}
                {...option}
              />
            );
          }}
        </Field>
      ))}
    </ButtonGroup>
  );
};
