import styled from "styled-components";

import { Breakpoint, buttonColors, spacing } from "../../../utils";

/**
 * Hidden internal Radio button to maintain input state.
 */
export const HiddenRadioButton = styled.input.attrs({ type: "radio" })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

/**
 * Wraps the entire Radio Input in a label for accessibility and ease of use.
 */
export const RadioInputWrapper = styled.label`
  text-transform: capitalize;
  cursor: pointer;
  display: flex;
  vertical-align: middle;
  padding: ${spacing[2]} 0;

  @media screen and (min-width: ${Breakpoint.Medium}) {
    padding: initial;
  }
`;

/**
 * Labels the input with a name representing the value represented by the button.
 */
export const Label = styled.span`
  padding-left: ${spacing[2]};
`;

/**
 * A checkmark icon that will be displayed when the Radio in question is selected.
 */
export const Icon = styled.svg`
  fill: none;
  stroke: ${buttonColors.secondary.hover.text};
  stroke-width: 3px;
`;

/**
 * The visual component of the radio that the user will interact with.
 */
export const StyledRadioButton = styled.div<{ checked: boolean }>`
  cursor: pointer;
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background: ${(props) =>
    props.checked
      ? buttonColors.secondary.border
      : buttonColors.secondary.background};
  box-shadow: 0 0 0 2px ${buttonColors.secondary.border};
  transition: all 150ms;

  ${HiddenRadioButton}:focus + & {
    box-shadow: 0 0 0 3px
      ${(props) =>
        props.checked
          ? buttonColors.secondary.border
          : buttonColors.secondary.hover.border};
  }

  > * {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;
