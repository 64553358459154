import styled from "styled-components";
import { NavLink as ReactRouterNavLink } from "react-router-dom";

import {
  Breakpoint,
  ZIndex,
  fadeIn,
  fadeOut,
  rootColors,
  spacing,
} from "../../../utils";

/**
 * The wrapping component for the Navigation menu.
 */
export const NavMenuWrapper = styled.nav``;

type NavMenuStyleProps = {
  toggled: boolean;
};

/**
 * The menu containing navigation links.
 */
export const NavMenuList = styled.ul<NavMenuStyleProps>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${ZIndex.NavMenu};

  height: 100vh;
  width: 100%;
  display: ${(props) => (props.toggled ? "flex" : "none")};
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: ${rootColors.footer.text};
  background: ${rootColors.footer.background};
  animation: 150ms linear ${(props) => (props.toggled ? fadeIn : fadeOut)};
  list-style: none;

  /* On Medium and larger screens, override mobile nav styles and replace with desktop nav styles */
  @media screen and (min-width: ${Breakpoint.Medium}) {
    display: flex;
    height: initial;
    flex-direction: row;
    justify-content: center;
    position: initial;
    background: initial;
    animation: none;
  }
`;

/**
 * Individual Nav Menu item.
 */
export const NavItem = styled.li`
  padding: ${spacing[4]} 0;

  /* On Medium and larger screens the nav menu will shift from a vertical orientation to a horizontal orientation,
     thus the padding should also be flipped to ensure space on the correct axis. */
  @media screen and (min-width: ${Breakpoint.Medium}) {
    padding: 0 ${spacing[4]};
  }
`;

/**
 * Link with specific nav styling.
 */
export const NavLink = styled(ReactRouterNavLink)`
  display: inline-flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
`;

/**
 * Text element to contain the name of the page the link references.
 */
export const NavText = styled.span`
  padding-left: ${spacing[2]};
`;
