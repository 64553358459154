import { StatusType } from "../../../../store/types";

/**
 * Updates a status variable based on the number of retries a queue has
 * attempted without a successful attempt.
 */
export function getStatusByNumberOfRetries(retries: number): StatusType {
  switch (true) {
    case retries === 0:
      return "success";

    case retries <= 6:
      return "warning";

    case retries <= 10:
    default:
      return "error";
  }
}
