import { QueueState } from "../../../../generated/graphql";
import { StatusType } from "../../../../store/types";

/**
 * Updates a status variable based on a provided QueueState.
 */
export function getStatusByState(state: QueueState): StatusType {
  switch (true) {
    case state === QueueState.Waiting:
      return "warning";

    case state === QueueState.Failing:
      return "error";

    case state === QueueState.Finishing:
    case state === QueueState.Queued:
    case state === QueueState.ProcessingJob:
    case state === QueueState.Idle:
    default:
      return "success";
  }
}
